import { useSearchParams } from "react-router-dom"
import '../../style/auth/message.css'

const Message = () => {
    let [searchParams, setSearchParams] = useSearchParams();
    const headline = searchParams.get("headline")
    const body = searchParams.get("body")

    const decrypt = (text, shift) => {
        let decryptedText = '';
        for (let i = 0; i < text.length; i++) {
            let char = text[i];
            if (char.match(/[a-z]/i)) {
                let code = text.charCodeAt(i);
                if ((code >= 65) && (code <= 90)) {
                    char = String.fromCharCode(((code - 65 - shift + 26) % 26) + 65);
                } else if ((code >= 97) && (code <= 122)) {
                    char = String.fromCharCode(((code - 97 - shift + 26) % 26) + 97);
                }
            }
            decryptedText += char;
        }
        return decryptedText;
    }

    return (
        <div id="message" className="bg-white">
            <p id="messageHeadline">{decrypt(decodeURI(headline),3)}</p>
            <p id="messageBody">{decrypt(decodeURI(body),3)}</p>
        </div>
    )
}
export default Message
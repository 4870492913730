import { useEffect, useState } from "react"
import { getCookie, isAuth, setCookie } from '../../helpers/helpers'
import axios from "axios"
import { formatIsoString } from '../PrematchArbitrage/helpers'
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify'
import Layout from '../../components/Layout'
import { v4 as uuidv4 } from 'uuid'
import { createBets } from '../BetTracker/helpers'
import { LuInfo } from "react-icons/lu";
import Modal from 'react-bootstrap/Modal'


const colors = { 'nike': '#ff8000', 'tipsport': '#1186f0', 'fortuna': '#FFDB01', 'doxxbet': '#f40034', 'tipos': '#5033ff', 'synottip': '#e21e3a', 'bet365': '#005641' }


const PrematchValuebets = () => {
    const [valuebets, setValuebets] = useState([])
    const [accounts, setAccounts] = useState([])
    const [error, setError] = useState([])
    const [success, setSuccess] = useState([])

    const [bankroll, setBankroll] = useState((getCookie('bankroll') && !isNaN(getCookie('bankroll'))) ? getCookie('bankroll') : 100)
    const [coefficient, setCoefficient] = useState((getCookie('coefficient') && !isNaN(getCookie('coefficient'))) ? getCookie('coefficient') : 1)
    const [showOptions, setShowOptions] = useState("")
    const navigate = useNavigate()
    const newDataIn = 10000
    const [selected, setSelected] = useState()
    const [added, setAdded] = useState(false)
    const [open, setOpen] = useState(false)

    const token = getCookie('token')

    const fetchNewData = async () => {
        axios.post(`${process.env.REACT_APP_API}/valuebets/prematch`, { token })
            .then(response => {
                setValuebets(response.data.valuebets)
                setAccounts(response.data.accounts)

            })
            .catch(error => {
                setError(error?.response?.data?.error || '')
            })
    }

    useEffect(() => {
        setAdded(false)
    }, [selected])

    useEffect(() => {
        setError('')
        fetchNewData()
        const interval = setInterval(fetchNewData, newDataIn)
        return () => clearInterval(interval)
    }, [token])

    useEffect(() => {
        if (!getCookie("prematchValuebets")) {
            setCookie("prematchValuebets", 'true')
        }
    }, [])

    useEffect(() => {
        if (error) {
            console.log('errorerror', error)
            toast.error(error)
            setError()
        }
    }, [error])


    useEffect(() => {
        setCookie("coefficient", coefficient.toString())
    }, [coefficient])

    useEffect(() => {
        setCookie("bankroll", bankroll.toString())
    }, [bankroll])



    return (
        <Layout>
            <ToastContainer />
            <div className="container-fluid">
                <Modal
                    onHide={() => setOpen(false)}
                    show={(open)}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    className="placeholder-color-white"
                >
                    <Modal.Header closeButton style={{ 'border': 'none' }} className="text-dark">
                        <Modal.Title id="contained-modal-title-vcenter">
                            Čo su to valuebets?
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ 'fontSize': '14px' }}>
                        <p>Valuebets sú stávky, kde kurz ponúkaný stávkovou kanceláriou je vyšší ako by mal byť podľa skutočnej pravdepodobnosti výsledku. Preto by si z dlhodobého hľadiska mal byť v zisku.</p>
                        <p>Príklad: Stávková spoločnosť Tipsport ponúka stávku na hod mincou. Kurz na to, že padne číslo, je 2.3 a na znak je 1.6. Stávková kancelária sa pomýlila, pretože na každú možnosť mal byť kurz 2. Z tohto dôvodu sa oplatí staviť na číslo kde je kurz 2.3.</p>
                        <p>Pri stávkach na zápas nedokážeme len tak ľahko určiť pravdepodobnost, že sa niečo stane. Preto využivame technológiu ktorá pozerá stovky kancelárii a urobí z nich priemer, ktorý sa podobá pravdepodobnosti.</p>
                        <p>Odporúčam dávať na valuebets zo ziskom cez 15%.</p>
                        <p>Pri každej stávky píše odporučaný vklad. Ten je vyrátaný pomocou Kelly Criterion z pravdepodobnosti, kurzu a bankrollu (nastaviť sa da vo Viac)</p>
                        <p>Valuebets sa dajú tiež využiť aj na to aby si zarábal na predčasnom predávaní tiketov.</p>

                    </Modal.Body>
                </Modal>
                <div className='row px-2'>
                    <div
                        onClick={() => setShowOptions(!showOptions)}
                        className='px-3 col-auto rounded rounded-2 d-flex align-items-center justify-content-center p-0 text-secondary font1'
                        style={{ 'backgroundColor': '#222222', 'cursor': 'pointer' }}
                    >Viac</div>
                    {/* <div
                        onClick={() => navigate('/notifications')}
                        className='ms-2 px-3 col-auto rounded rounded-2 d-flex align-items-center justify-content-center p-0 text-secondary font1'
                        style={{
                            'backgroundColor': '#222222', 'cursor': 'pointer',
                        }}>Upozorniť
                    </div> */}
                    <div
                        onClick={() => setOpen(true)}
                        className='ms-2 px-2 col-auto rounded rounded-2 d-flex align-items-center justify-content-center p-0 text-secondary font1'
                        style={{
                            'backgroundColor': '#222222', 'cursor': 'pointer',
                        }}>
                        <LuInfo />
                    </div>
                </div>

                {showOptions &&
                    <div className="row mt-2">
                        <div className="col-auto d-flex align-items-center mt-2 ">
                            <div className="text-white pe-2" style={{ 'width': '90px' }}>
                                Bankroll:
                            </div>
                            <input
                                value={bankroll}
                                onChange={e => { setBankroll(e.target.value) }}
                                name='Bankroll'
                                type="text"
                                className=" form-control form-control-secondary bg-dark text-white placeholder-color-white"
                                style={{ 'width': '160px' }}
                                placeholder="Enter Bankroll"
                            />
                        </div>
                        <div className="col-auto d-flex align-items-center  mt-2">
                            <div className="text-white pe-2" style={{ 'width': '90px' }}>
                                Koeficient:
                            </div>
                            <input
                                value={coefficient}
                                onChange={e => { setCoefficient(e.target.value) }}
                                name='Coefficient'
                                type="text"
                                className=" form-control form-control-secondary bg-dark text-white placeholder-color-white"
                                style={{ 'width': '160px' }}
                                placeholder="Enter Bankroll"
                            />
                        </div>
                        {valuebets[0]?.createdAt &&
                            <div className="col-auto d-flex align-items-center mt-2">
                                <div className="text-white pe-2">
                                    Naposledy aktualizové:
                                </div>
                                <div className="text-white">
                                    {((Date.now() - (new Date(valuebets[0]?.createdAt)).getTime()) / (1000 * 60)).toFixed(1)} minút dozadu
                                </div>
                            </div>
                        }
                    </div>
                }
                {valuebets.map(valuebet => {
                    const formatedStart = formatIsoString(valuebet.start)
                    const market = ((valuebet.path2 && valuebet.path3) ? `${valuebet.path1} -  ${valuebet.path2}` : valuebet.path1)
                    const bet = ((valuebet.path2 && valuebet.path3) ? valuebet.path3 : `${[valuebet.path2, valuebet.path3].filter(e => e).join(' ')}`)
                    const calculatedStake = Math.round(((valuebet.value - 1) * valuebet.probability - (1 - valuebet.probability)) / (valuebet.value - 1) * bankroll * coefficient)

                    return (
                        <div className="row mt-2 text-white px-2" onClick={() => setSelected(valuebet.id)}>
                            <div className="col-12 w-100 rounded rounded-3" style={{ "background": '#222222' }}>
                                <div className="row d-flex flex-row" >
                                    <div className="col-auto d-flex align-items-start justify-content-center flex-column text-center" style={{ "width": '110px', 'height': '100px' }}>
                                        <div className="fs-4">{((valuebet.overvalue - 1) * 100).toFixed(2)}%</div>
                                        <div className="text-secondary pb-1" style={{ 'fontSize': '14px' }}>
                                            <span>{formatedStart.dateText} {formatedStart.hour}:{formatedStart.minute}</span>
                                        </div>
                                        <div className="fs-4" style={{ 'width': '70px' }}>
                                            <Link to={valuebet.url} target="_blank" className="w-100 h-100 d-flex justify-content-center align-items-center">
                                                <img
                                                    src={require(`../../static/images/sites/${valuebet.site}.png`)}
                                                    style={{ maxWidth: '100%', height: 'auto', marginTop: '0px' }}
                                                    className="rounded-1"
                                                    alt="Offer 1"
                                                />
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="col d-flex align-items-start justify-content-center flex-column text-truncate" style={{ 'height': '100px' }}>
                                        <div className="row lh-1">{valuebet.name.split(' - ')[0]}</div>
                                        <div className="row lh-1">{valuebet.name.split(' - ')[1]}</div>
                                        <div style={{ 'height': '8px' }}></div>
                                        <div className="row lh-1 text-secondary">{market}</div>
                                        <div className="row lh-1 text-secondary">{bet}</div>
                                    </div>
                                    <div className="col-auto text-secondary p-0 d-flex align-items-center justify-content-center" style={{ 'width': '80px' }}>
                                        <div style={{ 'fontSize': '20px', 'color': colors[valuebet.site] }}>{valuebet.value}</div>
                                    </div>
                                </div>
                                {selected == valuebet.id &&
                                    <div className="row text-white">
                                        <div className="col-auto" style={{ "width": '110px' }}>
                                            {valuebet.data.sort((a, b) => (b.value || b.opening) - (a.value || a.opening)).filter(e => e.site !== valuebet.site).map(e => {
                                                return (
                                                    <div className="d-flex align-items-center justify-content-start">
                                                        <div style={{ 'maxWidth': '40px' }}>
                                                            <img
                                                                src={require(`../../static/images/sites/${e.site}.png`)}
                                                                style={{ maxWidth: '100%', height: 'auto', marginTop: '0px' }}
                                                                className="rounded-1"
                                                                alt="Offer 1"
                                                            />
                                                        </div>
                                                        <div className="ps-1" style={{ "color": colors[e.site] }}>
                                                            {(e.value || e.opening).toFixed(2)}
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>

                                        <div className="col p-0 text-secondary" >
                                            <div className="row d-flex" style={{ 'paddingRight': '20px' }}>
                                                <span className="col-auto ">
                                                    Odporučaný vklad
                                                </span>
                                                <span className="ms-auto col text-end" >
                                                    {calculatedStake.toFixed(2)}€
                                                </span>
                                            </div>
                                            <div className="row d-flex mt-1 mb-3" style={{ 'paddingRight': '20px' }}>
                                                <div className="col  d-flex align-items-center justify-content-start fs-3 text-truncate" >
                                                    <input
                                                        defaultValue={getCookie(`lastAccount_${valuebet.site}`)}
                                                        onChange={e => { setCookie(`lastAccount_${valuebet.site}`, e.target.value) }}
                                                        name='BetTracker Account'
                                                        type="text"
                                                        list={`datalist_${valuebet.id}`}
                                                        className=" form-control text-white placeholder-color-white rounded rounded-0"
                                                        placeholder="BetTracker ucet"
                                                        style={{
                                                            'height': '30px',
                                                            'width': '100%',
                                                            'border': 'none',
                                                            'borderBottom': '2px solid #6C757D',
                                                            'background': 'transparent',
                                                            'padding': '0',
                                                            'outline': 'none'
                                                        }}
                                                        onFocus={e => e.target.style.outline = 'none'}
                                                    />
                                                    <datalist id={`datalist_${valuebet.id}`}>
                                                        {accounts.filter(e => e.site === valuebet.site).map(e => {
                                                            return (
                                                                <option id={e.name} value={`${e.account}`} label={`${e.balance.toFixed(2)}€`} />
                                                            )
                                                        })}
                                                    </datalist>
                                                </div>
                                                <div className="col-auto text-truncate d-flex align-items-end justify-content-end fs-3" style={{ 'width': "70px", }}>
                                                    <div
                                                        className={`${added && 'disabled'} btn btn-outline-secondary btn-sm d-flex align-items-center justify-content-center`}
                                                        style={{ "background": '#222222', 'height': '24px', 'fontSize': '12px' }}
                                                        // disabled={added}
                                                        onClick={() => (
                                                            createBets({
                                                                setError,
                                                                setSuccess,
                                                                bets: [{
                                                                    account: getCookie(`lastAccount_${valuebet.site}`),
                                                                    bettype: 'valuebet',
                                                                    game_type: 'prematch',
                                                                    site: valuebet.site,
                                                                    name: valuebet.name,
                                                                    start: valuebet.start,
                                                                    market: market,
                                                                    bet: bet,
                                                                    value: valuebet.value,
                                                                    stake: calculatedStake.toFixed(2),
                                                                    status: 'pending',
                                                                    "sold_price": 0,
                                                                    true_value: (1 / parseFloat(valuebet.probability)).toFixed(2)
                                                                }]
                                                            }), setAdded(true))}
                                                    >{added ? 'Pridané' : 'Pridať'}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                        // <div className="row  mt-2 text-white px-2" style={{}} onClick={() => setSelected(valuebet.id)}>
                        //     <div className="col-12 w-100 rounded rounded-3" style={{  "background": '#222222' }}>
                        //         <div className="row " >
                        // <div className="col-auto d-flex align-items-start justify-content-center flex-column" style={{ "width": '110px','height':'70px' }}>
                        //     <div className="fs-4">{((valuebet.overvalue - 1) * 100).toFixed(2)}%</div>
                        //     <div className="text-secondary" style={{ 'fontSize': '14px' }}>
                        //         <span>{formatedStart.dateText} {formatedStart.hour}:{formatedStart.minute}</span>
                        //     </div>
                        // </div>
                        //             <div className="col d-flex justify-content-center flex-column text-truncate">
                        //                 <div className="row lh-base" style={{ 'fontSize': '14px' }}>
                        //                     {valuebet.name.split('-')[0]}
                        //                 </div>
                        //                 <div className="row lh-1" style={{ 'fontSize': '14px' }}>
                        //                     {valuebet.name.split('-')[1]}
                        //                 </div>
                        //                 <div className="row lh-base text-secondary " style={{ 'fontSize': '14px' }}>
                        //                     {market}
                        //                 </div>
                        //             </div>
                        //             <div className="col-auto d-flex align-items-center p-0" style={{ "width": '120px', 'borderLeft': '5px solid #222222' }}>
                        //                 <div style={{ 'width': '35px', 'color': colors[valuebet.site] }}>
                        //                     {valuebet.value}
                        //                 </div>
                        //                 <div className="d-flex pe-2" style={{ 'width': '85px' }}>
                        // <Link to={valuebet.url} target="_blank" className="w-100 h-100 d-flex justify-content-center align-items-center">
                        //     <img
                        //         src={require(`../../static/images/sites/${valuebet.site}.png`)}
                        //         style={{ maxWidth: '100%', height: 'auto', marginTop: '0px' }}
                        //         className="rounded-1"
                        //         alt="Offer 1"
                        //     />
                        // </Link>
                        //                 </div>
                        //             </div>
                        //         </div>
                        // {selected == valuebet.id &&
                        //     <div className="row text-white">
                        //         <div>{valuebet.path1}</div>
                        //         <div>{valuebet.path2}</div>
                        //         <div>{valuebet.path3}</div>

                        //         <hr/>
                        //     </div>
                        // }
                        //     </div>


                        // </div>
                    )
                })}



                {/* <div className="row mx-3">
                    {valuebets.map(valuebet => {
                        const formatedStart = formatIsoString(valuebet.start)
                        const market = ((valuebet.path2 && valuebet.path3) ? `${valuebet.path1} -  ${valuebet.path2}` : valuebet.path1)
                        const bet = ((valuebet.path2 && valuebet.path3) ? valuebet.path3 : `${[valuebet.path2, valuebet.path3].filter(e => e).join(' ')}`)
                        const calculatedStake = Math.round(((valuebet.value - 1) * valuebet.probability - (1 - valuebet.probability)) / (valuebet.value - 1) * bankroll * coefficient)
                        const siteLoginInformations = loginInformations.find(e => e.site === valuebet.site)
                        console.log(valuebet.site, accounts.filter(e => e.site === valuebet.site))
                        return (
                            <div className="col-12 my-2 p-0" key={valuebet.id}>
                                <div className="row rounded rounded-3 text-light" style={{ 'background': '#222222', 'minHeight': '75px' }}>
                                    <div className="col-auto d-flex align-items-center justify-content-center fs-3 font1" style={{ 'width': '100px' }}>
                                        <div>{((valuebet.overvalue - 1) * 100).toFixed(2)}%</div>
                                    </div>
                                    <div className='col-auto' style={{ width: window.innerWidth <= 1250 ? '100px' : '125px' }}>
                                        <Link to={valuebet.url} target="_blank" className="w-100 h-100 d-flex justify-content-center align-items-center">
                                            <img
                                                src={require(`../../static/images/sites/${valuebet.site}.png`)}
                                                style={{ maxWidth: '100%', height: 'auto', marginTop: '2px' }}
                                                className="rounded-1"
                                                alt="Offer 1"
                                            />
                                        </Link>
                                    </div>
                                    <div className="col-auto d-flex align-items-center justify-content-center p-0" style={{ 'width': '80px' }}>
                                        <div className="d-flex flex-column text-truncate text-center">
                                            <span>{formatedStart.dateText}</span>
                                            {formatedStart.hour}:{formatedStart.minute}
                                        </div>
                                    </div>
                                    <div className="col text-truncate d-flex align-items-start justify-content-center d-flex flex-column" >
                                        <div className="lh-base ">{valuebet.name}</div>
                                        <div className="text-secondary lh-base font1 text-uppercase">{valuebet.sport} | {valuebet.competition}</div>
                                    </div>
                                    <div className="col text-truncate d-flex align-items-start justify-content-center d-flex flex-column" >
                                        <div className="">{market}</div>
                                        <div className="text-secondary">{bet}</div>
                                    </div>
                                    <div className="col-auto text-truncate d-flex align-items-center justify-content-start fs-3" style={{ 'width': '80px' }}>
                                        {valuebet.value}
                                    </div>
                                    <div className="col-auto text-truncate text-secondary d-flex align-items-center justify-content-start fs-6" style={{ 'width': '80px' }}>
                                        {(valuebet.probability * 100).toFixed(2)}%
                                    </div>
                                    <div className="col-auto text-truncate text-secondary d-flex align-items-center justify-content-start fs-6" style={{ 'width': '80px' }}>
                                        {calculatedStake.toFixed(2)}€
                                    </div>
                                    <div className="col-auto text-truncate d-flex align-items-center justify-content-start fs-3" style={{ "width": '150px' }}>
                                        <input
                                            defaultValue={getCookie(`lastAccount_${valuebet.site}`)}
                                            onChange={e => { setCookie(`lastAccount_${valuebet.site}`, e.target.value) }}
                                            name='account'
                                            type="text"
                                            list={`datalist_${valuebet.id}`}
                                            className=" form-control form-control-secondary bg-dark text-white placeholder-color-white"
                                            placeholder="Enter account"
                                        />
                                        <datalist id={`datalist_${valuebet.id}`}>
                                            {accounts.filter(e => e.site === valuebet.site).map(e => {
                                                return (
                                                    <option id={e.name} value={`${e.account}`} label={`${e.balance.toFixed(2)}€`} />
                                                )
                                            })}
                                        </datalist>
                                    </div>
                                    <div className="col-auto text-truncate d-flex align-items-center justify-content-start fs-3" >
                                        <div
                                            className="btn btn-info"
                                            onClick={() =>
                                                createBets({
                                                    setError,
                                                    setSuccess,
                                                    bets: [{
                                                        account: getCookie(`lastAccount_${valuebet.site}`),
                                                        bettype: 'valuebet',
                                                        game_type: 'prematch',
                                                        site: valuebet.site,
                                                        name: valuebet.name,
                                                        start: valuebet.start,
                                                        market: market,
                                                        bet: bet,
                                                        value: valuebet.value,
                                                        stake: calculatedStake.toFixed(2),
                                                        status: 'pending',
                                                        "sold_price": 0,
                                                        true_value: (1 / parseFloat(valuebet.probability)).toFixed(2)
                                                    }]
                                                })}
                                        >ADD TO BetTracker</div>
                                    </div>
                                    <div className="col-auto text-truncate d-flex align-items-center justify-content-start fs-3" >
                                        {loginSessions.find(e => e.site === valuebet.site).loggedIn
                                            ?
                                            <div className="btn btn-danger" onClick={() => { placeBet({ ...valuebet, stake: calculatedStake, username: siteLoginInformations.username, password: siteLoginInformations.password, account: siteLoginInformations.account }) }}>BET</div>
                                            :
                                            <div className="btn btn-danger disabled">BET</div>
                                        }
                                    </div>
                                </div>
                            </div>
                        )
                    })}

                </div> */}
            </div>
        </Layout>
    )

}



export default PrematchValuebets
import { useEffect, useState } from 'react'
import Layout from '../../components/Layout'
import { getCookie, isAuth, setCookie } from '../../helpers/helpers'
import axios from "axios"
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer, toast } from 'react-toastify'
import { defaultOptions, FreeVersion, UnpaidInvoices, UserMessage, formatIsoString, roundDigits, Button, hideGroup, hideGame, Disclamer, invalidGame, Options, NothingFound, Bonuses } from '../PrematchArbitrage/helpers'
import ClipLoader from "react-spinners/ClipLoader";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { BiLineChart, BiHide, BiRefresh, BiDotsHorizontalRounded } from 'react-icons/bi'
import { MdLink, MdLinkOff, MdClose, MdDeleteForever, MdOutlineEditOff, MdOutlineEdit, MdPhotoSizeSelectLarge, MdInfoOutline } from 'react-icons/md'
import { RiEmotionSadLine } from 'react-icons/ri'
import { LuWallet } from 'react-icons/lu'
import { createBets } from '../BetTracker/helpers'




const BigScreen = ({ parameters }) => {
    const { arbitrages, free, unpaidInvoices, userMessage, selected, setSelected, values } = parameters
    const { sure, setSure, setError, addedToBetTracker, setAddedToBetTracker, options, setOptions, showAccounts, setShowAccounts, accounts, setAccounts, account1, setAccount1, account2, setAccount2, changingValues, setChangingValues, lastStake, setLastStake, linked, setLinked, value2, setValue2, value1, setValue1, stake2, setStake2, stake1, setStake1 } = values
    const [showMore, setShowMore] = useState(false)
    const [showHide, setShowHide] = useState(false)
    const [showInfo, setShowInfo] = useState(false)
    const navigate = useNavigate()

    useEffect(() => { setShowMore(false); setShowInfo(false) }, [selected])

    const selected_arbitrage = arbitrages.find(e => e.group_key === selected)

    const createdAgo = (Math.abs(new Date().getTime() - new Date(selected_arbitrage?.createdAt).getTime()) / 1000).toFixed(0)
    const updatedAgo = (Math.abs(new Date().getTime() - new Date(selected_arbitrage?.updatedAt).getTime()) / 1000).toFixed(0)


    return (
        <>
            <div className='row '>
                <div
                    className='col-12  text-white overflow-auto'
                    style={{ "height": "calc(100vh - 56px)", "background": '#161616' }}
                >
                    {/* <Bonuses /> */}
                    <div className="m-0 mb-1">
                        <div className="container-fluid px-1 pt-3 pb-1">
                            <div className="alert alert-danger m-0">Pozor! Toto je Beta verzia live arbitáži a môže obsahovať možstvo chýb ktoré vás privedu do straty. Preto budte pri stávkovani opatrný.</div>
                        </div>
                    </div>
                    <div className="m-0 mb-1">
                        <div className="container-fluid px-1 pt-3 pb-1">
                            <div className="alert alert-secondary m-0">Prosim o spatnu vazbu aby som mohol live arbitraze zlepsovat. Kazdy bug, error, chybu a uspech ktory nastane mi prosim poslite. Imessage/whatsapp na 0917782762.</div>
                        </div>
                    </div>
                    {/* <FreeVersion free={free} /> */}
                    {/* <UnpaidInvoices unpaidInvoices={unpaidInvoices} /> */}
                    {/* <UserMessage userMessage={userMessage} /> */}
                    {/* <NothingFound arbitrages={arbitrages} /> */}
                    {arbitrages.map(arbitrage => {
                        const { offer1_gameid, offer2_gameid, offer1_start, id, group_key, roi, offer1_url, offer2_url, offer1_site, offer2_site, offer1_home, offer2_away, market, market_quantity, offer1_bet, offer2_bet, offer1_color_primary, offer2_color_primary } = arbitrage
                        const start = formatIsoString(offer1_start)

                        const createdAgo = (Math.abs(new Date().getTime() - new Date(arbitrage.createdAt).getTime()) / 1000).toFixed(0)
                        const updatedAgo = (Math.abs(new Date().getTime() - new Date(arbitrage.updatedAt).getTime()) / 1000).toFixed(0)


                        return (
                            <div
                                style={{ 'border': `${group_key === selected ? '' : 'solid 3px rgba(255, 0, 0, 0) '}`, "background": "#222222" }}
                                className={`font2 row mx-1 mb-3 py-4  rounded rounded-4 align-middle ${group_key == selected ? 'border border-3 border-white' : ''}`}
                                onClick={() => (group_key != selected && setSelected(group_key))}
                                key={arbitrage.id}
                            >
                                <div className='col-2 d-flex align-items-center justify-content-center fs-3' >
                                    {arbitrage.roi.toFixed(2)}%
                                </div>
                                <div className='col-1 d-flex align-items-center justify-content-center flex-column p-0' >
                                    <div>
                                        {['football', 'tennis', 'hockey', 'basketball', 'handball', 'volleyball'].includes(arbitrage.offer1_sport)
                                            ? <img src={require(`../../static/images/sports/${arbitrage.offer1_sport}.png`)} className='w-100' style={{ 'maxWidth': '36px' }}></img>
                                            : <div>{ }</div>
                                        }
                                    </div>
                                </div>

                                <div className='col-2 d-flex align-items-start justify-content-center flex-column' >
                                    <span
                                        className='lh-base'
                                        style={{ fontSize: window.innerWidth <= 1150 ? '16px' : '1.25rem' }}
                                    >{arbitrage.offer1_home} <span className="text-secondary" style={{ 'fontSize': '12px' }}>vs.</span> {arbitrage.offer2_away}</span>
                                    <span className='lh-base text-secondary text-uppercase'>{arbitrage.market || arbitrage.offer1_path1}</span>
                                </div>
                                <div className='col-3 d-flex align-items-end justify-content-center flex-column pe-0' >
                                    <div className='d-flex'>
                                        <div className='px-2 text-secondary text-uppercase'>
                                            {arbitrage.offer1_bet}
                                        </div>
                                        <div className="col p-0 d-flex justify-content-center">
                                            <Link to={offer1_url} target="_blank" className="d-flex align-items-center">
                                                <img src={require(`../../static/images/sites/${offer1_site}.png`)} style={{ 'maxHeight': window.innerWidth <= 1150 ? '12px' : '18px' }} className="rounded w-100" alt="Offer 1"></img>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className='d-flex'>
                                        <div className='px-2 text-secondary text-uppercase'>
                                            {arbitrage.offer2_bet}
                                        </div>
                                        <div className="col p-0 d-flex justify-content-center">
                                            <Link to={offer2_url} target="_blank" className="d-flex align-items-center">
                                                <img src={require(`../../static/images/sites/${offer2_site}.png`)} style={{ 'maxHeight': window.innerWidth <= 1150 ? '12px' : '18px' }} className="rounded w-100" alt="Offer 2"></img>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className='col d-flex align-items-start justify-content-center flex-column' style={{ 'maxWidth': '100px' }}>
                                    <div style={{ 'color': arbitrage.offer1_color_primary, 'maxWidth': '100px' }}>
                                        <input
                                            value={group_key === selected ? stake1 : ''}
                                            onChange={e => { setStake1(e.target.value); setLastStake(e.target.name) }}
                                            name='stake1'
                                            type="text"
                                            className=" form-control text-center placeholder-color-white p-0 text1 text-light text-truncate rounded rounded-3 "
                                            placeholder="stake"
                                            style={{ 'fontSize': window.innerWidth <= 1450 ? '1rem' : '1rem', 'background': '#222222', 'maxHeight': '22px', 'boxShadow': 'none', 'border': `none` }}
                                        />
                                    </div>
                                    <div style={{ 'color': arbitrage.offer2_color_primary, 'maxWidth': '100px' }}>
                                        <input
                                            value={group_key === selected ? stake2 : ''}
                                            onChange={e => { setStake2(e.target.value); setLastStake(e.target.name) }}
                                            name='stake2'
                                            type="text"
                                            className=" form-control text-center placeholder-color-white p-0 text1 text-light text-truncate rounded rounded-3 "
                                            placeholder="stake"
                                            style={{ 'fontSize': window.innerWidth <= 1450 ? '1rem' : '1rem', 'background': '#222222', 'maxHeight': '22px', 'boxShadow': 'none', 'border': `none` }}
                                        />
                                    </div>
                                </div>
                                <div className='col-1 d-flex align-items-start justify-content-center flex-column' >
                                    <div style={{ 'color': arbitrage.offer1_color_primary }}>
                                        {arbitrage.offer1_value}
                                    </div>
                                    <div style={{ 'color': arbitrage.offer2_color_primary }}>
                                        {arbitrage.offer2_value}
                                    </div>
                                </div>
                                <div className='col-1 d-flex align-items-start justify-content-center flex-column' >
                                    <div style={{ 'color': arbitrage.offer1_color_primary }}>
                                        {group_key === selected &&
                                            <>
                                                {(stake1 * arbitrage.offer1_value - stake2 - stake1).toFixed(1)}€
                                            </>
                                        }
                                    </div>
                                    <div style={{ 'color': arbitrage.offer2_color_primary }}>
                                        {group_key === selected &&
                                            <>
                                                {(stake2 * arbitrage.offer2_value - stake2 - stake1).toFixed(1)}€
                                            </>
                                        }
                                    </div>
                                </div>

                                <div className='col-1 d-flex align-items-center justify-content-center ' >
                                    <div className='text-secondary mx-2'>
                                        {createdAgo}
                                    </div>
                                    <div>
                                        {updatedAgo}
                                    </div>
                                    {/* <div>
                                        {['football', 'tennis', 'hockey', 'basketball', 'handball', 'volleyball'].includes(arbitrage.offer1_sport)
                                            ? <img src={require(`../../static/images/sports/${arbitrage.offer1_sport}.png`)} style={{ 'width': '46px', 'objectFit': 'cover', 'height': '46px' }}></img>
                                            : <div>{ }</div>
                                        }
                                    </div> */}
                                </div>
                            </div>
                        )
                    })}
                    <Disclamer />
                </div>
            </div>
        </>
    )
}

const PrematchArbitrage = () => {
    const token = getCookie('token')
    const newDataIn = 1000
    const [arbitrages, setArbitrages] = useState([])
    const [free, setFree] = useState(false)
    const [unpaidInvoices, setUnpaidInvoices] = useState([])
    const [userMessage, setUserMessage] = useState('')
    const [dataFetched, setDataFetched] = useState(false)
    const [error, setError] = useState('')
    const [selected, setSelected] = useState('')
    const [accounts, setAccounts] = useState([])

    const [account1, setAccount1] = useState('')
    const [account2, setAccount2] = useState('')

    const [stake1, setStake1] = useState('')
    const [stake2, setStake2] = useState('')
    const [value1, setValue1] = useState('')
    const [value2, setValue2] = useState('')
    const [linked, setLinked] = useState(true)
    const [lastStake, setLastStake] = useState('')
    const [changingValues, setChangingValues] = useState(false)
    const [showAccounts, setShowAccounts] = useState((getCookie("showAccount") === "false") ? false : true)
    const [options, setOptions] = useState(getCookie('options') ? JSON.parse(getCookie('options')) : defaultOptions)
    const [addedToBetTracker, setAddedToBetTracker] = useState(false)
    const [sure, setSure] = useState(false)

    const values = { sure, setSure, setError, addedToBetTracker, setAddedToBetTracker, options, setOptions, showAccounts, setShowAccounts, accounts, setAccounts, account1, setAccount1, account2, setAccount2, changingValues, setChangingValues, lastStake, setLastStake, linked, setLinked, value2, setValue2, value1, setValue1, stake2, setStake2, stake1, setStake1 }


    useEffect(() => {
        if (arbitrages.length > 0) {
            if (!arbitrages.some(e => e.group_key === selected)) {
                if (window.innerWidth > 950) {
                    setSelected(arbitrages[0].group_key)
                }
            }
        }
    }, [arbitrages])

    const fetchNewData = async () => {
        axios.post(`${process.env.REACT_APP_API}/abitrage/live`, { token, options: JSON.parse(getCookie('options')) })
            .then(response => {
                setArbitrages(response.data.arbitrages)
                setUnpaidInvoices([])
                setDataFetched(true)
                setFree(false)
                setUserMessage(null)
                setAccounts([])

            })
            .catch(error => {
                setError(error.response.data.error || '')
                setArbitrages([])
                setUnpaidInvoices([])
                setDataFetched(true)
                setFree(null)
                setUserMessage('')
                setAccounts([])
            })
    }

    useEffect(() => {
        setCookie("options", (JSON.stringify(options)))
        fetchNewData()
    }, [options])

    useEffect(() => {
        const arbitrage = arbitrages.find(e => e.group_key === selected)
        if (arbitrage) {
            setCookie(`lastAccount_${arbitrage.offer1_site}`, account1)
            setCookie(`lastAccount_${arbitrage.offer2_site}`, account2)
        }
    }, [account1, account2])

    useEffect(() => {
        setError('')
        fetchNewData()
        const interval = setInterval(fetchNewData, newDataIn)
        return () => clearInterval(interval)
    }, [token])

    useEffect(() => {
        if (error) {
            console.log('errorerror', error)
            toast.error(error)
        }
    }, [error])

    useEffect(() => {
        const arbitrage = arbitrages.find(e => e.group_key === selected)
        if (arbitrage) {
            fetchNewData()
            setAccount1(getCookie(`lastAccount_${arbitrage.offer1_site}`) || "")
            setAccount2(getCookie(`lastAccount_${arbitrage.offer2_site}`) || "")
            setSure(false)
            setAddedToBetTracker(false)
        }
    }, [selected])

    useEffect(() => {
        if (!changingValues && selected) {
            const selectedArbitrage = arbitrages.find(e => e.group_key === selected)
            if (selectedArbitrage) {
                setValue1(selectedArbitrage.offer1_value)
                setValue2(selectedArbitrage.offer2_value)
            }
        }
    }, [changingValues, selected, arbitrages])

    useEffect(() => {
        setStake1('')
        setStake2('')
        setLastStake('')
    }, [value1, value2])

    useEffect(() => {
        const arbitrage = arbitrages.find(e => e.group_key === selected)
        console.log(linked, arbitrage, lastStake)
        if (linked && arbitrage && lastStake === 'stake1') {
            const stake2 = (stake1 * value1 / value2)
            setStake2(roundDigits(stake2))
            if (stake1 === '') {
                setStake2('')
            }
        }
    }, [stake1])

    useEffect(() => {
        const arbitrage = arbitrages.find(e => e.group_key === selected)
        if (linked && arbitrage && lastStake === 'stake2') {
            const stake1 = (stake2 * value2 / value1)
            setStake1(roundDigits(stake1))
            if (stake2 === '') {
                setStake1('')
            }
        }
    }, [stake2])

    useEffect(() => {
        if (showAccounts === 'false') {
            setCookie('showAccount', false)
        } else {
            setCookie('showAccount', true)
        }
    }, [showAccounts])

    useEffect(() => {
        if (!getCookie("liveArbitrage")) {
            setCookie("liveArbitrage", 'true')
        }
    }, [])

    if (!isAuth()) { return <Layout><div className='text-light'>Prihlaste sa</div></Layout> }

    if (dataFetched) {
        const parameters = { arbitrages, free, unpaidInvoices, selected, setSelected, userMessage, values }
        return (
            <Layout>
                <ToastContainer />
                <div className="overflow-auto container-fluid text-white font2 " style={{ "minHeight": "calc(100vh - 56px)", "background": "#161616" }} >
                    {window.innerWidth <= 950
                        // ? <SmallScreen parameters={parameters} />
                        ? <div className='text-white'>Pouzite prosim vacsiu obrazovku</div>
                        : <BigScreen parameters={parameters} />
                    }
                </div>

            </Layout>
        )
    } else {
        return (
            <Layout>
                <div className="container d-flex align-items-center justify-content-center" style={{ "height": "calc(100vh - 56px)" }}>
                    <ClipLoader color="#000" size={30} margin={5} speedMultiplier={1} />
                </div>
            </Layout>
        )
    }
}

export default PrematchArbitrage
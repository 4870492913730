import { useEffect, useState } from "react"
import { getCookie, isAuth, setCookie } from '../../helpers/helpers'
import axios from "axios"
import { formatIsoString } from '../PrematchArbitrage/helpers'
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify'
import Layout from '../../components/Layout'
import { v4 as uuidv4 } from 'uuid'
import { createBets } from '../BetTracker/helpers'

const nikeGrid = {
    'A': { '1': '', '2': '', '3': '', '4': '', '5': '', '6': '', '7': '', '8': '' },
    'B': { '1': '', '2': '', '3': '', '4': '', '5': '', '6': '', '7': '', '8': '' },
    'C': { '1': '', '2': '', '3': '', '4': '', '5': '', '6': '', '7': '', '8': '' },
    'D': { '1': '', '2': '', '3': '', '4': '', '5': '', '6': '', '7': '', '8': '' },
    'E': { '1': '', '2': '', '3': '', '4': '', '5': '', '6': '', '7': '', '8': '' },
    'F': { '1': '', '2': '', '3': '', '4': '', '5': '', '6': '', '7': '', '8': '' },
    'G': { '1': '', '2': '', '3': '', '4': '', '5': '', '6': '', '7': '', '8': '' },
    'H': { '1': '', '2': '', '3': '', '4': '', '5': '', '6': '', '7': '', '8': '' }
}

const loginSites = [{ name: 'nike', grid: nikeGrid }, { name: 'tipsport', grid: false }, { name: 'fortuna', grid: false }, { name: 'doxxbet', grid: false }]


const PrematchValuebets = () => {
    const [valuebets, setValuebets] = useState([])
    const [accounts, setAccounts] = useState([])
    const [error, setError] = useState([])
    const [success, setSuccess] = useState([])

    const [bankroll, setBankroll] = useState((getCookie('bankroll') && !isNaN(getCookie('bankroll'))) ? getCookie('bankroll') : 100)
    const [coefficient, setCoefficient] = useState((getCookie('coefficient') && !isNaN(getCookie('coefficient'))) ? getCookie('coefficient') : 1)
    const [loginInformations, setLoginInformations] = useState(getCookie('loginInformations') ? JSON.parse(getCookie('loginInformations')) : loginSites.map(e => ({ site: e.name, username: '', password: '', account: '', grid: e.grid })))
    const [loginSessions, setLoginSession] = useState(loginSites.map(e => ({ site: e.name, loggedIn: false, id: uuidv4() })))

    const token = getCookie('token')

    const fetchNewData = async () => {
        axios.post(`${process.env.REACT_APP_API}/valuebets/prematch`, { token })
            .then(response => {
                setValuebets(response.data.valuebets)
                setAccounts(response.data.accounts)

            })
            .catch(error => {
                setError(error?.response?.data?.error || '')
            })
    }

    const placeBet = (valuebet) => {
        axios.post(`${process.env.REACT_APP_API}/autobet`, { token, bet: valuebet, site: valuebet.site, action: 'placeBet', id: loginSessions.find(e => e.site === valuebet.site).id })
            .then(response => {
                console.log(response)
            })
            .catch(error => {
                setError(error?.response?.data?.error || '')
            })
    }

    useEffect(() => {
        fetchNewData()
        console.log("dfsdfd")
    }, [])

    useEffect(()=>{
        if(!getCookie("prematchValuebets")){
            setCookie("prematchValuebets",'true')
        }
    },[])

    useEffect(() => {
        if (error) {
            console.log('errorerror', error)
            toast.error(error)
            setError()
        }
    }, [error])

    useEffect(() => {
        if (success) {
            console.log('success', success)
            toast.success(success)
            setSuccess()
        }
    }, [success])

    useEffect(() => {
        setCookie("coefficient", coefficient.toString())
    }, [coefficient])

    useEffect(() => {
        setCookie("bankroll", bankroll.toString())
    }, [bankroll])

    const openSession = (site) => {
        const siteLoginInformations = loginInformations.find(e => e.site === site)
        const siteLoginSessions = loginSessions.find(e => e.site === site)
        if (siteLoginInformations.username) {
            if (siteLoginInformations.password) {
                if (siteLoginInformations.account) {
                    axios.post(`${process.env.REACT_APP_API}/autobet`, { token, action: 'init', site, id: siteLoginSessions.id, username: siteLoginInformations.username, password: siteLoginInformations.password, account: siteLoginInformations.account })
                        .then(response => {
                            console.log(response.data)
                            if (response.data.status == 200) {
                                // const [loginSessions, setLoginSession] = useState(loginSites.map(e => ({ site: e.name, loggedIn: false, id: uuidv4() })))
                                // setLoginSession({...loginSessions,})
                                setLoginSession(loginSessions.map(i => i.site === site ? { ...i, loggedIn: true } : i))
                            }
                        })
                        .catch(error => {
                            setError(error?.response?.data?.error || '')
                        })
                } else {
                    setError(`No account for ${site}`)
                }
            } else {
                setError(`No password for ${site}`)
            }
        } else {
            setError(`No username for ${site}`)
        }
    }



    return (
        <Layout>
            <ToastContainer />
            <div>
                <div>
                    {loginInformations.map(e => {

                        return (
                            <div className="row my-2">
                                <div className="col-auto text-white d-flex align-items-center justify-content-end" style={{ "width": '125px' }}>
                                    {e.site}
                                </div>
                                <div className="col-auto text-white" style={{ "width": '250px' }}>
                                    <input
                                        value={e.username}
                                        onChange={event => { setLoginInformations(loginInformations.map(i => i.site === e.site ? { ...i, username: event.target.value } : i)); }}
                                        name='Username'
                                        type="text"
                                        className=" form-control form-control-secondary bg-dark text-white placeholder-color-white"
                                        placeholder="Enter Username"
                                    />
                                </div>
                                <div className="col-auto text-white" style={{ "width": '150px' }}>
                                    <input
                                        value={e.password}
                                        onChange={event => { setLoginInformations(loginInformations.map(i => i.site === e.site ? { ...i, password: event.target.value } : i)); }}
                                        name='Password'
                                        type="password"
                                        className=" form-control form-control-secondary bg-dark text-white placeholder-color-white"
                                        placeholder="Enter Password"
                                    />
                                </div>
                                <div className="col-auto text-white" style={{ "width": '150px' }}>
                                    <input
                                        value={e.account}
                                        onChange={event => { setLoginInformations(loginInformations.map(i => i.site === e.site ? { ...i, account: event.target.value } : i)); }}
                                        name='Account'
                                        type="account"
                                        className=" form-control form-control-secondary bg-dark text-white placeholder-color-white"
                                        placeholder="Enter Account"
                                    />
                                </div>
                                {loginSessions.find(i => i.site === e.site).loggedIn
                                    ? <div className="col-auto text-white btn-success btn" style={{ "width": '120px' }}>LOGGED IN</div>
                                    : <div onClick={() => openSession(e.site)} className="col-auto text-white btn-danger btn" style={{ "width": '120px' }}>LOG IN</div>
                                }

                            </div>
                        )
                    })}
                    <div className="row my-2">
                        <div className="col-auto" style={{ "width": '125px' }}></div>
                        <div className="col-auto btn btn-success mx-3" onClick={() => setCookie("loginInformations", (JSON.stringify(loginInformations)))}>SAVE LOGIN INFORMATIONS LOCALLY</div>
                    </div>
                </div>
                <div className="row my-2">
                    <div className="col-auto d-flex justify-content-center align-items-center">
                        {((Date.now() - (new Date(valuebets[0]?.createdAt)).getTime()) / (1000 * 60)).toFixed(1)} minutes ago
                    </div>
                </div>
                <div className="row my-2">
                    <div className="col-auto d-flex justify-content-center align-items-center">
                        <div className="text-white ps-3" style={{ 'width': '100px' }}>bankroll: </div>
                    </div>
                    <div className="col-auto" style={{ "width": '150px' }}>
                        <input
                            value={bankroll}
                            onChange={e => { setBankroll(e.target.value) }}
                            name='Bankroll'
                            type="text"
                            className=" form-control form-control-secondary bg-dark text-white placeholder-color-white"
                            // className="form-control form-control-primary bg-dark"
                            // style={{ "height": '38px', "background": "#222222", 'fontSize': "20px", "boxShadow": `none` }}
                            placeholder="Enter Bankroll"
                        />
                    </div>
                </div>
                <div className="row my-2">
                    <div className="col-auto d-flex justify-content-center align-items-center placeholder-color-white">
                        <div className="text-white ps-3" style={{ 'width': '100px' }}>coefficient: </div>
                    </div>
                    <div className="col-auto" style={{ "width": '150px' }}>
                        <input
                            value={coefficient}
                            onChange={e => { setCoefficient(e.target.value) }}
                            name='Coefficient'
                            type="text"
                            className=" form-control form-control-secondary bg-dark text-white"
                            // className="form-control form-control-primary bg-dark"
                            // style={{ "height": '38px', "background": "#222222", 'fontSize': "20px", "boxShadow": `none` }}
                            placeholder="Enter Coefficient"
                        />
                    </div>
                </div>
                <div className="row mx-3">
                    {valuebets.map(valuebet => {
                        const formatedStart = formatIsoString(valuebet.start)
                        const market = ((valuebet.path2 && valuebet.path3) ? `${valuebet.path1} -  ${valuebet.path2}` : valuebet.path1)
                        const bet = ((valuebet.path2 && valuebet.path3) ? valuebet.path3 : `${[valuebet.path2, valuebet.path3].filter(e => e).join(' ')}`)
                        const calculatedStake = Math.round(((valuebet.value - 1) * valuebet.probability - (1 - valuebet.probability)) / (valuebet.value - 1) * bankroll * coefficient)
                        const siteLoginInformations = loginInformations.find(e => e.site === valuebet.site)
                        console.log(valuebet.site, accounts.filter(e => e.site === valuebet.site))
                        return (
                            <div className="col-12 my-2 p-0" key={valuebet.id}>
                                <div className="row rounded rounded-3 text-light" style={{ 'background': '#222222', 'minHeight': '75px' }}>
                                    <div className="col-auto d-flex align-items-center justify-content-center fs-3 font1" style={{ 'width': '100px' }}>
                                        <div>{((valuebet.overvalue - 1) * 100).toFixed(2)}%</div>
                                    </div>
                                    <div className='col-auto' style={{ width: window.innerWidth <= 1250 ? '100px' : '125px' }}>
                                        <Link to={valuebet.url} target="_blank" className="w-100 h-100 d-flex justify-content-center align-items-center">
                                            <img
                                                src={require(`../../static/images/sites/${valuebet.site}.png`)}
                                                style={{ maxWidth: '100%', height: 'auto', marginTop: '2px' }}
                                                className="rounded-1"
                                                alt="Offer 1"
                                            />
                                        </Link>
                                    </div>
                                    <div className="col-auto d-flex align-items-center justify-content-center p-0" style={{ 'width': '80px' }}>
                                        <div className="d-flex flex-column text-truncate text-center">
                                            <span>{formatedStart.dateText}</span>
                                            {formatedStart.hour}:{formatedStart.minute}
                                        </div>
                                    </div>
                                    <div className="col text-truncate d-flex align-items-start justify-content-center d-flex flex-column" >
                                        <div className="lh-base ">{valuebet.name}</div>
                                        <div className="text-secondary lh-base font1 text-uppercase">{valuebet.sport} | {valuebet.competition}</div>
                                    </div>
                                    <div className="col text-truncate d-flex align-items-start justify-content-center d-flex flex-column" >
                                        <div className="">{market}</div>
                                        <div className="text-secondary">{bet}</div>
                                    </div>
                                    <div className="col-auto text-truncate d-flex align-items-center justify-content-start fs-3" style={{ 'width': '80px' }}>
                                        {valuebet.value}
                                    </div>
                                    <div className="col-auto text-truncate text-secondary d-flex align-items-center justify-content-start fs-6" style={{ 'width': '80px' }}>
                                        {(valuebet.probability * 100).toFixed(2)}%
                                    </div>
                                    <div className="col-auto text-truncate text-secondary d-flex align-items-center justify-content-start fs-6" style={{ 'width': '80px' }}>
                                        {calculatedStake.toFixed(2)}€
                                    </div>
                                    <div className="col-auto text-truncate d-flex align-items-center justify-content-start fs-3" style={{ "width": '150px' }}>
                                        <input
                                            defaultValue={getCookie(`lastAccount_${valuebet.site}`)}
                                            onChange={e => { setCookie(`lastAccount_${valuebet.site}`, e.target.value) }}
                                            name='account'
                                            type="text"
                                            list={`datalist_${valuebet.id}`}
                                            className=" form-control form-control-secondary bg-dark text-white placeholder-color-white"
                                            placeholder="Enter account"
                                        />
                                        <datalist id={`datalist_${valuebet.id}`}>
                                            {accounts.filter(e => e.site === valuebet.site).map(e => {
                                                return (
                                                    <option id={e.name} value={`${e.account}`} label={`${e.balance.toFixed(2)}€`} />
                                                )
                                            })}
                                        </datalist>
                                    </div>
                                    <div className="col-auto text-truncate d-flex align-items-center justify-content-start fs-3" >
                                        <div
                                            className="btn btn-info"
                                            onClick={() =>
                                                createBets({
                                                    setError,
                                                    setSuccess,
                                                    bets: [{
                                                        account: getCookie(`lastAccount_${valuebet.site}`),
                                                        bettype: 'valuebet',
                                                        game_type: 'prematch',
                                                        site: valuebet.site,
                                                        name: valuebet.name,
                                                        start: valuebet.start,
                                                        market: market,
                                                        bet: bet,
                                                        value: valuebet.value,
                                                        stake: calculatedStake.toFixed(2),
                                                        status: 'pending',
                                                        "sold_price": 0,
                                                        true_value: (1 / parseFloat(valuebet.probability)).toFixed(2)
                                                    }]
                                                })}
                                        >ADD TO BetTracker</div>
                                    </div>
                                    <div className="col-auto text-truncate d-flex align-items-center justify-content-start fs-3" >
                                        {loginSessions.find(e => e.site === valuebet.site).loggedIn
                                            ?
                                            <div className="btn btn-danger" onClick={() => { placeBet({ ...valuebet, stake: calculatedStake, username: siteLoginInformations.username, password: siteLoginInformations.password, account: siteLoginInformations.account }) }}>BET</div>
                                            :
                                            <div className="btn btn-danger disabled">BET</div>
                                        }
                                    </div>
                                </div>
                            </div>
                        )
                    })}

                </div>
            </div>
        </Layout>
    )

}



export default PrematchValuebets
import { useNavigate, BrowserRouter as Router, Route } from "react-router-dom";
import Layout from "./components/Layout";
import { useEffect, useState } from "react";
import { isAuth } from "./helpers/helpers";
import { Table } from 'react-bootstrap';
import { IoIosArrowDown } from "react-icons/io";
import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';


const roundDigits = (number) => {
	const decimalPlaces = 2
	const factor = 10 ** decimalPlaces
	return (Math.round(number * factor) / factor).toFixed(decimalPlaces)
}

const App = () => {
	const navigate = useNavigate()
	const [isVisible, setIsVisible] = useState(false);
	const [grid1, setGrid1] = useState("Nepál")
	const [grid2, setGrid2] = useState("X2")
	const [lastGrid, setLastGrid] = useState("")
	const [stake1, setStake1] = useState(70)
	const [stake2, setStake2] = useState(60.25)
	const [lastStake, setLastStake] = useState('')
	const [recalculateStake, setRecalculateStake] = useState(false)

	const grid1array = [{ name: 'Nepál', value: 2.1 }, { name: 'Remíza', value: 3.22 }, { name: 'Laos', value: 3.5 }, { name: 'Neprehra Nepál', value: 1.3 }, { name: 'Nebude remíza', value: 1.27 }, { name: 'Neprehra Laos', value: 1.78 }]
	const grid2array = [{ name: 'Nepál', value: 1.56 }, { name: 'remíza', value: 3.92 }, { name: 'Laos', value: 5.97 }, { name: '1X', value: 1.15 }, { name: '12', value: 1.25 }, { name: 'X2', value: 2.44 }]

	useEffect(() => {
		const index = grid1array.indexOf(grid1array.find(e => e.name === grid1))
		if (lastGrid === 1) {
			if (index == 0) { setGrid2(grid2array[5].name) }
			if (index == 1) { setGrid2(grid2array[4].name) }
			if (index == 2) { setGrid2(grid2array[3].name) }
			if (index == 3) { setGrid2(grid2array[2].name) }
			if (index == 4) { setGrid2(grid2array[1].name) }
			if (index == 5) { setGrid2(grid2array[0].name) }
			setStake1(70)
			setRecalculateStake(!recalculateStake)
			setLastStake('stake1')
		}

	}, [grid1])

	useEffect(() => {
		const index = grid2array.indexOf(grid2array.find(e => e.name === grid2))
		if (lastGrid === 2) {
			if (index == 5) { setGrid1(grid1array[0].name) }
			if (index == 4) { setGrid1(grid1array[1].name) }
			if (index == 3) { setGrid1(grid1array[2].name) }
			if (index == 2) { setGrid1(grid1array[3].name) }
			if (index == 1) { setGrid1(grid1array[4].name) }
			if (index == 0) { setGrid1(grid1array[5].name) }
			setStake2(60)
			setRecalculateStake(!recalculateStake)
			setLastStake('stake2')
		}
	}, [grid2])

	useEffect(() => {
		console.log(stake1, lastStake)
		if (lastStake === 'stake1') {
			const stake2 = (stake1 * grid1array.find(e => e.name === grid1).value / grid2array.find(e => e.name === grid2).value)
			setStake2(roundDigits(stake2))
			if (stake1 === '') {
				setStake2('')
			}
		}
	}, [stake1, recalculateStake])

	useEffect(() => {
		if (lastStake === 'stake2') {
			const stake1 = (stake2 * grid2array.find(e => e.name === grid2).value / grid1array.find(e => e.name === grid1).value)
			setStake1(roundDigits(stake1))
			if (stake2 === '') {
				setStake1('')
			}
		}
	}, [stake2, recalculateStake])

	const scrollToAbout = () => {
		document.getElementById("about").scrollIntoView({ behavior: "smooth" });
	}


	useEffect(() => {
		// Set a timer to change visibility after 2 seconds
		const timer = setTimeout(() => {
			setIsVisible(true);
		}, 500);
	})
	useEffect(() => {
		if (isAuth()) {
			navigate('/prematch/arbitrage');
		}
	}, [])
	const fontSize = 14
	const pr = 1.618
	return (
		<>
			<div style={{ "background": '#0F0725', 'height': "100vh" }} className="d-flex align-items-center justify-content-center flex-column">
				<div className="d-flex justify-content-end align-items-start" style={{ 'height': 'calc(100%/3)', 'width': '100%' }}>
					<a className=" text-white btn mt-4 me-4 rounded rounded-4 py-0" style={{ "background": '#6F00FD' }} href='/signin'>
						<span className="lh-base py-0 font3 text-uppercase" style={{ "fontSize": `${fontSize}px` }}>prihlásiť sa</span>
					</a>
				</div>
				<div className="font3" style={{ 'height': 'calc(100%/3)' }}>
					<div className=" text-white text-center d-flex align-items-center justify-content-center flex-column h-100" style={{ 'maxWidth': '400px' }}>
						<h1 className="text-uppercase lh-1" style={{ "fontSize": `${fontSize * pr * pr * pr}px`, 'letterSpacing': '0' }}>arbitrážne stávkovanie</h1>
						<h3 className="font4" style={{ "fontSize": `${fontSize}px` }}>Netipujeme výsledok - Zisk pri</h3>
						<h3 className="font4" style={{ "fontSize": `${fontSize}px` }}>akomkoľvek výsledku zápasu</h3>
					</div>
				</div>
				<div className={`d-flex flex-column align-items-center justify-content-center ${isVisible ? 'fade-in' : 'hidden'}`} style={{ 'height': 'calc(100%/3)' }}>
					<a className="text-white btn rounded rounded-5 px-3" style={{ "background": '#6F00FD', 'paddingTop': '7px' }} href='/signup'>
						<span className="lh-base py-2 font3 text-uppercase " style={{ "fontSize": `${fontSize * pr}px` }}>skúsiť zadarmo</span>
					</a>
					<div className="d-flex flex-column align-items-center justify-content-center mt-5" style={{ 'marginBottom': '5rem', 'cursor': 'pointer' }} onClick={() => scrollToAbout()}>
						<span className="text-white font3 lh-1">dozvedieť sa viac</span>
						<span className="lh-1"><IoIosArrowDown size={"25px"} color={"#fff"} className="" /></span>

					</div>


				</div>
			</div>
			<div className="container-fluid" id="about" style={{ "height": '285vh', "background": '#0F0725' }}>
				<div className="row ">
					<div className="container-fluid" style={{ 'maxWidth': '600px' }}>
						{/* about */}
						<div className="row">
							<h2 style={{ 'fontSize': '1rem' }} className="text-white lh-lg fw-normal text-center"><b>Arbitrážne stávkovanie</b> (surebetting, surebets) je stratégia, kde podáš <b>dva tikety</b> v rôznych spoločnostiach, tak aby si <b>pokryl každú možnosť</b> ako môže zápas skončiť. Preto bude jeden vždy <b>výherný</b> a zisk z výherného pokryje stratu druhého tiketu plus <b>zarobí</b> pár eur na <b>istotu</b>. </h2>
							<div style={{ 'height': '20px' }}></div>
							<h3 style={{ 'fontSize': '1rem' }} className="text-white lh-lg fw-normal text-center"><b>Nižšie</b> môžete vidieť funkčné <b>kurzové ponuky</b> dvoch stávkových kancelárii na futbalový zápas <b>Nepál vs. Laos</b>. Stačí správne <b>rozdeliť vklady</b> pomocou kalkulačky a vybrať kombinácie ktoré pokryjú <b>každý výsledok</b>.</h3>
							<div style={{ 'height': '20px' }}></div>
							<h3 style={{ 'fontSize': '1rem' }} className="text-white lh-lg fw-normal text-center">Dokážeš nájsť druhú kombináciu s ktorou máš <b>istý zisk?</b> </h3>
						</div>

						{/* boxes */}
						<div className="row">
							<div className="text-white d-flex align-items-center justify-content-between" style={{ "background": '#394b5b', 'height': '32px' }}>
								<div>Výsledok zápasu</div>
								<div>
									<div style={{ "width": '75px' }}>
										<img src={require(`./static/images/sites/tipsport.png`)} style={{ 'borderRadius': '5px' }} className="w-100" alt="Offer 1"></img>
									</div>
								</div>
							</div>
							{grid1array.map(e => {
								return (
									<div className="col-4 px-1 py-1 bg-white">
										<div
											onClick={() => { setGrid1(e.name); setLastGrid(1) }}
											className={`d-flex flex-column align-items-center  justify-content-center  px-2 ${grid1 === e.name ? '' : 'hover-effect'}`}
											style={{ 'height': '40px', 'border': '1px solid #d6d8da', 'borderRadius': '2px', 'cursor': 'pointer', 'background': grid1 === e.name ? '#0f549d' : '#f5f5f5' }}>
											<div className="lh-base" style={{ "fontSize": '12px', 'color': grid1 === e.name ? '#ffffffb3' : '#0f549d' }}>{e.name}</div>
											<div className="lh-1" style={{ "fontSize": '16px', 'color': grid1 === e.name ? '#fff' : '#0f549d' }}>{e.value}</div>
										</div>
									</div>
								)
							})}
							<div className="col-12" style={{ 'height': '50px' }}></div>
							<div className=" d-flex align-items-center justify-content-between" style={{ "background": '#fff', 'height': '32px' }}>
								<div>Zápas</div>
								<div>
									<div style={{ "width": '75px' }}>
										<img src={require(`./static/images/sites/nike.png`)} style={{ 'borderRadius': '5px' }} className="w-100" alt="Offer 1"></img>
									</div>
								</div>
							</div>							{grid2array.map(e => {
								return (
									<div className="col-4 px-0 py-0 bg-white">
										<div
											onClick={() => { setGrid2(e.name); setLastGrid(2) }}
											className={`d-flex flex-column align-items-center  justify-content-center  px-2 ${grid2 === e.name ? '' : 'hover-effect'}`}
											style={{ 'height': '40px', 'border': '1px solid #d6d8da', 'borderRadius': '0', 'borderCollapse': 'collapse', 'cursor': 'pointer', 'background': grid2 === e.name ? '#5d9cec' : '#fff' }}>
											<div className="lh-base" style={{ "fontSize": '12px', 'color': grid2 === e.name ? '#fff' : '#7f7f7f' }}>{e.name}</div>
											<div className="lh-1" style={{ "fontSize": '16px', 'color': grid2 === e.name ? '#fff' : '#000' }}>{e.value}</div>
										</div>
									</div>
								)
							})}
						</div>
						{/* calucaltor */}
						<div className="row">
							<div className="col-12" style={{ "height": '50px' }}></div>
							<div className="col-5">
								<div
									className="text-center fs-4 d-flex align-items-center justify-content-center"
									style={{ 'color': '#fff' }}
								>{grid1array.find(e => e.name === grid1).value}</div>
							</div>
							<div className="col-2 text-white fs-6 d-flex align-items-center justify-content-center" >KURZ</div>
							<div className="col-5">
								<div
									className="text-center fs-4 d-flex align-items-center justify-content-center"
									style={{ 'color': '#fff' }}
								>{grid2array.find(e => e.name === grid2).value}</div>
							</div>
							<div className="col-5">
								<input
									value={stake1}
									onChange={e => { setStake1(e.target.value); setLastStake(e.target.name) }}
									name='stake1'
									type="number"
									min="0"
									step={"10"}
									className="form-control text-center placeholder-color-white p-0 text1 rounded rounded-3"
									placeholder="€"
									style={{ "height": '40px', "background": "transparent", 'fontSize': "20px", "boxShadow": `none`, 'border': `3px solid #1186f0`, 'color': `#fff` }}
								></input>
							</div>
							<div className="col-2 text-white fs-6 d-flex align-items-center justify-content-center" >VKLAD</div>
							<div className="col-5">
								<input
									value={stake2}
									onChange={e => { setStake2(e.target.value); setLastStake(e.target.name) }}
									name='stake2'
									type="number"
									min="0"
									step={"10"}
									className="form-control text-center placeholder-color-white p-0 text1 rounded rounded-3"
									placeholder="€"
									style={{ "height": '40px', "background": "transparent", 'fontSize': "20px", "boxShadow": `none`, 'border': `3px solid #ff8000`, 'color': `#fff` }}
								></input>
							</div>
							<div className="col-12" style={{ "height": '50px' }}></div>
							{/* PAYOUT */}
							<div className="col-5">
								<div
									className="text-center fs-4 d-flex align-items-center justify-content-center"
									style={{ 'color': '#fff' }}
								>{(grid1array.find(e => e.name === grid1).value * stake1).toFixed(2)}€</div>
							</div>
							<div className="col-2 text-white fs-6 d-flex align-items-center justify-content-center" >VYHRA</div>
							<div className="col-5">
								<div
									className="text-center fs-4 d-flex align-items-center justify-content-center"
									style={{ 'color': '#fff' }}
								>{(grid2array.find(e => e.name === grid2).value * stake2).toFixed(2)}€</div>
							</div>
							{/* ROI */}
							<div className="col-5">
								<div
									className={`text-center fs-4 d-flex align-items-center justify-content-center ${(((grid1array.find(e => e.name === grid1).value - 1) * stake1) - stake2) < 0 ? 'text-white' : 'text-white'}`}
								>{((((grid1array.find(e => e.name === grid1).value - 1) * stake1) - stake2) / (parseFloat(stake1) + parseFloat(stake2)) * 100).toFixed(2)}%</div>
							</div>
							<div className="col-2 text-white fs-6 d-flex align-items-center justify-content-center" >ROI</div>
							<div className="col-5">
								<div
									className={`text-center fs-4 d-flex align-items-center justify-content-center ${(((grid2array.find(e => e.name === grid2).value - 1) * stake2) - stake1) < 0 ? 'text-white' : 'text-white'}`}
								>{((((grid2array.find(e => e.name === grid2).value - 1) * stake2) - stake1) / (parseFloat(stake1) + parseFloat(stake2)) * 100).toFixed(2)}%</div>
							</div>
							{/* ZISK */}
							<div className="col-5">
								<div
									className={`font-weight-bold text-center fs-4 d-flex align-items-center justify-content-center ${(((grid1array.find(e => e.name === grid1).value - 1) * stake1) - stake2) < 0 ? 'text-danger' : 'text-success'}`}
								><b>{(((grid1array.find(e => e.name === grid1).value - 1) * stake1) - stake2).toFixed(2)}€</b></div>
							</div>
							<div className="col-2 text-white fs-6 d-flex align-items-center justify-content-center" >ZISK</div>
							<div className="col-5">
								<div
									className={`font-weight-bold text-center fs-4 d-flex align-items-center justify-content-center ${(((grid2array.find(e => e.name === grid2).value - 1) * stake2) - stake1) < 0 ? 'text-danger' : 'text-success'}`}
								><b>{(((grid2array.find(e => e.name === grid2).value - 1) * stake2) - stake1).toFixed(2)}€</b></div>
							</div>
						</div>

						{/* stats */}
						<div style={{ 'height': '50px' }}></div>
						<h3 style={{ 'fontSize': '1rem' }} className="text-white lh-lg fw-normal text-center">Takáto možnosť mať istý zisk sa nevyskytuje pri každom zápase ale iba pri <b>malom percente</b> z nich. Manuálne to hľadať by bolo extrémne časovo náročné. </h3>
						<div style={{ 'height': '20px' }}></div>
						<h3 style={{ 'fontSize': '1.6rem' }} className="text-white lh-lg fw-normal text-center"><b style={{ "color": '#6f00fd' }}>Arbitrage.sk</b> to robí <b className="" style={{ "color": '#6f00fd' }}>24/7</b></h3>
						<div style={{ 'height': '20px' }}></div>
						<h3 style={{ 'fontSize': '1rem' }} className="text-white lh-lg fw-normal text-center"><b>Denne</b> sa vyskytnú <b>desiatky</b> takýchto príležitosti <b>so ziskom nad 3%</b> a priemerne sú aktívne <b>7 minút</b>. Arbitrage.sk skontroluje <b>5000 zápasov</b> za 8 minút v <b>6 slovenských</b> stávkových kanceláriach. </h3>
						<div style={{ 'height': '20px' }}></div>
						{/* sites */}
						<div className="row">
							{['nike', 'tipsport', 'fortuna', 'doxxbet', 'tipos', 'synottip'].map(e => {
								return (
									<div className="col px-1">
										<div style={{ "maxWidth": '75px' }}>
											<img src={require(`./static/images/sites/${e}.png`)} style={{ 'borderRadius': '5px' }} className="w-100" alt={e}></img>
										</div>
									</div>
								)
							})}
						</div>
						{/* CTA */}
						<div className={`d-flex align-items-center justify-content-center ${isVisible ? 'fade-in' : 'hidden'}`} style={{ 'height': '150px' }}>
							<a className=" text-white btn rounded rounded-5 px-3" style={{ "background": '#6f00fd', 'paddingTop': '7px' }} href='/signup'>
								<span className="lh-base py-2 font3 text-uppercase " style={{ "fontSize": `${fontSize * pr}px` }}>VYSKUŠAŤ ARBITRÁŽE PO 1% PO REGISTRÁCII</span>
							</a>
						</div>
						{/* TELEGRAM */}
						{/* <div className={`d-flex align-items-center justify-content-center ${isVisible ? 'fade-in' : 'hidden'}`} style={{ 'height': '130px' }}>
							<a className=" text-white btn rounded rounded-5 px-3 " style={{ "background": '#6f00fd', 'paddingTop': '6px' }} href='https://t.me/+ppPtuff3GrMyMGU0'>
								<span className="lh-base py-0 font3 text-uppercase d-flex justify-content-center align-items-center" style={{ "fontSize": `${fontSize * pr}px` }}>PRIPOJIŤ SA DO TELEGRAM SKUPINY

								</span>
							</a>
						</div> */}
						{/* SLIDERS */}
						{/* <div className="row  rounded-5" style={{ 'height': '400px', 'background': '#6f00fd' }}>
							<div className="col-4" style={{ "height": '60px' }}>
								Pocet stavok za den
							</div>
							<div className="col-8" style={{ "height": '60px' }}>
								<input
									type="range"
									class="form-range"
									id="customRange1"
									min="1"
									max="10"
									step="1"
									defaultValue={"3"}
								/>
							</div>
						</div> */}

						
					</div>
				</div>


			</div>
			{/* <div style={{ "background": '#0F0725', 'height': "100vh" }} className="d-flex align-items-center		justify-content-center flex-column"> */}
			{/* <p className="text-white w-50">Arbitrážne stávkovanie (sure betting, surebets) je stratégia, ktorá zabezpečuje garantovaný zisk využívaním rozdielov v kurzoch medzi stávkovými kanceláriami. Na Slovensku je arbitrážne stávkovanie populárne medzi tipérmi, ktorí hľadajú bezrizikové spôsoby zisku zo športových stávok. Vďaka arbitrážnym stávkam môžete bez rizika pokryť všetky možné výsledky športových udalostí a získať výhodu nad stávkovými spoločnosťami.</p> */}


			{/* </div> */}
		</>
	);
}

export default App;

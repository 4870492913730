import { Navigate, useNavigate } from "react-router-dom";
import { isAuth } from "./helpers/helpers"
import Layout from "./components/Layout";
import { useEffect } from "react";

const Home = () => {
    console.log(isAuth())
    const navigate = useNavigate()
    useEffect(() => {
      navigate('/prematch/arbitrage')
    }, [])
    return (
        <Layout>
            {!isAuth() && <Navigate to={'/'}/>}
            {/* for signed in */}
            <Navigate to={'/arbitrage'}/>
        </Layout>

    );
}

export default Home;

import { isAuth, getCookie } from '../../helpers/helpers'
import Layout from '../../components/Layout'
import { Navigate } from "react-router-dom"
import axios from "axios"
import { useEffect, useState } from "react"
import ClipLoader from "react-spinners/ClipLoader"
import { TiTick } from "react-icons/ti"
import Modal from 'react-bootstrap/Modal'
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from "react-router-dom";

const Pricing = () => {
    const token = getCookie('token')
    const [dataFetched, setDataFetched] = useState(true)
    const [error, setError] = useState(false)
    const [open, setOpen] = useState(false)
    const [listPrice, setListPrice] = useState(75)
    const [price, setPrice] = useState(75)
    const navigate = useNavigate()

    // const response = await axios.post(`${process.env.REACT_APP_API}/active/arbitrage`, { token })
    // useEffect(() => {
    //     axios({
    //         method: 'POST',
    //         url: `${process.env.REACT_APP_API}/pricing`,
    //         data: { token }
    //         // headers: {
    //         //     Authorization: `Bearer ${token}`
    //         // }
    //     })
    //         .then(response => {
    //             console.log(response.data)
    //             setDataFetched(true)
    //             setListPrice(response.data[0].list_price)
    //         })
    //         .catch(error => {
    //             if (error.response) {
    //                 setError(error.response.data.error)
    //             }
    //             setDataFetched(true)
    //         })
    // }, [])

    const coeficient = 0
    const size2 = window.innerWidth > 777 ? 16 : (window.innerWidth < 600 ? 12 : 14)
    const r = 1.6


    return (
        <Layout>
            {!isAuth() && <Navigate to={'/'} />}
            <>
                {/* 6F00FD55 */}
                <Modal
                    onHide={() => setOpen(false)}
                    show={(open)}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    className="placeholder-color-white"
                >
                    <Modal.Header closeButton style={{ 'border': 'none' }} className="text-dark">
                        <Modal.Title id="contained-modal-title-vcenter">
                            Ako poslať peniaze?
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{}}>
                        <p>Peniaze treba poslať na účet SK0411000000002934621360</p>
                        <p>Do poznámky treba napísať email</p>
                        <p>Službu aktivujem hneď ako mi prídu peniaze. Ak prevod trvá niekoľko dní stači poslať screenshot platby.</p>
                        <p>Ak by ste mali nejaké otázky kontaktujte ma na +421 917 782 762 alebo cez instagram na <a href="https://instagram.com/arbitrage.sk">@arbitrage.sk</a></p>

                        <a className='btn btn-success w-100 my-2' href={`https://payme.sk/?V=1&IBAN=SK0411000000002934621360&AM=${price}.00&CC=EUR&DT=&PI=&MSG=${jwtDecode(token).email}&CN=`}>payme.sk link</a>
                        <div className='btn btn-secondary w-100' onClick={() => navigator.clipboard.writeText('SK0411000000002934621360')}>kopírovať IBAN</div>
                    </Modal.Body>
                </Modal>
                {window.innerWidth > 600
                    ?
                    <div className='container-fluid d-flex align-items-center justify-content-center font3 ' style={{ 'height': '90vh' }}>

                        <div className={`row d-flex align-items-center justify-content-center px-3`} style={{ 'maxWidth': '900px' }}>
                            <div className={` col rounded rounded-3 d-flex flex-column`} style={{ 'background': '#222222', 'height': '380px', 'width': '250px' }}>
                                <div className='h1 text-white text-center py-3'>Free</div>
                                <div className='h3 text-white text-center'><span style={{ "fontSize": '42px', 'paddingLeft': '10px' }}>0<span style={{ 'fontSize': '28px' }}>€</span></span></div>
                                <div style={{ 'fontSize': size2 + 'px' }} className=' text-white text-center  mt-3'>Arbitráže zo ziskom do 1%</div>
                                <div style={{ 'fontSize': size2 + 'px' }} className=' text-white text-center '>BetTracker na sledovanie ziskov</div>
                                <div style={{ 'fontSize': size2 + 'px' }} className=' text-white text-center '>Aktuálne kurzy</div>
                                <div style={{ 'fontSize': size2 + 'px' }} className=' text-white text-center'>Podpora 24/7</div>
                                <div className='btn w-100 text-white mt-auto mb-3' style={{ "background": '#6f00FD' }} onClick={() => navigate("/prematch/arbitrage")}>Mám záujem</div>

                            </div>
                            <div className='col mx-4 rounded rounded-3 d-flex flex-column' style={{ 'background': '#6F00FD85', 'height': '400px', 'width': '250px' }}>
                                <div className='h1 text-white text-center py-3'>Ultimate</div>
                                <div className='h3 text-white text-center ps-2'><span style={{ "fontSize": '42px', 'paddingLeft': '10px' }}>99</span><span style={{ 'fontSize': '28px' }}>€</span></div>
                                <div style={{ 'fontSize': size2 + 'px', "marginTop": '20px' }} className=' text-white text-center'>Prístup na 30 dní</div>
                                <div style={{ 'fontSize': size2 + 'px' }} className=' text-white text-center'>Všetky nájdené arbitráže</div>
                                <div style={{ 'fontSize': size2 + 'px' }} className=' text-white text-center '>Bet365</div>
                                <div style={{ 'fontSize': size2 + 'px' }} className=' text-white text-center '>Arbitráže s prienikom*</div>
                                <div style={{ 'fontSize': size2 + 'px' }} className=' text-white text-center '>Valuebets</div>
                                <div style={{ 'fontSize': size2 + 'px' }} className=' text-white text-center '>Všetko z Premium</div>
                                <div className='btn w-100 text-white mt-auto mb-3' style={{ "background": '#6f00FD' }} onClick={() => (setPrice(99), setOpen(true))}>Mám záujem</div>

                            </div>
                            <div className='col rounded rounded-3 d-flex flex-column' style={{ 'background': '#222222', 'height': '380px', 'width': '250px' }}>
                                <div className='h1 text-white text-center py-3'>Premium</div>
                                <div className='h3 text-white text-center'><span style={{ "fontSize": '42px', 'paddingLeft': '10px' }}>75<span style={{ 'fontSize': '28px' }}>€</span></span></div>
                                <div style={{ 'fontSize': size2 + 'px', "marginTop": '10px' }} className=' text-white text-center'>Prístup na 30 dní</div>
                                <div style={{ 'fontSize': size2 + 'px' }} className=' text-white text-center' >Arbitráže zo ziskom do 6%</div>
                                <div style={{ 'fontSize': size2 + 'px' }} className=' text-white text-center '>6 slovenských stávkových spoločnosti</div>
                                <div style={{ 'fontSize': size2 + 'px' }} className=' text-white text-center '>6 športov</div>
                                <div style={{ 'fontSize': size2 + 'px' }} className=' text-white text-center '>Upozornenia do aplikácie Telegram</div>
                                <div style={{ 'fontSize': size2 + 'px' }} className=' text-white text-center '>Všetko z Free</div>
                                <div className='btn w-100 text-white mt-auto mb-3' style={{ "background": '#6f00FD' }} onClick={() => (setPrice(75), setOpen(true))}>Mám záujem</div>

                            </div>
                            <div className='text-center mt-5 ' style={{ "color": '#878787' }}>
                                *Prienik znamená, že je šanca na výhru oboch stávok, napríklad viac ako 4.5 a menej ako 6.5 gólov.
                            </div>

                        </div>
                    </div>
                    :
                    <div className='container-fluid'>
                        <div className='row px-3 mt-3' >
                            <div className='rounded rounded-3 d-flex flex-column' style={{ 'background': '#222222' }}>
                                <div className='h2 text-white text-center pt-2'>Free</div>
                                <div className='h3 lh-1 text-white text-center'><span style={{ "fontSize": '34px', 'paddingLeft': '10px' }}>0<span style={{ 'fontSize': '20px' }}>€</span></span></div>
                                <div style={{ 'fontSize': size2 + 'px', "marginTop": '10px' }} className=' text-white text-center  '>Arbitráže so ziskom do 1%</div>
                                <div style={{ 'fontSize': size2 + 'px' }} className=' text-white text-center '>BetTracker na sledovanie ziskov</div>
                                <div style={{ 'fontSize': size2 + 'px' }} className=' text-white text-center '>Aktuálne kurzy</div>
                                <div style={{ 'fontSize': size2 + 'px' }} className=' text-white text-center'>Podpora 24/7</div>
                                <div style={{ 'height': '15px' }}></div>
                                <div className='btn w-100 text-white mt-auto mb-3' style={{ "background": '#6f00FD' }} onClick={() => navigate("/prematch/arbitrage")}>Mám záujem</div>
                            </div>
                            <div className='rounded rounded-3 d-flex flex-column my-3' style={{ 'background': '#222222' }}>
                                <div className='h2 text-white text-center py-2'>Ultimate</div>
                                <div className='h3 lh-1 text-white text-center ps-2'><span style={{ "fontSize": '34px', 'paddingLeft': '10px' }}>99</span><span style={{ 'fontSize': '20px' }}>€</span></div>
                                <div style={{ 'fontSize': size2 + 'px', "marginTop": '20px' }} className=' text-white text-center'>Prístup na 30 dní</div>
                                <div style={{ 'fontSize': size2 + 'px' }} className=' text-white text-center'>Všetky nájdené arbitráže</div>
                                <div style={{ 'fontSize': size2 + 'px' }} className=' text-white text-center'>Bet365</div>
                                <div style={{ 'fontSize': size2 + 'px' }} className=' text-white text-center '>Arbitráže s prienikom*</div>
                                <div style={{ 'fontSize': size2 + 'px' }} className=' text-white text-center '>Valuebets</div>
                                <div style={{ 'fontSize': size2 + 'px' }} className=' text-white text-center '>Všetko z Premium</div>
                                <div style={{ 'height': '15px' }}></div>
                                <div className='btn w-100 text-white mt-auto mb-3' style={{ "background": '#6f00FD' }} onClick={() => (setPrice(99), setOpen(true))}>Mám záujem</div>
                            </div>
                            <div className='rounded rounded-3 d-flex flex-column' style={{ 'background': '#222222' }}>
                                <div className='h2 text-white text-center py-2'>Premium</div>
                                <div className='h3 lh-1 text-white text-center'><span style={{ "fontSize": '34px', 'paddingLeft': '10px' }}>75<span style={{ 'fontSize': '20px' }}>€</span></span></div>
                                <div style={{ 'fontSize': size2 + 'px', "marginTop": '10px' }} className=' text-white text-center'>Prístup na 30 dní</div>
                                <div style={{ 'fontSize': size2 + 'px' }} className=' text-white text-center' >Arbitráže so ziskom do 6%</div>
                                <div style={{ 'fontSize': size2 + 'px' }} className=' text-white text-center '>6 stávkových spoločnosti a 6 športov</div>
                                <div style={{ 'fontSize': size2 + 'px' }} className=' text-white text-center '>Upozornenia do aplikácie Telegram</div>
                                <div style={{ 'fontSize': size2 + 'px' }} className=' text-white text-center '>Všetko z Free</div>
                                <div style={{ 'height': '15px' }}></div>
                                <div className='btn w-100 text-white mt-auto mb-3' style={{ "background": '#6f00FD' }} onClick={() => (setPrice(75), setOpen(true))}>Mám záujem</div>
                            </div>
                        </div>
                        <div className='text-center mt-5 ' style={{ "color": '#878787','fontSize':'12px' }}>
                            *Prienik znamená, že je šanca na výhru oboch stávok, napríklad viac ako 4.5 a menej ako 6.5 gólov.
                        </div>
                    </div>
                }
            </>
        </Layout >
    )
}

export default Pricing

import { Navigate, useNavigate } from "react-router-dom"
import { isAuth, signout } from  "../../helpers/helpers"

import { useEffect } from "react"
import cookie from 'js-cookie'

const Signout = () => {
    useEffect(()=>{
        signout()
    },[])
    
    const navigate = useNavigate()
    cookie.remove("token")
    navigate('/')

    return (
        <div id="signout">
            {/* <h1>Signout</h1> */}
            {isAuth() ?<Navigate to={'/signout'}/>:<Navigate to={'/'}/>}            
        </div>
    )
}
export default Signout
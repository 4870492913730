// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#message{
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
#messageHeadline{
    font-size: 46px;
    font-family: 'Teko', sans-serif;
    font-weight: bold;
    color: var(--black);
}
#messageBody{
    font-family: 'Poppins', sans-serif;
    font-size: 17px;
}`, "",{"version":3,"sources":["webpack://./src/style/auth/message.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,sBAAsB;AAC1B;AACA;IACI,eAAe;IACf,+BAA+B;IAC/B,iBAAiB;IACjB,mBAAmB;AACvB;AACA;IACI,kCAAkC;IAClC,eAAe;AACnB","sourcesContent":["#message{\n    width: 100vw;\n    height: 100vh;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    flex-direction: column;\n}\n#messageHeadline{\n    font-size: 46px;\n    font-family: 'Teko', sans-serif;\n    font-weight: bold;\n    color: var(--black);\n}\n#messageBody{\n    font-family: 'Poppins', sans-serif;\n    font-size: 17px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

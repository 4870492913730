import React, { useState } from "react";
import { BrowserRouter, Navigate, Routes as ReactRoutes, Route } from 'react-router-dom'
import App from "./App";
import Home from "./Home";
import Signup from './pages/auth/Signup'
import Signin from './pages/auth/Signin'
import Signout from './pages/auth/Signout'
import Verify from './pages/auth/Verify'
import Reset from './pages/auth/Reset'
import Forgot from './pages/auth/Forgot'
import Message from "./pages/auth/Message";
import PrematchArbitrage from "./pages/PrematchArbitrage/PrematchArbitrage";
import BetTracker from "./pages/BetTracker/BetTracker";
import GettingStartedGuide from './pages/GettingStartedGuilde/GettingStartedGuide'
import Profile from './pages/Profile/Profile'
import Pricing from './pages/Pricing/Pricing'
import Calculator from './pages/Calculator/Calculator'
import Admin from "./pages/Admin/Admin";
import Bonuses  from "./pages/Bonuses/Bonuses";
import LiveArbitrage from './pages/LiveArbitrage/LiveArbitrage'
import PrematchValuebets from "./pages/PrematchValuebets/PrematchValuebets";

const Routes = () => {

    return (
        <BrowserRouter>
            <ReactRoutes>
                <Route path="*" exact element={<App />} />
                <Route path="/" exact element={<App />} />
                <Route path="/home" exact element={<Home />} />
                <Route path="/signup" exact element={<Signup />} />
                <Route path="/signin" exact element={<Signin />} />
                <Route path="/signout" exact element={<Signout />} />
                <Route path="/forgot-password" exact element={<Forgot />} />
                <Route path="/reset-password" exact element={<Reset />} />
                <Route path="/verify" exact element={<Verify />} />
                <Route path="/message" exact element={<Message />} />
                <Route path="/bet-tracker" exact element={<BetTracker />} />
                <Route path="/admin" exact element={<Admin />} />
                <Route path="/bonuses" exact element={<Bonuses />} />
                
                
                <Route path="/prematch/arbitrage" exact element={<PrematchArbitrage />} />
                <Route path="/prematch/valuebets" exact element={<PrematchValuebets />} />
                <Route path="/live/arbitrage" exact element={<LiveArbitrage />} />
                
                <Route path="/pricing" exact element={<Pricing />} />
                <Route path="/profile" exact element={<Profile />} />
                <Route path="/calculator" exact element={<Calculator />} />
                <Route path="/getting-started-guide" exact element={<GettingStartedGuide/>} />
                
            </ReactRoutes>
        </BrowserRouter>
    )

}

export default Routes
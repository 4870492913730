import { useEffect, useState } from "react"
import Layout from "../../components/Layout"
import { MdLink, MdLinkOff } from 'react-icons/md'
import { RiDeleteBin6Line } from 'react-icons/ri'

const Calculator = () => {
    const [linked, setLinked] = useState(true)
    const [lastStake, setLastStake] = useState()
    const [stake1, setStake1] = useState('')
    const [stake2, setStake2] = useState('')
    const [value1, setValue1] = useState('')
    const [value2, setValue2] = useState('')

    const decimalPlaces = 2
    // significantDigits
    // toPrecision

    const roundDigits = (number) => {
        const factor = 10 ** decimalPlaces
        const rounded = (Math.round(number * factor) / factor).toFixed(decimalPlaces)
        return isNaN(rounded) ? Math.round(0).toFixed(decimalPlaces) : rounded
    }

    useEffect(() => {
        if (linked && lastStake === 'stake1' && value1 > 1 && value2 > 1) {
            const stake2 = (stake1 * value1 / value2)
            setStake2(roundDigits(stake2))
            if (stake1 === '') {
                setStake2('')
            }
        }
    }, [stake1, value1, value2])

    useEffect(() => {
        if (linked && lastStake === 'stake2' && value1 > 1 && value2 > 1) {
            const stake1 = (stake2 * value2 / value1)
            setStake1(roundDigits(stake1))
            if (stake2 === '') {
                setStake1('')
            }
        }
    }, [stake2, value1, value2])

    const payout1 = value1 * stake1
    const payout2 = value2 * stake2
    const total = parseFloat(stake1) + parseFloat(stake2)
    return (
        <Layout>
            <div className="container-fluid bg-white">
                <div className="container d-flex justify-content-center align-items-center flex-column" style={{ 'maxWidth': '600px', "minHeight": "calc(100vh - 56px)", 'background': "#fff" }}>
                    <div className="row my-2">
                        <div className="col d-flex justify-content-center align-items-center">
                            <input
                                value={value1}
                                onChange={e => { setValue1(e.target.value) }}
                                name='stake1'
                                type="number"
                                min="1"
                                step={"0.1"}
                                className="form-control text-center"
                                placeholder="Enter bet 1 odds"
                            ></input>
                        </div>
                        <div className="col-1"></div>
                        <div className="col d-flex justify-content-center align-items-center">
                            <input
                                value={value2}
                                onChange={e => { setValue2(e.target.value) }}
                                name='stake2'
                                type="number"
                                min="1"
                                step={"0.1"}
                                className="form-control text-center"
                                placeholder="Enter bet 2 odds"
                            ></input>
                        </div>
                    </div>
                    <div className="row my-2">
                        <div className="col d-flex justify-content-center align-items-center">
                            <input
                                value={stake1}
                                onChange={e => { setStake1(e.target.value); setLastStake(e.target.name) }}
                                name='stake1'
                                type="number"
                                min="0"
                                step={"10"}
                                className="form-control text-center"
                                placeholder="Enter stake"
                            ></input>
                        </div>
                        <div className="col-1 d-flex justify-content-center align-items-center p-0">
                            {linked ? <MdLink onClick={() => setLinked(!linked)} size={"100%"} style={{ 'maxWidth': '30px' }} /> : <MdLinkOff onClick={() => setLinked(!linked)} size={"100%"} style={{ 'maxWidth': '30px' }} />}
                        </div>
                        <div className="col d-flex justify-content-center align-items-center">
                            <input
                                value={stake2}
                                onChange={e => { setStake2(e.target.value); setLastStake(e.target.name) }}
                                name='stake2'
                                type="number"
                                min="0"
                                step={"10"}
                                className="form-control text-center"
                                placeholder="Enter stake"
                            ></input>
                        </div>
                    </div>

                    <div className="row my-2 w-100">
                        <div className="col text-center">{roundDigits(payout1)}€</div>
                        <div className="col-1 d-flex align-items-center justify-content-center" style={{ 'fontSize': '12px' }}>PAYOUT</div>
                        <div className="col text-center">{roundDigits(payout2)}€</div>
                    </div>
                    <div className="row my-2 w-100">
                        <div className={`col text-center ${payout1 - total < 0 && 'text-danger'}`}>{roundDigits(payout1 - total)}€</div>
                        <div className="col-1 d-flex align-items-center justify-content-center" style={{ 'fontSize': '12px' }}>PROFIT</div>
                        <div className={`col text-center ${payout2 - total < 0 && 'text-danger'}`}>{roundDigits(payout2 - total)}€</div>
                    </div>
                    <div className="row my-2 w-100">
                        <div className={`col text-center ${payout1 - total < 0 && 'text-danger'}`}>{roundDigits((payout1 - total) / total * 100)}%</div>
                        <div className="col-1 d-flex align-items-center justify-content-center" style={{ 'fontSize': '12px' }}>ROI</div>
                        <div className={`col text-center ${payout2 - total < 0 && 'text-danger'}`}>{roundDigits((payout2 - total) / total * 100)}%</div>
                    </div>
                    <div className="col-1 d-flex justify-content-center align-items-center p-0 mt-3" style={{ "height": '30px' }}>
                        {(stake1 || stake2 || value1 || value2) &&
                            <RiDeleteBin6Line onClick={() => (setStake1(''), setStake2(''), setValue1(''), setValue2(''))} size={"100%"} style={{ 'maxWidth': '20px' }} color="grey" />
                        }
                    </div>
                </div>

            </div>
        </Layout>
    )
}

export default Calculator

import Layout from "../../components/Layout"
import { getCookie } from "../../helpers/helpers"
import axios from "axios"
import { useEffect, useState } from "react"
import ClipLoader from "react-spinners/ClipLoader";
import cookie from 'js-cookie'
import { useNavigate } from "react-router-dom";


const Profile = () => {
    const token = getCookie('token')
    const [error, setError] = useState()
    const [dataFetched, setDataFetched] = useState(false)
    const [prematch_arbitrage_notification, setPrematch_arbitrage_notification] = useState(false)
    const [prematch_arbitrage_notification_to, setPrematch_arbitrage_notification_to] = useState('')
    const [prematch_arbitrage_notification_min, setPrematch_arbitrage_notification_min] = useState(0)
    const [prematch_arbitrage_notification_sites_ids, setPrematch_arbitrage_notification_sites_ids] = useState([])
    const [prematch_arbitrage_notification_sports_ids, setPrematch_arbitrage_notification_sports_ids] = useState([])
    const [referral_code, setReferral_code] = useState('')
    const [sports, setSports] = useState('')
    const [sites, setSites] = useState('')
    const [invoices, setInvoices] = useState([])
    const [customLink, setCustomLink] = useState('')
    const [message, setMessage] = useState('')
    const navigate = useNavigate()


    useEffect(() => {
        console.log(prematch_arbitrage_notification_sports_ids)
    }, [prematch_arbitrage_notification_sports_ids])

    useEffect(() => {
        axios({
            method: 'POST',
            url: `${process.env.REACT_APP_API}/profile/read`,
            data: { token }
        })
            .then(response => {
                setPrematch_arbitrage_notification(response.data.profile.prematch_arbitrage_notification)
                setPrematch_arbitrage_notification_to(response.data.profile.prematch_arbitrage_notification_to)
                setPrematch_arbitrage_notification_min(response.data.profile.prematch_arbitrage_notification_min)
                setPrematch_arbitrage_notification_sites_ids(response.data.profile.prematch_arbitrage_notification_sites_ids)
                setPrematch_arbitrage_notification_sports_ids([...response.data.profile.prematch_arbitrage_notification_sports_ids])
                setReferral_code(response.data.referral_code)
                setInvoices(response.data.invoices)
                setSports(response.data.sports)
                setSites(response.data.sites)
                setCustomLink(process.env.REACT_APP_URL + '/signup?i=' + response.data.profile.referral_code + '&email=' + response.data.profile.email)
                setDataFetched(true)
            })
            .catch(error => {
                if (error.response) {
                    setError(error.response.data.error)
                }
                setDataFetched(true)
            })
    }, [])

    const save = () => {
        axios({
            method: 'POST',
            url: `${process.env.REACT_APP_API}/profile/update`,
            data: { token, prematch_arbitrage_notification, prematch_arbitrage_notification_to, prematch_arbitrage_notification_min, prematch_arbitrage_notification_sites_ids, prematch_arbitrage_notification_sports_ids },
        })
            .then(response => {
                setMessage('Updated successfully. Changes take up to 5 minutes to start working. ')
            })
            .catch(error => {
                setError(error.response.data.error)
            })
    }

    if (!dataFetched) {
        return (
            <div className="container d-flex align-items-center justify-content-center" style={{ "height": "calc(100vh - 56px)" }}>
                <ClipLoader color="#000" size={30} margin={5} speedMultiplier={1} />
            </div>
        )
    }


    return (
        <Layout>
            <div className="container-fluid p-3 bg-light h-100" style={{ 'minHeight': '100vh' }}>
                <div className="row">
                    {error &&
                        <div className="alert alert-danger alert-dismissible mt-3" role="alert">
                            {error}
                            <button className="btn-close" aria-label="close" data-bs-dismiss="alert"></button>
                        </div>
                    }
                </div>
                <div className="row">
                    <div className="col-auto">
                        Send opportunity notifications:
                    </div>
                    <div className="col">
                        <div className="form-check form-switch">
                            <input
                                type="checkbox"
                                id="send_emails"
                                className="form-check-input"
                                checked={prematch_arbitrage_notification}
                                onChange={e => { setPrematch_arbitrage_notification(!prematch_arbitrage_notification) }}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        Enter telegram id: (nie meno ale to co je za @)
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <input
                            value={prematch_arbitrage_notification_to}
                            onChange={e => { setPrematch_arbitrage_notification_to(e.target.value) }}
                            name='send to'
                            type="text"
                            className="form-control form-control-primary"
                            placeholder="Enter account"
                            list='datalist_account1'
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col">

                        <div className="container-fluid px-1 pt-3 pb-1">
                            <div className="alert alert-primary m-0">Pri zmene telegram uctu je potrebne poslat spravu "update" na telegram ucet <a style={{ 'textDecoration': 'none' }} href="https://t.me/arbitrage_sk_bot">@arbitrage_sk_bot</a></div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        Minimum ROI of opportunity:
                    </div>
                </div>
                <div className="row">
                    <div className="col">

                        <input
                            value={prematch_arbitrage_notification_min}
                            onChange={e => { setPrematch_arbitrage_notification_min(e.target.value) }}
                            name='send to'
                            type="text"
                            className="form-control form-control-primary"
                            placeholder="Enter account"
                            list='datalist_account1'
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        Sports:
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        {sports.map(sport => {
                            const handleChange = () => {
                                if (prematch_arbitrage_notification_sports_ids.includes(sport.id)) {
                                    setPrematch_arbitrage_notification_sports_ids([...prematch_arbitrage_notification_sports_ids.filter(e => e !== sport.id)])
                                } else {
                                    setPrematch_arbitrage_notification_sports_ids([...prematch_arbitrage_notification_sports_ids, sport.id])
                                }
                            }
                            return (
                                <div className="form-check form-check-inline" key={sport.id}>
                                    <input
                                        type="checkbox"
                                        id="send_emails"
                                        className="form-check-input"
                                        checked={prematch_arbitrage_notification_sports_ids.includes(sport.id)}
                                        onChange={handleChange}
                                    />
                                    <label htmlFor="send_emails" className="form-check-label">{sport.name}</label>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        Sites:
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        {sites.map(site => {
                            const handleChange = () => {
                                if (prematch_arbitrage_notification_sites_ids.includes(site.id)) {
                                    setPrematch_arbitrage_notification_sites_ids([...prematch_arbitrage_notification_sites_ids.filter(e => e !== site.id)])
                                } else {
                                    setPrematch_arbitrage_notification_sites_ids([...prematch_arbitrage_notification_sites_ids, site.id])
                                }
                            }
                            return (
                                <div className="form-check form-check-inline" key={site.id}>
                                    <input
                                        type="checkbox"
                                        id="send_emails"
                                        className="form-check-input"
                                        checked={prematch_arbitrage_notification_sites_ids.includes(site.id)}
                                        onChange={handleChange}
                                    />
                                    <label htmlFor="send_emails" className="form-check-label">{site.name}</label>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div className="container-fluid px-1 pt-3 pb-1">
                            <div className="alert alert-primary m-0">Nezabudnite si vybrat ktore sporty a stavkove spolocnosti vam maju chodit. Ak nevyberiete ziadne, notifikacie nebudu chodit. Sport/stranka je vybrata ked svieti modra fajka.</div>
                        </div>
                    </div>
                </div>
                <div className="row mt-5">
                    <div className="col">
                        <div className="btn btn-primary" onClick={() => save()}>SAVE</div>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col">
                        {message &&
                            <div className="alert alert-success alert-dismissible mt-3" role="alert">
                                {message}
                                <button className="btn-close" aria-label="close" data-bs-dismiss="alert"></button>
                            </div>
                        }
                    </div>
                </div>
                <div className="row mt-5">
                    <div className="col">
                        {customLink.length > 0 &&
                            <>
                                <div className="mt-4">Invite Friends:</div>
                                <a className="share-btn" href={customLink}>{customLink}</a>
                            </>
                        }
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        {invoices.length > 0 &&
                            <>
                                <div className="mt-4">Invocies:</div>
                                <div className="table table-responsive">
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Date</th>
                                            <th>Amount</th>
                                            <th>State</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {invoices.map(e => {
                                            return (
                                                <tr>
                                                    <td>{e.name}</td>
                                                    <td>{e.invoice_date}</td>
                                                    <td>{e.amount_total}€</td>
                                                    <td className={e.payment_state === 'paid' ? 'table-success' : e.payment_state === 'not_paid' ? 'table-danger' : ''}>{e.payment_state.replace('_', ' ')}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </div>
                            </>
                        }
                    </div>
                </div>
                <div className="row">
                    <div className="btn btn-danger col-auto" onClick={e=>{cookie.remove("token");navigate('/')}}>SIGN OUT</div>
                </div>
            </div>
        </Layout>
    )
}
export default Profile

import React, { useEffect, useState } from "react"
import axios from 'axios'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Form, FormInput, FormError, FormButton, FormText, handleChange, handleBlur, setInputValues } from '../../components/Form'
import '../../style/auth/reset.css'

const Reset = () => {
    const [buttonText, setButtonText] = useState('Set New Password')
    const [error, setError] = useState('')
    const [values, setValues] = useState()
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate()
    const resetPasswordLink = searchParams.get("token")

    useEffect(() => { setInputValues(['newPassword'], setValues) }, [])

    const handleSubmit = (event) => {
        event.preventDefault()
        const { newPassword } = Object.keys(values).reduce((obj, key) => { obj[key] = values[key].value; return obj; }, {})
        axios({
            method: 'PUT',
            url: `${process.env.REACT_APP_API}/auth/password/reset`,
            data: { newPassword, resetPasswordLink }
        })
            .then(response => {
                navigate(response.data.redirect)
            })
            .catch(error => {
                let copyOfValues = values
                Object.keys(copyOfValues).map(key => copyOfValues[key].isActivated = false);
                setValues(copyOfValues)
                setButtonText('Set New Password')
                if (error) {
                    if (error.response) {
                        if (error.response.data) {
                            setError(error.response.data.error)
                        }
                    }
                }
            })
    }

    return (
        <div id="reset" className="bg-white">
            <Form title={'Reset Password'}>

                <FormInput
                    onChange={event => handleChange(event, values, setValues)}
                    onBlur={event => handleBlur(event, values, setValues)}
                    values={values}
                    type={'password'}
                    name={'newPassword'}
                    placeholder={'New password'}
                />
                <FormError error={error} />
                <FormButton text={buttonText} onClick={e => handleSubmit(e)} disabled={false} />
            </Form>
        </div>
    )
}
export default Reset
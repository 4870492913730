// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Inter&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#layoutHeader {
    height: var(--header-height);
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px;
    background-color: #f0f0f0;
}

#layoutHeaderContainer {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
#layoutLeft{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
#layoutLeft a{
    color: var(--black);
    font-size: 14px;
    font-family: 'Teko', sans-serif;
    font-weight: bold;
    text-decoration: none;
}
#layoutRight{
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    height: 100%;
}
#layoutRight a{
    font-family: 'Teko', sans-serif;
    font-weight: bold;
    display: flex;
    font-size: 18px;
    color: var(--black);
    text-decoration: none;
    margin: 0 10px;
}
#layoutPipe{
    font-size: 18px;
    font-family: 'Teko', sans-serif;
}
`, "",{"version":3,"sources":["webpack://./src/style/components/layout.css"],"names":[],"mappings":"AAEA;IACI,4BAA4B;IAC5B,WAAW;IACX,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,YAAY;IACZ,yBAAyB;AAC7B;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;AACvB;AACA;IACI,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;AACA;IACI,mBAAmB;IACnB,eAAe;IACf,+BAA+B;IAC/B,iBAAiB;IACjB,qBAAqB;AACzB;AACA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,WAAW;IACX,YAAY;AAChB;AACA;IACI,+BAA+B;IAC/B,iBAAiB;IACjB,aAAa;IACb,eAAe;IACf,mBAAmB;IACnB,qBAAqB;IACrB,cAAc;AAClB;AACA;IACI,eAAe;IACf,+BAA+B;AACnC","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');\n\n#layoutHeader {\n    height: var(--header-height);\n    width: 100%;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    padding: 6px;\n    background-color: #f0f0f0;\n}\n\n#layoutHeaderContainer {\n    width: 100%;\n    height: 100%;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n}\n#layoutLeft{\n    height: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n#layoutLeft a{\n    color: var(--black);\n    font-size: 14px;\n    font-family: 'Teko', sans-serif;\n    font-weight: bold;\n    text-decoration: none;\n}\n#layoutRight{\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    width: auto;\n    height: 100%;\n}\n#layoutRight a{\n    font-family: 'Teko', sans-serif;\n    font-weight: bold;\n    display: flex;\n    font-size: 18px;\n    color: var(--black);\n    text-decoration: none;\n    margin: 0 10px;\n}\n#layoutPipe{\n    font-size: 18px;\n    font-family: 'Teko', sans-serif;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import { useEffect, useState } from "react"
import Layout from "../../components/Layout"


const Bonuses = () => {

    return (
        <Layout>
            <div className="container-fluid text-secondary">
                <div>
                    <span style={{ 'color': '#FFDB01' }}>Fortuna</span>
                    <ul>
                        <li>
                            70€ stávka bez rizika, (dá sa mať istých 40€ keď podáš špecialne vyrátanú arbitráž) <a href='https://www.ifortuna.sk/cms/ms-hokej-2024/vstupny-bonus'>Podmienky</a> 
                        </li>
                        <li>
                            každy deň počas MS v hokeji iný bonus <a href="https://casino.ifortuna.sk/hokejovy-kalendar">LINK</a>
                        </li>
                    </ul>
                </div>
                <div>
                    <span style={{ 'color': '#1186f0' }}>Tipsport</span>
                    <ul>
                        <li>
                            30€ registračný bonus <a href='https://www.tipsport.sk/bonusy/vstupny-bonus/podmienky'>Podmienky</a>
                        </li>
                        <li>
                            vstupný bonus podľa prvého vkladu az do 4000€ (pri vklade 100€ bonus 100€ po prestávkovaní 700€ s kurzom min 1.5, pri 200€ bonus 200€ po prestávkovaní 1800€ s kurzom min 3) <a href="https://www.tipsport.sk/bonusy/vstupny-bonus/podmienky">Podmienky</a>
                        </li>
                    </ul>
                </div>
                <div>
                    <span style={{ 'color': '#ff8000' }}>Nike</span>
                    <ul>
                        <li>
                            20 € ZADARMO <a href='https://www.nike.sk/vstupne-bonusy'>Podmienky</a>
                        </li>
                        <li>
                            150 SPINOV ZADARMO <a href='https://www.nike.sk/casino/clanky/kasino-vstupny-bonus'>Podmienky</a>
                        </li>
                        <li>
                            odmena 33€ po stavení 595€ do 14dni potom odmena 20€ za
                            každých prestavkovaných 500€ s kurzom min 2.5 <a href='https://www.nike.sk/clanky/bonus-bez-limitu'>Podmienky</a>
                        </li>
                        <li>
                            5€ každy deň počas MS v hokeji <a href='https://www.nike.sk/clanky/pohodove-majstrovstva'>Kompletné podmienky naspodu</a>
                        </li>
                    </ul>
                </div>
                <div>
                    <span style={{ 'color': '#f40034' }}>Doxxbet</span>
                    <ul>
                        <li>
                            5€ každy deň počas MS v hokeji <a href='https://www.doxxbet.sk/sk/sportove-tipovanie-online/bonusy-a-akcie/tipovanie-ms-v-hokeji#informacie-o-turnaji'>Podmienky</a>
                        </li>
                    </ul>
                </div>
                <div>
                    <span style={{ 'color': '#2d51c3' }}>Tipos</span>
                    <ul>
                        <li>
                            bonus 50% z prvého vkladu maximalne však 50€ (pri vloženi 100€ bonus 50€) <a href='https://www.etipos.sk/Pages/reaktivacny-bonus-sport.html'>Podmienky</a>
                        </li>
                        <li>
                            100 free spinov pri dobiti konta aspoň za 20 € <a href='https://moj.etipos.sk/promoakcia/ziskaj-100-free-spinov'>Podmienky</a>
                        </li>
                    </ul>
                </div>
                <div>
                    <span style={{ 'color': '#fff' }}>Oriontip</span>
                    <ul>
                        <li>
                         vstupný bonus 100% z prvého vkladu pri prestavkovani podla podmienok <a href='https://www.oriontip.sk/Promotion/VstupnyBonus/index.html'>Podmienky</a>
                        </li>
                    </ul>
                </div>
                <div className="text-danger">Vždy si poriadne prečitajte podmienky bonusu a v pripade otázok ma nevahajte kontaktovat.</div>
            </div>
        </Layout>
    )
}

export default Bonuses

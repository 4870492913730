// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#forgot{
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
#signinForgotPassword{
    width: 100%;
    color: var(--black);
    text-decoration: none;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    text-align: right;
}`, "",{"version":3,"sources":["webpack://./src/style/auth/forgot.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;AACA;IACI,WAAW;IACX,mBAAmB;IACnB,qBAAqB;IACrB,kCAAkC;IAClC,eAAe;IACf,iBAAiB;AACrB","sourcesContent":["#forgot{\n    width: 100vw;\n    height: 100vh;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n#signinForgotPassword{\n    width: 100%;\n    color: var(--black);\n    text-decoration: none;\n    font-family: 'Poppins', sans-serif;\n    font-size: 14px;\n    text-align: right;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import cookie from 'js-cookie'

//set in cookie
export const setCookie = (key, value) => {
    if (window !== 'undefined') {
        cookie.set(key, value, {
            expires: 7
        })
    }
}

//remove from cookie
export const removeCookie = (key) => {
    if (window !== 'undefined') {
        cookie.remove(key, {
            expires: 1
        })
    }
}

//get from cookie such as stored token
export const getCookie = (key) => {
    if (window !== 'undefined') {
        return cookie.get(key)
    }
}

export const authenticate = (response, next) => {
    setCookie('token', response.data.token)
    next()
}


export const isAuth = () => {
    // return true

    if (window !== 'undefined') {
        const cookieChecked = getCookie('token')
        if (cookieChecked) {
            return true
        }
        return false

    }
}
export const signout =() =>{
    removeCookie('token')
}
import React, { useEffect, useState } from "react"
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { Form, FormInput, FormError, FormButton, FormText, handleChange, handleBlur, setInputValues } from '../../components/Form'
import '../../style/auth/forgot.css'

const Forgot = () => {
    const [buttonText, setButtonText] = useState('Submit')
    const [error, setError] = useState('')
    const [values, setValues] = useState()
    const navigate = useNavigate()

    useEffect(() => { setInputValues(['email'], setValues) }, [])

    const handleSubmit = (event) => {
        event.preventDefault()
        axios({
            method: 'PUT',
            url: `${process.env.REACT_APP_API}/auth/password/forgot`,
            data: Object.keys(values).reduce((obj, key) => { obj[key] = values[key].value; return obj; }, {})
        })
            .then(response => {
                navigate(response.data.redirect)
            })
            .catch(error => {
                let copyOfValues = values
                copyOfValues.password.value = ''
                Object.keys(copyOfValues).map(key => copyOfValues[key].isActivated = false);
                setValues(copyOfValues)
                setButtonText('Submit')
                setError(error.response.data.error)
            })
    }
    return (
        <div id="forgot" className="bg-white">
            <Form title={'Reset Password'}>
                <FormInput
                    onChange={event => handleChange(event, values, setValues)}
                    onBlur={event => handleBlur(event, values, setValues)}
                    values={values}
                    type={'email'}
                    name={'email'}
                    placeholder={'Email'}
                />

                <FormError error={error} />
                <FormButton text={buttonText} onClick={e => handleSubmit(e)} disabled={false} />
            </Form>
        </div>
    )
}
export default Forgot
import React from 'react'
import { useLocation } from 'react-router-dom'
import { isAuth } from '../helpers/helpers'
import '../style/components/layout.css'
import { getCookie } from '../helpers/helpers'
import { jwtDecode } from "jwt-decode";

const Header = () => {
    const location = useLocation()
    return (
        <header>
            {isAuth() ?
                <nav className="navbar navbar-expand-md navbar-dark fixed-top font1" style={{ "background": "#161616" }}>
                    <div className="container-fluid">
                        <a href="/" className="navbar-brand"> </a>
                        <button className="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#nav" aria-label="Expand Navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse justify-content-md-center " id="nav">
                            <ul className="navbar-nav">
                                <li className="nav-item px-2">
                                    <a href="/prematch/arbitrage" className={`nav-link ${location.pathname === '/prematch/arbitrage' ? 'active' : ''}`}>Predzápasové</a>
                                </li>
                                <li className="nav-item px-2">
                                    <a href="/prematch/valuebets" className={`nav-link ${location.pathname === '/prematch/valuebets' ? 'active' : ''}`}>Valuebets</a>
                                </li>
                                {/* {(process.env.REACT_APP_ADMIN_EMAIL === jwtDecode(getCookie('token')).email||getCookie('liveArbitrage')) && */}
                                {/* <li className="nav-item px-2 d-flex align-items-center"> */}
                                {/* <a href="/live/arbitrage" className={`nav-link ${location.pathname === '/live/arbitrage' ? 'active' : ''}`}>Live</a> */}
                                {/* <div className='text-white m-0 py-0 px-2 bg-danger rounded rounded-5 font1' style={{'fontSize':'12px'}}>BETA</div> */}
                                {/* </li> */}
                                {/* } */}
                                <li className="nav-item px-2">
                                    <a href="/bet-tracker" className={`nav-link ${location.pathname === '/bet-tracker' ? 'active' : ''}`}>Bet Tracker</a>
                                </li>
                                <li className="nav-item px-2">
                                    <a href="/pricing" className={`nav-link ${location.pathname === '/pricing' ? 'active' : ''}`}>Plná verzia</a>
                                </li>
                                <li className="nav-item px-2">
                                    <a href="/getting-started-guide" className={`nav-link ${location.pathname === '/getting-started-guide' ? 'active' : ''}`}>Návod</a>
                                </li>
                                <li className="nav-item px-2">
                                    <a href="/calculator" className={`nav-link ${location.pathname === '/calculator' ? 'active' : ''}`}>Kalkulačka</a>
                                </li>
                                {/* <li className="nav-item px-2">
                                    <a href="/bonuses" className={`nav-link ${location.pathname === '/bonuses' ? 'active' : ''}`}>bonusy</a>
                                </li> */}
                                {/* <li className="nav-item px-2">
                                    <a href="/profile" className={`nav-link ${location.pathname === '/profile' ? 'active' : ''}`}>Profil</a>
                                </li> */}
                                {process.env.REACT_APP_ADMIN_EMAIL === jwtDecode(getCookie('token')).email &&
                                    <li className="nav-item px-2">
                                        <a href="/admin" className={`nav-link ${location.pathname === '/admin' ? 'active' : ''}`}>Admin</a>
                                    </li>
                                }
                            </ul>
                        </div>

                    </div>
                </nav>
                :
                <nav className="navbar navbar-expand navbar-dark bg-dark">
                    <div className="container-fluid">
                        <div className='collapse navbar-collapse justify-content-end'>
                            <div className='row g-3'>
                                <div className='col-auto'>
                                    <a className='btn btn-outline-light' href='/signin'>Sign in</a>
                                </div>
                                <div className='col-auto'>
                                    <a className='btn btn-warning' href='/signup'>Create account</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
            }
        </header>
    )
}




const Layout = ({ children }) => {
    return (
        <>
            <Header />
            <div style={{ height: '56px' }}></div>
            {children}
        </>
    )
}

export default Layout


import { useState } from "react"
import { isAuth } from "../../helpers/helpers"
import Layout from "../../components/Layout"
// import '../style/site/GettingStartedGuide.css'
import { useNavigate, Navigate } from "react-router-dom"
import { MdLink, MdLinkOff } from 'react-icons/md'


const Vklad = () => (
    <>
        <div className="fs-4 font-weight-bold mt-0">Ako správne vkladať peniaze na účet?</div>
        <div>
            Do stávkových spoločnosti nevkladáme peniaze kartou (cez čislo karty, platnosť a CV) ale pomocou internetbanking lebo mnohé banky si účtujú takzvaný “poplatok za hazardné hry” v hodnote okolo 5€.
        </div>
        <img src={require(`../../static/images/poplatky.png`)} className="img-fluid" />
    </>
)
const Storno = ({ showStorno, setShowStorno }) => (
    <>
        <div className="fs-4 font-weight-bold mt-4">Stornovanie stávok</div>
        <div>
            Niekedy sa môže stať, že stávková spoločnosť zamietne tiket z neznámeho dôvodu, čo ohrozí náš zaručený zisk. Preto je lepšie v takejto situácii stornovať všetky ostatné tikety. Všetky spoločnosti ponúkajú túto možnosť. Rozdiel je iba v čase od uzavretia stávky kedy je stornovanie možné. V Nike je to 5 minút, Tipsporte 1 minúta, Fortune 10 minút a v Doxxbete tiež 10 minút. Preto je lepšie prvý tiket podať v spoločnosti s najdlhším časom na stornovanie, aby sme v prípade zamietnutia tiketu mali dostatočný čas na stornovanie druhého tiketu. Ak by bola arbitraž v Nike a Tipsporte. Najprv by som podal tiket v spoločnosti Nike. Ak sa tento tiket úspešne uzavrie, podal by som ďalší v Tipsporte. V prípade, že Tipsport zamietne tiket, budeme mať stále dosť času na stornovanie prvého tiketu.                    </div>
        <div className="btn btn-primary w-100" onClick={() => setShowStorno(!showStorno)}>Zobrazit návod pre každú spoločnosť</div>
        {showStorno &&
            <>
                <img src={require(`../../static/images/stornovanieNike.png`)} className="img-fluid" />
                <img src={require(`../../static/images/stornovanieTipsport.png`)} className="img-fluid" />
                <img src={require(`../../static/images/stornovanieFortuna.png`)} className="img-fluid" />
                <img src={require(`../../static/images/stornovanieDoxxbet.png`)} className="img-fluid" />
            </>
        }
    </>
)
const Handicap = () => (
    <>
        <div className="fs-4 font-weight-bold mt-4">Handicapy</div>
        <div>
            Handicap je stávka, pri ktorej pred začiatkom zápasu pridáme jednému z tímov dodatočné góly. Buď budú záporne (-3.5) alebo kladné (3.5). Ak zápas medzi Barcelonou a Real Madridom skončí 4:3 pre Barcelonu a máme stavené na handicap Tim2 +1.5. Vyhráme lebo po pripočitaní +1.5 gólu Real Madridu je nové skóre 4:4.5. Pre prvý tím by boli víťazne handicapy -0.5, +0.5, +1.5, +2.5 a vyššie. Ak by po pripočitaní handicapu nastala remíza tiket bude vyhodnotení kurzom 1. Pre arbitráž s handicapmi musí platiť, že menší handicap (Tim1 -0.5 je menšie ako Tim2 4.5) musí byť v absolutnej hodnote rovnaký alebo menší.                   </div>
        <img src={require(`../../static/images/handicap.png`)} className="img-fluid" />
    </>
)
const Goal = () => (
    <>
        <div className="fs-4 font-weight-bold mt-4">Počet gólov</div>
        <div>
            Stávka, či počet gólov v základnej hracej dobe zápasu bude menší alebo väčší ako uvedená hranica. V prípade rovnosti bude príležitosť vyhodnotená kurzom 1.                    </div>

    </>
)
const DrawNoBet = () => (
    <>
        <div className="fs-4 font-weight-bold mt-4">Zápas bez remízy</div>
        <div>
            Stávka na víťazstvo prvého, alebo druhého tímu. V prípade remízy sa vracia vklad.
        </div>
    </>
)
const PWA = () => (
    <>
        <div className="fs-4 font-weight-bold mt-4">Ako si spraviť aplikáciu zo stránky</div>
        <div>
            Stránka sa da používať aj ako mobilná aplikácia (PWA). Návod pre safari nižšie. Určite sa to bude dať spraviť aj na iných zariadeniach.
        </div>
        <img src={require(`../../static/images/safariPWA.png`)} className="img-fluid" />
    </>
)
const SearchNike = () => (
    <>
        <div className="fs-4 font-weight-bold mt-4">Ako vyhladávať zápasy v aplikácii Nike</div>
        <img src={require(`../../static/images/searchNike.png`)} className="img-fluid" />
    </>
)
const BetTracker = () => (
    <>
        <div className="fs-4 font-weight-bold mt-4">Ako používať Bet Tracker</div>
        <div className="mt-0">
            Do BetTrackeru vieme veľmi jednoducho vložiť zápas. Na mobile rozklikneme tri bodky a stláčíme "Add to BetTracker". Pre úspešne pridanie musíme mať správne vyplnené údaje pre obidva vklady a účty na na ktorých podávame tikety. Ak sme ešte nikdy nepridávali arbitrage do BetTrackeru je potrebne do políčka ACCOUNT napísať na ktorom účte práve stávkujeme. Napíšeme tak svoje meno alebo nijakú poznávaciu značku. Dávame to tam aby sme rozlíšili aktuálny zostatok a zisky ak by sme to robili napríklad aj na kamarátov alebo bratov učet. Po prvom vpísaní bude prehliadač automaticky dopĺňať posledný použitý účet. Pri poctivom používaní BetTrackeru nám bude pri AVAILABLE písať aktuálny zostatok na účte.
        </div>
        <div className="mt-3">
            Po vyhodnotení zápasu si zmeníme v BetTrackery status stávky z pending na won alebo lost. V prípade, že bol zápas zrušený alebo vyhodnotení kurzom 1 dáme cancelled. Ak sem ho predčasne predali dáme sold a do sold price napíšeme vyplatené peniaze.
        </div>
        <div className="mt-3">
            Niekedy sa nám môže stať, že arbitráž zmizne a nestihneme ju pridať do BT ale my sme úspešne stavili. Vieme ju tam pridať ručne cez "Add New Bet" a potom možnosť "Arbitrage". Ak si chceme pridať normálnu hazardnú stávku pokračujeme podobne ale vyberieme "Normal". Objaví sa nám okno kde doplníme údaje podla pokynov. Ak si nebudete istí čo vložiť do "Market" pomôžte si stačením "Help Create Market". Tak isto je spravených zopár predvolieb pre políčko "Bet" schované su pod žltým gombíkom "?".
        </div>
        Aby sme vedeli presne určiť aktuálny zostatok na účte je dôležite si zapisovať aj vklady, výbery a bonusy. Vieme to cez "New Transaction" kde si vyberieme typ a doplníme ostatné údaje podla pokynov. Do typu "bonus" si možeme zapisovať aj výhry z free spinov a rôznych iných akcii. Zoznam zapísaných transakcií si vieme pozrieť prvým gombíkom "Show Transactions". Späť k stávkam sa potom dostaneme cez "Show Bets".
        <div className="mt-3">
            Keď ručne zadávate stávky alebo pridávate transakciu. Treba do polička "site" napísať jednu z týchto štyroch možnosti: nike, tipsport, fortuna, doxxbet. Ak to napíšete inak môžu nastať problémy a zle sčítavania.
        </div>
        <div className="mt-3">
            Odporúčam si pridávať všetky stávky do BetTrackera lebo si v ňom potom vieme pozrieť celkový zisk, zisk za posledných 30 dní, zisky po dňoch a aký máš zisk z pohľadu stávkovej spoločnosti. Takisto sa s ním vieme pochváliť iným a ukázať im, že to naozaj funguje.
        </div>
    </>
)
const StartAccount = ({ navigate }) => (
    <>
        <div className="fs-4 font-weight-bold mt-4">Ako začať stávkovať keď mám nový účet?</div>
        <div>
            Keď budeme mať nový účet na ktorom sme ešte nepodali žiadne stávky nikdy nezačiname so stávkami kde máme zaručený zisk. Ak by sme začali, obmedzenie (hlavne na nike) by mohlo prísť veľmi rýchlo. Stávkové spoločnosti sa pozerajú na prvé stávky a tým sa rozhodujú či je to niaky špekulant ktorí im chce zobrať zisky alebo to je daľší "hlúpy" človek ktorý prehrá všetky peniaze. Preto sa musíte zo začiatku správať ako priemerný človek. To znamená, že nedáte prvý vklad 400€ ale začnete z 50€ a postupne budete navyšovať a pravidelne si vkladať viac a viac peňazí. Ja by som zo začiatku podal niekoľko stávok na TOP ligy. Môžete podať aj arbitražne stávky s roi -3% ktoré si viete veľmi rýchlo nájsť ručne medzi dvoma spoločnosťami. Takto budete môcť staviť aj väčšie množstvo peňazi a nepôjdete do rizika. Na vypočitanie vkladov môžete použiť <span onClick={() => navigate('/calculator')} className="" style={{ 'cursor': 'pointer', 'textDecoration': 'underline' }}>kalkulačku</span> na stránke.
        </div>
    </>
)
const Cashout = () => (
    <>
        <div className="fs-4 font-weight-bold mt-4">Predčasné vyplatenie tiketu</div>
        <div>
            Veľa krát sa stáve, že ak by sme predčasne vyplatili obidva tiketu budeme mať väčší zisk ako sme na začiatku predpokladali. Treba si to vždy dôkladne skontrolovať. Môže sa to stať aj keby sme jeden tiket predali zo stratou. V ukážke dole som mal s ROI 7,34% najprv zisk 17,2€ no vďaka predčasnému vyplateniu som mal zisk 41,8€.
        </div>
        <img src={require(`../../static/images/sold.png`)} className="img-fluid" />
    </>
)
const Prienik = () => (
    <>
        <div className="fs-4 font-weight-bold mt-4">Prienik - zisk 793.68€</div>
        <div>
            Niekedy sa môže stať, že sú kurzy veľmi rozdielne a vznikne prienik. V príklade ukázanom nižšie vypísali v nike na počet striel menej ako 68.5 kurz 1.86 a v tipsporte na počet striel viac ako 61.5 kurz 2.54. To znamená, že ak by bolo viac ako 61.5 a menej ako 68.5 striel vyhrali by obidve stávky. Potom by sme roi vyrátali: 100+2*(roi s kurzami 1.86 a 2.54). V príklade to bolo 114.74% čo predstavovalo zisk 793.68€. Ak  by však bolo viac ako 68.5 alebo menej ako 61.5 striel vyhrali by sme s ROI 7.37% čo by bolo okolo 50€.
        </div>
        <img src={require(`../../static/images/prienik.png`)} className="img-fluid" />
        <img src={require(`../../static/images/prienikSheet.png`)} className="img-fluid" />
    </>
)
const WhatIsArbitrage = ({ navigate }) => {
    const [showTiket, setShowTiket] = useState(false)

    return (
        <>
            <div className="fs-4 font-weight-bold mt-4">Čo je to arbitrážne stávkovanie?</div>
            <div>
                Arbitráž je spôsob podávania na kurzy medzi stávkovými kanceláriami tak aby sa pokryli všetky možnosti a tým pádom vždy vyhrala, bez ohľadu na to ako zápas dopadne.
            </div>
            <div>
                Pri podaní arbitráže NEPREHRAS jediná možnosť kedy nevyhráš je keď sa zruší zápas alebo podáš na ponuku kde je možná remíza (tzn. vráti sa nám vklad).
            </div>
            <div>
                Na obrázku nižši su ovedené ponuky dvoch spoločnoti (nike a tipsport) na zápas Nepál vs Laos. Aby sme mali zaručený zisk musíme staviť na všetky možnosti ako môže skončiť zápas. Futbal môže skončiť víťazstvom prvého tímu, remízou alebo víťazstvom druhého tímu. Musíme teda staviť v Nike na <strong>Nepál</strong> s kurzom 2,1 a v Tipsporte na <strong>Neprehra Laos</strong> (remíza / vyhra Laos) s kurzom 2,44.

                Ak by sme si tieto kurzy dali do <span onClick={() => navigate('/calculator')} className="" style={{ 'cursor': 'pointer', 'textDecoration': 'underline' }}>kalkulačky</span> zistíme, že táto arbitraž má roi (return of investment) 12,86%. To znamená, že za každých stavených 100€ máme zisk skoro 13€.
            </div>
            <img src={require(`../../static/images/nepal-laos.png`)} className="img-fluid" />
            <div className="btn btn-primary w-100" onClick={() => setShowTiket(!showTiket)}>Ukázať stavené tikety</div>
            {showTiket &&
                <>
                    <img src={require(`../../static/images/nepal-laos-bet.png`)} className="img-fluid" />
                    <div>Spolu som stavil 635€ a mal som zisk 89,5€.</div>
                </>
            }


        </>
    )
}
const Linked = () => (
    <>
        <div className="fs-4 font-weight-bold mt-4">Ako správne použivať kalkulačku</div>
        <div>
            Keď otvoríme nejakú arbitráž a zadáme aký chceme mať jeden z dvoch vkladov. Druhý sa automaticky vypočíta tak, aby sme mali rovnakú výhru. Je však lepšie keď stavíme zaokrúhlene, buď na celé eurá alebo desiatky eur. Na toto nám pomôže tlačítko <MdLink /> medzi políčkami. Po stlačení sa už nebude meniť druhý vklad a môžeme ho prepísať na novú hodnotu.
        </div>
        <img src={require(`../../static/images/linked.png`)} className="img-fluid" />
    </>
)
const Bug = () => (
    <>
        <div className="fs-4 font-weight-bold mt-4">Na čo si treba dávať pozor!</div>
        <div>
            Niekedy sa môže stať, že budú opačne tími medzi spoločnosťami. Napríklad v Nike bude <strong>Švédsko - USA</strong> a v Tipsporte <strong>USA - Švédsko</strong>. V takomto prípade nestavujte lebo sú všetky arbitráže neplatné. Názov zápasu na tejto stránke sa tvorí s Tim1 prvej spoločnosti a Tim2 druhej spoločnosti. Takže ak bude v názve zápasu 2 krát ten istí tím, je to nesprávne. Takto zle arbitráže odstranite stlačením gombíku "This is not Arbitrage" ktorý sa ukáže po rozkliknutí troch bodiek.
        </div>
        <img src={require(`../../static/images/bug.png`)} className="img-fluid" />
    </>
)
const Predict = () => {
    return (
        <>
            <div className="fs-4 font-weight-bold mt-4">Ako predísť zablokovaniu účtu.</div>
            <div>
                <ul className="list-group">
                    <li className="list-group-item">Po založení nového učtu sa musíte správať ako priemerný človek. To znamená, že navkladám hneď veľa peňazi, dávam stávky na TOP zápasy a nepodávam hneď na rizikové arbitráže stávky.</li>
                    <li className="list-group-item">Vyberám peniaze čo najmenej krát, iba keď to je fakt nutné.</li>
                    <li className="list-group-item">Vklad stávky zaokrúhlujem na cele eurá alebo desiatky eur. Podľa výšky vkladu.</li>
                    <li className="list-group-item">Nedávam dva krát tu istú stávku.</li>
                    <li className="list-group-item">Vyhybajte sa stavkovaniu zapasov na ktore toľko ľudí nestávkuje (nepopularne ligy).</li>
                    <li className="list-group-item">Stavujte na populárne typy stávok napríklad vítaz, počet gólov alebo handicap.</li>
                    <li className="list-group-item">Čím skôr začína zápas tým je bezpečnejšie davať väčšie vklady.</li>
                    <li className="list-group-item">Važe zisky by nemali prekročiť 5% (najlepšie čo najmenej) z celkovej prestávkovanej sumy v spoločnosti. Takže ak by ste chceli mať zisk 1000€ v nike mali by ste prestávkovať aspoň 20000€. Je predsa menej podozrivé mať zarobených 1000€ a prestávkovaných 20000€ ako mať zarobených 1000€ a prestávkovaných iba 5000€. </li>
                    <li className="list-group-item">Tip vyššie je najdôležitejšie. Dávajte si pozor.</li>
                    <li className="list-group-item">Ak je roi príliž veľký, cez 20% tak je to fakt veľká chyba zo strany spoločnosti. Zvážil by som riziko.</li>
                    <li className="list-group-item">Dajte si pauzu.</li>
                    <li className="list-group-item">Majte na vedomi, ze ak budete v zisku raz vas obmedzia.</li>
                </ul>
            </div>
        </>
    )
}
const GettingStartedGuide = () => {
    const [showStorno, setShowStorno] = useState(false)
    const navigate = useNavigate();

    return (
        <Layout>
            {!isAuth() && <Navigate to={'/'} />}
            <div className="container-fluid bg-white font2 d-flex justify-content-center text-center" style={{ minHeight: '100vh' }}>
                <div className="container m-0 p-0 " style={{ maxWidth: '600px' }}>
                    <WhatIsArbitrage navigate={navigate} />
                    <StartAccount navigate={navigate} />
                    <Vklad />
                    <Storno showStorno={showStorno} setShowStorno={setShowStorno} />
                    <Cashout />
                    <Predict />
                    <Linked />
                    <Handicap />
                    <Goal />
                    <DrawNoBet />
                    <BetTracker />
                    <PWA />
                    <SearchNike />
                    <Bug />

                    <Prienik />
                    <div style={{ "height": '50px' }}></div>
                </div>
            </div>

        </Layout>
    )
}

export default GettingStartedGuide
import Layout from "../../components/Layout"
import { getCookie } from "../../helpers/helpers"
import axios from "axios"
import { useEffect, useState } from "react"
import ClipLoader from "react-spinners/ClipLoader";
import cookie from 'js-cookie'
import { useNavigate } from "react-router-dom";


const Profile = () => {
    const token = getCookie('token')
    const [error, setError] = useState()
    const [dataFetched, setDataFetched] = useState(false)
    const [prematch_arbitrage_notification, setPrematch_arbitrage_notification] = useState(false)
    const [prematch_arbitrage_notification_to, setPrematch_arbitrage_notification_to] = useState('')
    const [prematch_arbitrage_notification_min, setPrematch_arbitrage_notification_min] = useState(0)
    const [prematch_arbitrage_notification_sites_ids, setPrematch_arbitrage_notification_sites_ids] = useState([])
    const [prematch_arbitrage_notification_sports_ids, setPrematch_arbitrage_notification_sports_ids] = useState([])
    const [referral_code, setReferral_code] = useState('')
    const [res, setRes] = useState([])
    const [sports, setSports] = useState('')
    const [sites, setSites] = useState('')
    const [invoices, setInvoices] = useState([])
    const [customLink, setCustomLink] = useState('')
    const [message, setMessage] = useState('')
    const navigate = useNavigate()


    useEffect(() => {
        console.log(prematch_arbitrage_notification_sports_ids)
    }, [prematch_arbitrage_notification_sports_ids])

    useEffect(() => {
        axios({
            method: 'POST',
            url: `${process.env.REACT_APP_API}/profile/read`,
            data: { token }
        })
            .then(response => {
                setPrematch_arbitrage_notification(response.data.profile.prematch_arbitrage_notification)
                setRes(response.data.profile)
                setPrematch_arbitrage_notification_to(response.data.profile.prematch_arbitrage_notification_to)
                setPrematch_arbitrage_notification_min(response.data.profile.prematch_arbitrage_notification_min)
                setPrematch_arbitrage_notification_sites_ids(response.data.profile.prematch_arbitrage_notification_sites_ids)
                setPrematch_arbitrage_notification_sports_ids([...response.data.profile.prematch_arbitrage_notification_sports_ids])
                setReferral_code(response.data.referral_code)
                setInvoices(response.data.invoices)
                setSports(response.data.sports)
                setSites(response.data.sites)
                setCustomLink(process.env.REACT_APP_URL + '/signup?i=' + response.data.profile.referral_code + '&email=' + response.data.profile.email)
                setDataFetched(true)
            })
            .catch(error => {
                if (error.response) {
                    setError(error.response.data.error)
                }
                setDataFetched(true)
            })
    }, [])

    const save = () => {
        axios({
            method: 'POST',
            url: `${process.env.REACT_APP_API}/profile/update`,
            data: { token, prematch_arbitrage_notification, prematch_arbitrage_notification_to, prematch_arbitrage_notification_min, prematch_arbitrage_notification_sites_ids, prematch_arbitrage_notification_sports_ids },
        })
            .then(response => {
                setMessage('Updated successfully. Changes take up to 5 minutes to start working. ')
            })
            .catch(error => {
                setError(error.response.data.error)
            })
    }

    if (!dataFetched) {
        return (
            <div className="container d-flex align-items-center justify-content-center" style={{ "height": "calc(100vh - 56px)" }}>
                <ClipLoader color="#000" size={30} margin={5} speedMultiplier={1} />
            </div>
        )
    }


    return (
        <Layout>
            <div className="container-fluid bg-white p-3 h-100" style={{ 'minHeight': '100vh' }}>
                {!res.telegram_id &&
                    <div className="alert alert-info">
                        Vyplňte políčka na stránke a uložte ich! Potom pošlite spravu <span className="text-danger">update</span> na telegram účet <a style={{ 'textDecoration': 'none' }} href="https://t.me/arbitrage_sk_bot">@arbitrage_sk_bot</a> aby sa účet spároval. Ak ste spravili všetko správne hneď vám príde správa <span className="text-danger">zmena uložená</span> od telegram bota. V prípade otázok alebo problému sa neváhaj spýtať.
                    </div>
                }
                <div className="col">
                    {message &&
                        <div className="alert alert-success alert-dismissible mt-3" role="alert">
                            {message}
                            <button className="btn-close" aria-label="close" data-bs-dismiss="alert"></button>
                        </div>
                    }
                </div>
                <div className="row">
                    <div className="col-auto">
                        Posielať notifikácie:
                    </div>
                    <div className="col">
                        <div className="form-check form-switch">
                            <input
                                type="checkbox"
                                id="send_emails"
                                className="form-check-input"
                                checked={prematch_arbitrage_notification}
                                disabled={!res.telegram_id}
                                onChange={e => { setPrematch_arbitrage_notification(!prematch_arbitrage_notification) }}
                            />
                        </div>
                    </div>
                </div>
                <div href="#telegram">
                    Telegram username (to čo je za @, návod je nižšie)
                    <input
                        value={prematch_arbitrage_notification_to}
                        onChange={e => { setPrematch_arbitrage_notification_to(e.target.value) }}
                        name='send to'
                        type="text"
                        className="form-control form-control-secondary"
                        placeholder="Enter account"
                        list='datalist_account1'
                    />
                </div>
                <div className="row">
                    <div className="col">
                        Minimálne ROI (%) na upozornenie:
                    </div>
                </div>
                <div className="row">
                    <div className="col">

                        <input
                            value={prematch_arbitrage_notification_min}
                            onChange={e => { setPrematch_arbitrage_notification_min(e.target.value) }}
                            name='send to'
                            type="text"
                            className="form-control form-control-primary"
                            placeholder="Enter account"
                            list='datalist_account1'
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col">
                        Stávkové spoločnosti:
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        {sites.map(site => {
                            const handleChange = () => {
                                if (prematch_arbitrage_notification_sites_ids.includes(site.id)) {
                                    setPrematch_arbitrage_notification_sites_ids([...prematch_arbitrage_notification_sites_ids.filter(e => e !== site.id)])
                                } else {
                                    setPrematch_arbitrage_notification_sites_ids([...prematch_arbitrage_notification_sites_ids, site.id])
                                }
                            }
                            return (
                                <div className="form-check form-check ms-3" key={site.id}>
                                    <input
                                        type="checkbox"
                                        id="send_emails"
                                        className="form-check-input"
                                        checked={prematch_arbitrage_notification_sites_ids.includes(site.id)}
                                        onChange={handleChange}
                                    />
                                    <label htmlFor="send_emails" className="form-check-label">{site.name}</label>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col">
                        <div className="btn btn-primary" onClick={() => save()}>ULOŽIŤ</div>
                    </div>
                </div>

                <div className="h5 mt-5" id="telegram">Kde nájdem Telegram username?</div>
                <div className="ms-0">Nájdeš ho v Telegram aplikácii v nastaveniach. Je to text čo je za @. Ak ho tam nemáš treba si ho vytvoriť </div>
                <div className="row d-flex ms-3" style={{ 'maxWidth': '400px' }}>
                    <div className="col p-0 m-0">
                        <img src={require(`../../static/images/telegram1.jpg`)} className="img-fluid w-100 p-0 m-0" style={{ 'minWidth': '150px' }} />
                    </div>
                    <div className="col p-0 m-0">
                        <img src={require(`../../static/images/telegram2.jpg`)} className="img-fluid w-100 p-0 m-0" style={{ 'minWidth': '150px' }} />
                    </div>
                </div>
                <div className="h5 mt-3" id="telegram">Čo keď si chcem zmeniť telegram účet?</div>
                <div className="ms-0">Pri každej zmene telegram účtu je potrebné poslat spravu "update" na telegram ucet <a style={{ 'textDecoration': 'none' }} href="https://t.me/arbitrage_sk_bot">@arbitrage_sk_bot</a></div>



                {/*                 
                <div className="row">
                    <div className="btn btn-danger col-auto" onClick={e=>{cookie.remove("token");navigate('/')}}>SIGN OUT</div>
                </div>  */}
            </div>
        </Layout>
    )
}
export default Profile

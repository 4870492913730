import { isAuth, getCookie } from '../../helpers/helpers'
import Layout from '../../components/Layout'
import { Navigate } from "react-router-dom"
import axios from "axios"
import { useEffect, useState } from "react"
import ClipLoader from "react-spinners/ClipLoader";


const Pricing = () => {
    const token = getCookie('token')
    const [dataFetched, setDataFetched] = useState(true)
    const [error, setError] = useState(false)
    const [listPrice, setListPrice] = useState(75)
    // const response = await axios.post(`${process.env.REACT_APP_API}/active/arbitrage`, { token })
    // useEffect(() => {
    //     axios({
    //         method: 'POST',
    //         url: `${process.env.REACT_APP_API}/pricing`,
    //         data: { token }
    //         // headers: {
    //         //     Authorization: `Bearer ${token}`
    //         // }
    //     })
    //         .then(response => {
    //             console.log(response.data)
    //             setDataFetched(true)
    //             setListPrice(response.data[0].list_price)
    //         })
    //         .catch(error => {
    //             if (error.response) {
    //                 setError(error.response.data.error)
    //             }
    //             setDataFetched(true)
    //         })
    // }, [])
    return (
        <Layout>
            {!isAuth() && <Navigate to={'/'} />}
            <>
                <div className='container-fluid' style={{ "minHeight": "calc(100vh - 56px)", 'background': "#fff" }}>
                    <div className='mt-0'>
                        {/* 10 dni zadarmo potom {listPrice}€ / 30 dni */}
                        {listPrice}€ / 30 dni
                    </div>
                    <a href="https://payme.sk/?V=1&IBAN=SK0411000000002934621360&AM=75.00&CC=EUR&DT=&PI=&MSG=&CN=">QR code</a>
                    <div>SK0411000000002934621360</div>
                    {/* <div>SK6809000000005207532437</div> */}
                    <div className='mt-2'>contact.arbitrage.sk@gmail.com</div>
                    <div className='mt-2'>+421 917 782 762</div>
                    {/* <div className='mt-2'>funkčnu stránku najdete vždy na tomto linku: <a href='https://bit.ly/arbitrage_sk'>https://bit.ly/arbitrage_sk</a></div> */}
                </div>
            </>
        </Layout>
    )
}

export default Pricing

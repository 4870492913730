import React, { useEffect, useState } from "react"
import axios from 'axios'
import { useNavigate, Link, useSearchParams } from 'react-router-dom'
import { Form, FormInput, FormError, FormButton, FormText, handleChange, handleBlur, setInputValues } from '../../components/Form'
import '../../style/auth/signin.css'
import { authenticate } from "../../helpers/helpers"

const Signin = () => {
    const [buttonText, setButtonText] = useState('Log In')
    const [error, setError] = useState('')
    const [values, setValues] = useState()
    const navigate = useNavigate()

    useEffect(() => { setInputValues(['email', 'password'], setValues) }, [])

    const handleSubmit = (event) => {
        event.preventDefault()
        setButtonText('loader')
        axios({
            method: 'POST',
            url: `${process.env.REACT_APP_API}/auth/signin`,
            data: Object.keys(values).reduce((obj, key) => { obj[key] = values[key].value; return obj; }, {})
        })
            .then(response => {
                authenticate(response, () => {
                    navigate('/home')
                })
            })
            .catch(error => {
                let copyOfValues = values
                copyOfValues.password.value = ''
                Object.keys(copyOfValues).map(key => copyOfValues[key].isActivated = false);
                setValues(copyOfValues)
                setButtonText('Log In')
                console.log(error)
                if (error) {
                    if (error.response) {
                        if (error.response.data) {
                            setError(error.response.data.error)
                        }
                    }
                }
            })
    }

    return (
        <div id="signin" className="bg-white">
            <Form title={'Log In'}>
                <FormInput
                    onChange={event => handleChange(event, values, setValues)}
                    onBlur={event => handleBlur(event, values, setValues)}
                    values={values}
                    type={'email'}
                    name={'email'}
                    placeholder={'Email'}
                />
                <FormInput
                    onChange={event => handleChange(event, values, setValues)}
                    onBlur={event => handleBlur(event, values, setValues)}
                    values={values}
                    type={'password'}
                    name={'password'}
                    placeholder={'Password'}
                />
                <Link to={"/forgot-password"} id={'signinForgotPassword'}>Forgot password?</Link>
                <FormError error={error} />
                <FormButton text={buttonText} onClick={e => handleSubmit(e)} disabled={false} />
                <FormText html={<>Don't have an account? <strong><Link to={"/signup"}>Sign up</Link></strong></>} />
            </Form>
        </div>
    )
}

export default Signin
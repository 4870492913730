import axios from "axios"
import Layout from "../../components/Layout"
import { BetsModal, formatIsoString, updateBets, defaultBet, TransactionsModal, defaultTransaction, deleteTransaction, DashboardBettype, DashboardCalendar, getMonthInfo, exportToExcel } from "./helpers"
import { useEffect, useState } from "react"
import { getCookie, setCookie } from "../../helpers/helpers"
import { FaEdit } from "react-icons/fa";
import Dropdown from 'react-bootstrap/Dropdown'
import { MdEdit, MdDelete } from 'react-icons/md'
import { FaRegFolder, FaRegEyeSlash, FaRegEye } from "react-icons/fa6"
import { FaPercentage, FaFileInvoiceDollar } from "react-icons/fa"
import { MdOutlineKeyboardDoubleArrowLeft, MdOutlineKeyboardDoubleArrowRight } from "react-icons/md";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, PointElement, LineElement, Filler, BarController, BarElement, DoughnutController } from "chart.js"
import { Line, Bar, Doughnut } from 'react-chartjs-2'
ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, PointElement, LineElement, Filler, BarController, BarElement, DoughnutController)

const Bets = ({ parameters }) => {
    const { bets, fetchNewBets, accounts, transactions } = parameters
    const [betsModal, setBetsModal] = useState([])
    const [error, setError] = useState(null)
    const [showAccount, setShowAccount] = useState((getCookie("showAccount") === "false") ? false : true)
    const [editSold, setEditSold] = useState({ value: null, id: null })

    useEffect(() => {
        setCookie('showAccount', showAccount.toString())
    }, [showAccount])

    let smallDeviceIconHeight = window.innerWidth >= 600 ? '28px' : '22px'
    let smallDeviceIconFont = window.innerWidth >= 600 ? '1rem' : '0.8rem'

    return (
        <>
            <BetsModal parameters={{ accounts, bets: betsModal, setBets: setBetsModal, fetchNewBets }} />
            <div className="row justify-content-end gx-2 gy-2 mb-3 mt-2 font1" style={{ 'fontSize': '0.7rem' }}>
                <div className="col-auto">
                    <div
                        onClick={() => { setShowAccount(!showAccount) }}
                        className="btn btn-secondary"
                    >{!showAccount ? 'Show' : `Don't Show`} Accounts</div>
                </div>
                <div className="col-auto">
                    <div
                        onClick={() => { exportToExcel({ transactions, bets }) }}
                        className="btn btn-secondary"
                    >Export to Excel</div>
                </div>
                <div className="col-auto">
                    <div className="dropdown">
                        <Dropdown>
                            <Dropdown.Toggle variant="primary" id="dropdownNewBet">
                                Add Bet
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item
                                    onClick={() => setBetsModal([{ ...defaultBet, bettype: 'normal' }])}
                                >Normal</Dropdown.Item>
                                <Dropdown.Item
                                    onClick={() => setBetsModal([{ ...defaultBet, bettype: 'arbitrage' }, { ...defaultBet, bettype: 'arbitrage' }])}
                                >Arbitrage</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>

                {/* <div className="col-auto mt-2">
                    <div className="btn btn-secondary disabled">Show Pending Bets</div>
                </div>
                <div className="col-auto mt-2">
                    <div className="btn btn-secondary disabled">Filter</div>
                </div> */}
            </div>
            {window.innerWidth >= 1000
                ?
                <div className="row mx-0">
                    <div className="col-12">
                        <div className="row text-secondary">
                            <div className="text-start col-auto" style={{ 'width': '120px' }}>Start</div>
                            <div className="col-auto text-center" style={{ 'width': '80px' }}>Site</div>
                            <div className="col-2 col-xl-3">Match</div>
                            <div className="text-start col-1">Bet Type</div>
                            <div className="col-2">Market / Bet</div>
                            <div className="col-auto text-center" style={{ 'width': '80px' }}>Odds</div>
                            <div className="col-auto text-center" style={{ 'width': '80px' }}>Stake</div>
                            <div className="col-1 text-center px-0" >Sold Price</div>
                            <div className="col-1 text-start" >Status</div>
                            <div className="col"></div>

                        </div>
                    </div>
                    {bets.map(bet => {
                        const formatedStart = formatIsoString(bet.start)
                        return (
                            <div className="col-12 my-2" key={bet.id}>
                                <div className="row rounded rounded-3 text-light" style={{ 'background': '#222222', 'minHeight': '75px' }}>
                                    <div className="col-1 pe-0 d-flex align-items-center" style={{ 'fontSize': '0.9rem', 'width': '120px' }}>
                                        <span className="">{formatedStart.date} {formatedStart.month}</span>
                                        &nbsp;
                                        <span className="text-secondary">{formatedStart.hour}:{formatedStart.minute}</span>
                                    </div>
                                    <div className="col-1 d-flex align-items-center justify-content-center p-0" style={{ 'fontSize': '0.7rem', 'width': '80px' }} >
                                        <div className="d-flex flex-column text-truncate text-center">
                                            {showAccount &&
                                                <div className="text-center no-select" style={{ 'color': "#222" }}>
                                                    -
                                                </div>
                                            }
                                            {['nike', 'tipsport', 'fortuna', 'doxxbet','synottip','tipos'].includes(bet.site.toLowerCase())
                                                ? <><img src={require(`../../static/images/sites/${bet.site.toLowerCase()}.png`)} style={{ 'maxWidth': '80px' }} className="my-1 w-100 rounded rounded-3"></img></>
                                                : <><div className="bg-secondary text-light px-2 py-0 rounded rounded-5 text-truncate my-1 d-flex align-items-center justify-content-center" style={{ '--bs-bg-opacity': '.05' }}>{bet.site}</div></>
                                            }
                                            {showAccount &&
                                                <div className="text-center text-secondary text-truncate">
                                                    {bet.account}
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className="col-2 col-xl-3  d-flex align-items-center" style={window.innerWidth < 1200 ? { 'fontSize': '0.9rem' } : {}}>
                                        <span className="text-truncate">
                                            {bet.name}
                                        </span>
                                    </div>
                                    <div className="col-1 d-flex align-items-center" style={{ 'fontSize': '0.75rem' }}>
                                        <div
                                            style={{ '--bs-bg-opacity': '.05' }}
                                            className={` 
                                                text-center rounded rounded-5 px-2 py-0
                                                ${bet.bettype === 'arbitrage' ? `border-info text-info bg-info text-capitalize` : ''}
                                                ${bet.bettype === 'valuebet' ? `border-danger text-danger bg-danger text-capitalize` : ''}
                                                ${bet.bettype === 'normal' ? `border-secondary text-secondary bg-secondary text-capitalize` : ''}
                                            `}>{bet.bettype}
                                        </div>
                                    </div>
                                    <div className="col-2 d-flex align-items-center" style={{ 'fontSize': '1rem' }}>
                                        <div className="d-flex flex-column">
                                            <div className="lh-base text-light font2">
                                                {bet.market}
                                            </div>
                                            <div className="lh-1 text-light font2">
                                                {bet.bet}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-auto text-truncate d-flex align-items-center justify-content-center" style={{ 'fontSize': '1rem', 'width': '80px' }}>
                                        {bet.value}
                                    </div>
                                    <div className="col-auto text-truncate d-flex align-items-center justify-content-center" style={{ 'fontSize': '1rem', 'width': '80px' }}>
                                        {bet.stake}€
                                    </div>
                                    <div className="col-1 d-flex align-items-center justify-content-center" style={{ 'fontSize': '1rem' }}>
                                        <div className={`${bet.status !== 'sold' && 'd-none'}`}>
                                            <div className={`
                                                    d-flex justify-content-center align-items-center 
                                                    ${editSold.id === bet.id && 'd-none'}`}>
                                                <MdEdit style={{ 'cursor': 'pointer' }} className="px-0 me-2 text-secondary" onClick={() => setEditSold({ value: bet.sold_price, id: bet.id })} />
                                                <div>
                                                    {bet.sold_price}
                                                </div>
                                            </div>
                                            <div className={`${editSold.id != bet.id && 'd-none'} d-flex justify-content-center align-items-center`}>
                                                <div className="d-flex flex-column align-items-center justify-content-center">
                                                    <input
                                                        defaultValue={bet.sold_price}
                                                        onChange={e => setEditSold({ ...editSold, value: e.target.value })}
                                                        className="form-select px-0 mx-0 text-secondary  bg-secondary text-center p-0"
                                                        style={{ '--bs-bg-opacity': '.05', 'boxShadow': 'none', 'border': 'none', 'backgroundImage': 'none', 'paddingRight': '0.75em' }}
                                                    />
                                                    <div
                                                        className="btn btn-success p-0 w-100 mt-1"
                                                        onClick={() => { updateBets({ setError, fetchNewBets, bets: [{ ...bet, sold_price: editSold.value }] }); setEditSold({ value: null, id: null }) }}
                                                    >Save</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-1  d-flex align-items-center justify-content-center" style={{ 'fontSize': '1rem' }}>
                                        <select
                                            style={{ '--bs-bg-opacity': '.05', 'boxShadow': 'none', 'backgroundImage': 'none', 'paddingRight': '0.75em', 'opacity': '0.75' }}
                                            className={`
                                            form-select text-truncate text-start 
                                            ${bet.status === 'won' ? `text-success border-success bg-success` : ''}
                                            ${bet.status === 'lost' ? `text-danger border-danger bg-danger` : ''}
                                            ${bet.status === 'sold' ? `text-warning border-warning bg-warning` : ''}
                                            ${bet.status === 'cancelled' ? `text-secondary border-secondary bg-secondary` : ''}
                                            ${bet.status === 'pending' ? `text-primary border-primary bg-primary` : ''}
                                        `}
                                            defaultValue={bet.status}
                                            onChange={event => { updateBets({ setError, fetchNewBets, bets: [{ ...bet, status: event.target.value }] }) }}
                                        >
                                            <option className="text-dark bg-white p-0" value="won">won</option>
                                            <option className="text-dark bg-white" value="lost">lost</option>
                                            <option className="text-dark bg-white" value="pending">pending</option>
                                            <option className="text-dark bg-white" value="cancelled">cancelled</option>
                                            <option className="text-dark bg-white" value="sold">sold</option>
                                        </select>

                                    </div>
                                    <div className="col d-flex align-items-center justify-content-end ps-0 pe-2 pe-xl-2 pe-xxl-4 text-secondary" style={{ 'fontSize': '1rem' }}>
                                        <FaEdit style={{ 'cursor': 'pointer' }} onClick={() => setBetsModal([bet])} />
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
                :
                <div className="row mx-0">
                    {bets.map(bet => {
                        const formatedStart = formatIsoString(bet.start)
                        return (
                            <div className="col-12 my-2 p-0" key={bet.id}>
                                <div className="rounded rounded-3 text-light p-2" style={{ 'background': '#222222', 'minHeight': '75px', 'position': 'relative' }}>
                                    <div className="lh-sm">
                                        <div className="row gx-2 gy-2 mb-2 font1">
                                            <div className="col-auto">
                                                {['nike', 'tipsport', 'fortuna', 'doxxbet','synottip','tipos'].includes(bet.site.toLowerCase())
                                                    ? <><img src={require(`../../static/images/sites/${bet.site.toLowerCase()}.png`)} style={{ 'height': smallDeviceIconHeight }} className="rounded rounded-5"></img></>
                                                    : <><div className="text-secondary text-truncate bg-secondary rounded rounded-5 px-2 d-flex justify-content-center align-items-center" style={{ '--bs-bg-opacity': '.1', 'fontSize': smallDeviceIconFont, smallDeviceIconHeight }}>{bet.site}</div></>
                                                }
                                            </div>
                                            <div className="col-auto">
                                                {showAccount &&
                                                    <div
                                                        className="text-secondary text-truncate bg-secondary rounded rounded-5 px-2 d-flex justify-content-center align-items-center"
                                                        style={{ '--bs-bg-opacity': '.1', 'fontSize': smallDeviceIconFont, 'height': smallDeviceIconHeight }}
                                                    >{bet.account}</div>
                                                }
                                            </div>
                                            <div className="col-auto">
                                                <div
                                                    className="text-secondary text-truncate bg-secondary rounded rounded-5 px-2 d-flex justify-content-center align-items-center"
                                                    style={{ '--bs-bg-opacity': '.1', 'fontSize': smallDeviceIconFont, 'height': smallDeviceIconHeight }}
                                                >
                                                    <span className="text-light">{formatedStart.date} {formatedStart.month}</span>
                                                    &nbsp;
                                                    <span className="text-secondary">{formatedStart.hour}:{formatedStart.minute}</span>
                                                </div>
                                            </div>
                                            <div className="col-auto">
                                                <div
                                                    style={{ '--bs-bg-opacity': '.1', 'fontSize': smallDeviceIconFont, 'height': smallDeviceIconHeight }}
                                                    className={` 
                                                        text-secondary text-truncate  rounded rounded-5 px-2 d-flex justify-content-center align-items-center
                                                        ${bet.bettype === 'arbitrage' ? `border-info text-info bg-info text-capitalize` : ''}
                                                        ${bet.bettype === 'valuebet' ? `border-danger text-danger bg-danger text-capitalize` : ''}
                                                        ${bet.bettype === 'normal' ? `border-secondary text-secondary bg-secondary text-capitalize` : ''}
                                                    `}>{bet.bettype}
                                                </div>
                                            </div>
                                            <div className="col-auto">
                                                <select
                                                    style={{ '--bs-bg-opacity': '.1', 'fontSize': smallDeviceIconFont, 'height': smallDeviceIconHeight, 'boxShadow': 'none', 'border': 'none' }}
                                                    className={`
                                                        form-select text-truncate text-start py-0 rounded rounded-5
                                                        ${bet.status === 'won' ? `text-success border-success bg-success` : ''}
                                                        ${bet.status === 'lost' ? `text-danger border-danger bg-danger` : ''}
                                                        ${bet.status === 'sold' ? `text-warning border-warning bg-warning` : ''}
                                                        ${bet.status === 'cancelled' ? `text-secondary border-secondary bg-secondary` : ''}
                                                        ${bet.status === 'pending' ? `text-primary border-primary bg-primary` : ''}
                                                    `}
                                                    defaultValue={bet.status}
                                                    onChange={event => { updateBets({ setError, fetchNewBets, bets: [{ ...bet, status: event.target.value }] }) }}
                                                >
                                                    <option className="text-dark bg-white p-0" value="won">won</option>
                                                    <option className="text-dark bg-white" value="lost">lost</option>
                                                    <option className="text-dark bg-white" value="pending">pending</option>
                                                    <option className="text-dark bg-white" value="cancelled">cancelled</option>
                                                    <option className="text-dark bg-white" value="sold">sold</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="lh-sm">
                                        {bet.name}
                                    </div>
                                    <div className="lh-sm">
                                        <div className='text-secondary text-start '>
                                            {bet.market} - {bet.bet}
                                        </div>
                                    </div>
                                    <div className="lh-sm mt-2">
                                        <span className="text-secondary" style={{ "minWidth": '50px', 'display': 'inline-block' }}>odds: </span>{bet.value}
                                    </div>
                                    <div className="lh-sm">
                                        <span className="text-secondary" style={{ "minWidth": '50px', 'display': 'inline-block' }}>stake: </span>{bet.stake}€
                                    </div>
                                    <div className={`lh-sm ${bet.status !== 'sold' && 'd-none'}`}>
                                        <div className={`d-flex align-items-center ${editSold.id === bet.id && 'd-none'}`}>
                                            <span className="text-secondary" style={{ "minWidth": '50px', 'display': 'inline-block' }}>sold:</span>
                                            <span>{bet.sold_price}€</span>
                                            <div
                                                style={{ '--bs-bg-opacity': '.05', 'fontSize': '0.7rem', 'cursor': 'pointer' }}
                                                className="bg-light text-secondary rounded rounded-5 px-2 font1 text-uppercase ms-2"
                                                onClick={() => setEditSold({ value: bet.sold_price, id: bet.id })}
                                            >edit</div>
                                        </div>
                                        <div className={`d-flex align-items-center ${editSold.id !== bet.id && 'd-none'}`}>
                                            <div className="text-secondary" style={{ "minWidth": '50px' }}>sold:</div>
                                            <div style={{ "width": '100px' }}>
                                                <input
                                                    defaultValue={bet.sold_price}
                                                    onChange={e => setEditSold({ ...editSold, value: e.target.value })}
                                                    className="bg-secondary text-light no-select rounded roudned-5 w-100"
                                                    style={{ '--bs-bg-opacity': '.05', 'boxShadow': 'none', 'border': 'none', 'outline': 'none', 'paddingRight': '0.75em' }}
                                                />
                                            </div>

                                            <div
                                                style={{ '--bs-bg-opacity': '.05', 'fontSize': '0.7rem', 'cursor': 'pointer' }}
                                                className="bg-success text-success rounded rounded-5 px-2 font1 text-uppercase ms-2"
                                                onClick={() => { updateBets({ setError, fetchNewBets, bets: [{ ...bet, sold_price: editSold.value }] }); setEditSold({ value: null, id: null }) }}
                                            >save</div>
                                        </div>
                                    </div>
                                    <div className="text-secondary" style={{ 'position': 'absolute', 'bottom': '10px', 'right': '10px' }}>
                                        <FaEdit style={{ 'cursor': 'pointer' }} onClick={() => setBetsModal([bet])} />
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            }
        </>
    )
}

const Transactions = ({ parameters }) => {
    const { transactions, fetchNewTransactions, accounts, bets } = parameters
    const [transactionModal, setTransactionModal] = useState()
    const [error, setError] = useState(null)
    const [showAccount, setShowAccount] = useState((getCookie("showAccount") === "false") ? false : true)

    useEffect(() => {
        setCookie('showAccount', showAccount.toString())
    }, [showAccount])

    let smallDeviceWidth = window.innerWidth >= 430 ? '80px' : '60px'


    return (
        <>
            <TransactionsModal parameters={{ accounts, transaction: transactionModal, setTransaction: setTransactionModal, fetchNewTransactions }} />
            <div className="row justify-content-end gx-2 gy-2 mb-3 mt-2 font1" style={{ 'fontSize': '0.7rem' }}>
                <div className="col-auto">
                    <div
                        onClick={() => { setShowAccount(!showAccount) }}
                        className="btn btn-secondary"
                    >{!showAccount ? 'Show' : `Don't Show`} Accounts</div>
                </div>
                <div className="col-auto">
                    <div
                        onClick={() => { exportToExcel({ transactions, bets }) }}
                        className="btn btn-secondary"
                    >Export to Excel</div>
                </div>
                <div className="col-auto">
                    <div
                        onClick={() => setTransactionModal({ ...defaultTransaction })}
                        className="btn btn-primary"
                    >Add New Transaction</div>
                </div>
            </div>
            <div className="row mx-3">
                {transactions.map(transaction => {
                    const formatedStart = formatIsoString(transaction.date)
                    return (
                        <div className="col-12 my-2 p-0" key={transaction.id}>
                            <div className="row rounded rounded-3 text-light" style={{ 'background': '#222222', 'minHeight': '75px' }}>
                                <div className="col-auto d-flex align-items-center justify-content-center" style={{ 'fontSize': window.innerWidth >= 430 ? '0.75rem' : '0.6rem', 'width': smallDeviceWidth }}>
                                    <div
                                        style={{ '--bs-bg-opacity': '.05' }}
                                        className={` 
                                                text-center rounded rounded-5 px-2 py-0
                                                ${transaction.transaction_type === 'withdraw' ? `border-success text-success bg-success text-capitalize` : ''}
                                                ${transaction.transaction_type === 'deposit' ? `border-info text-info bg-info text-capitalize` : ''}
                                                ${transaction.transaction_type === 'bonus' ? `border-danger text-danger bg-danger text-capitalize` : ''}
                                            `}>{transaction.transaction_type}
                                    </div>
                                </div>
                                <div className="col-auto d-flex align-items-center justify-content-center p-0" style={{ 'fontSize': '0.7rem', 'width': smallDeviceWidth }} >
                                    <div className="d-flex flex-column text-truncate text-center">
                                        {showAccount &&
                                            <div className="text-center no-select" style={{ 'color': "#222", 'fontSize': window.innerWidth >= 430 ? '0.7rem' : '0.5rem' }}>
                                                -
                                            </div>
                                        }
                                        {['nike', 'tipsport', 'fortuna', 'doxxbet','synottip','tipos'].includes(transaction.site.toLowerCase())
                                            ? <><img src={require(`../../static/images/sites/${transaction.site.toLowerCase()}.png`)} style={{ 'maxWidth': smallDeviceWidth }} className="my-1 w-100 rounded rounded-3"></img></>
                                            : <><div className="bg-secondary text-light px-2 py-0 rounded rounded-5 text-truncate my-1 d-flex align-items-center justify-content-center" style={{ '--bs-bg-opacity': '.05' }}>{transaction.site}</div></>
                                        }
                                        {showAccount &&
                                            <div className="text-center text-secondary text-truncate" style={{ 'fontSize': window.innerWidth >= 430 ? '0.7rem' : '0.5rem' }}>
                                                {transaction.account}
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="col-auto pe-0 d-flex align-items-center justify-content-center" style={{ 'fontSize': window.innerWidth >= 430 ? '0.9rem' : '0.7rem', 'width': window.innerWidth >= 430 ? '120px' : '80px' }}>
                                    <span className="">{formatedStart.date} {formatedStart.month}</span>
                                </div>
                                <div className="col text-truncate d-flex align-items-center justify-content-center" style={{ 'fontSize': window.innerWidth >= 430 ? '1rem' : '0.8rem' }}>
                                    {transaction.value}€
                                </div>
                                <div className="col-auto text-truncate d-flex align-items-center justify-content-center text-danger" style={{ 'fontSize': '1rem', 'width': '45px' }}>
                                    <div style={{ "height": '100%', width: '100%' }} role='button'>
                                        <MdDelete size={'100%'} onClick={() => deleteTransaction({ id: transaction.id, fetchNewTransactions })} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        </>
    )
}

const Dashboard = ({ parameters }) => {
    const { dashboard } = parameters
    const [weekUntil, setWeekUntil] = useState(new Date((new Date().setHours(0, 0, 0, 0))).toISOString())
    const { accounts, profits, profits_by_days, profits_by_bettype, profits_by_months, invoices, pending, total, last_bets } = dashboard
    const [showTotal, setShowTotal] = useState((getCookie("showTotal") === "false") ? false : true)
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

    // 2023-10-11T22:00:00.000Z

    useEffect(() => {
        console.log(weekUntil)
    }, [weekUntil])

    useEffect(() => {
        if (showTotal === false) {
            setCookie('showTotal', false)
        } else {
            setCookie('showTotal', true)
        }
    }, [showTotal])

    const IconsSize = '65px'

    if (!accounts) { return '' }


    const getDatesBefore = (isoString) => Array.from({ length: 7 }, (_, i) => (d =>
        `${d.toLocaleDateString('en-US', { weekday: 'short' })} ${d.getDate()}.${months[d.getMonth()]}
        `)(new Date(new Date(isoString).setDate(new Date(isoString).getDate() - i)))).reverse();
    const getProfitsByDays = (isoString, profits) => [...Array(7).keys()].map(day => profits.filter(entry => new Date(new Date(entry.start_day).setHours(12, 0, 0, 0)).toISOString() === new Date(new Date(new Date(isoString).setDate(new Date(isoString).getDate() - day)).setHours(12, 0, 0, 0)).toISOString()).reduce((total, entry) => total + entry.profit, 0))
    const proftisByDays = getProfitsByDays(weekUntil, profits_by_days)


    const weekData = {
        labels: getDatesBefore(weekUntil),
        datasets: [{
            label: 'Daily Profit',
            data: proftisByDays.reverse(),
            fill: false,
            borderColor: '#3498db',
            tension: 0.3
        }],
    }
    const weekOptions = {
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                intersect: false,
            }
        },
        scales: {

            x: {
                grid: {
                    display: false,
                },
            },
            y: {
                grid: {
                    display: false,
                },
                ticks: {
                    beginAtZero: true,
                    callback: function (value, index, values) {
                        return value + '€'

                        console.log(values.map(e => e.value))

                        const i = values.reverse().findIndex(obj => obj.value === value)
                        console.log(i)
                        if (i % 2 === 0) {
                            return value + '€'
                        }
                    },
                }
            },
        },
        elements: {
            line: {
                borderWidth: 3, // Adjust line thickness
                borderColor: '#0d6efd', // Change line color
                borderDash: [3, 10], // Set a dashed line pattern
                backgroundColor: 'rgba(52,152,219,0.1)', // Change fill color
                borderCapStyle: 'round', // Set line cap style
                borderJoinStyle: 'round', // Set line join style
            },
        },

    }
    const monthData = {
        labels: profits_by_months.map(e => `${e.month_name.slice(0, 3)} ${e.year_number - 2000}`).reverse(),
        datasets: [{
            label: 'Months',
            data: profits_by_months.map(e => e.profit).reverse(),
            borderColor: '#6C757D',
            backgroundColor: '#6C757D',
            barPercentage: 0.8,
            hoverBackgroundColor: '#3498db',
        }],
    }
    const monthOptions = {
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                callbacks: {
                    label: function (e) {
                        return [
                            `profit: ${profits_by_months.map(e => e.profit).reverse()[e.dataIndex].toFixed(2)}€`,
                            `total: ${profits_by_months.map(e => e.total).reverse()[e.dataIndex].toFixed(0)}€`,
                            `roi: ${profits_by_months.map(e => e.roi).reverse()[e.dataIndex].toFixed(2)}%`,
                            `count: ${profits_by_months.map(e => e.count).reverse()[e.dataIndex]}`,
                        ]
                    }
                }
            },
        },
        scales: {
            x: {
                grid: {
                    display: false,
                },
            },
            y: {
                grid: {
                    display: false,
                },
                ticks: {
                    beginAtZero: true,
                    callback: function (value, index, values) {
                        return ''
                    },
                }
            },
        },
        elements: {
            line: {
                borderWidth: 1,
                borderColor: '#6C757D',
                backgroundColor: '#6C757D',
            },
        },
    }
    const bettypeData = {
        labels: profits_by_bettype.filter(e => ['bonus', 'arbitrage', 'valuebet', 'normal'].includes(e.bettype)).map(e => e.bettype),
        datasets: [{
            data: profits_by_bettype.filter(e => ['bonus', 'arbitrage', 'valuebet', 'normal'].includes(e.bettype)).map(e => e.profit),
            backgroundColor: profits_by_bettype.filter(e => ['bonus', 'arbitrage', 'valuebet', 'normal'].includes(e.bettype)).map(e => e.color),
            borderColor: 'transparent',
            hoverOffset: 5,
        }]
    }
    const bettypeOptions = {
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                callbacks: {
                    label: function (e) {
                        return [
                            `profit: ${profits_by_bettype.filter(e => ['bonus', 'arbitrage', 'valuebet', 'normal'].includes(e.bettype)).map(e => e.profit)[e.dataIndex].toFixed(2)}€`,
                            `total: ${profits_by_bettype.filter(e => ['bonus', 'arbitrage', 'valuebet', 'normal'].includes(e.bettype)).map(e => e.total)[e.dataIndex].toFixed(0)}€`,
                            `count: ${profits_by_bettype.filter(e => ['bonus', 'arbitrage', 'valuebet', 'normal'].includes(e.bettype)).map(e => e.count)[e.dataIndex]}`,
                        ]
                    }
                }
            },
        },
    }


    return (
        <div className="mt-2 text-light font2" style={{ 'background': '#161616' }}>
            <div className="row gx-3 gy-3">
                {/* stats */}
                <div className="col-12 col-sm-6 col-xl-3" style={{ 'height': '350px' }}>
                    <div
                        className={`rounded rounded-4 d-flex justify-content-center align-items-center h-100 `}
                        style={{ 'fontSize': '3rem', 'background': '#222222' }}>
                        <div className="row w-100 gy-3 px-2">
                            <div className="col-12">
                                <div className="row text-secondary">
                                    <div className="col-auto">
                                        <div className={`bg-secondary rounded rounded-3 d-flex justify-content-center align-items-center`} style={{ 'width': IconsSize, 'height': IconsSize, '--bs-bg-opacity': '.4' }}>
                                            <FaRegFolder size={'40%'} className="" />
                                        </div>
                                    </div>
                                    <div className="col d-flex align-items-start justify-content-center flex-column">
                                        <div className=" fs-2 lh-1 font2">{total?.count || 0}</div>
                                        <div className="" style={{ 'fontSize': '1rem' }}>Total Bets</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="row text-success">
                                    <div className="col-auto">
                                        <div className={`bg-success rounded rounded-3 d-flex justify-content-center align-items-center`} style={{ 'width': IconsSize, 'height': IconsSize, '--bs-bg-opacity': '.4' }}>
                                            <FaPercentage size={'40%'} className="" />
                                        </div>
                                    </div>
                                    <div className="col d-flex align-items-start justify-content-center flex-column">
                                        <div className=" fs-2 lh-1 font2">{total?.roi.toFixed(1) || 0}%</div>
                                        <div className="" style={{ 'fontSize': '1rem' }}>Total ROI</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="row text-primary">
                                    <div className="col-auto">
                                        <div className={`bg-primary rounded rounded-3 d-flex justify-content-center align-items-center`} style={{ 'width': IconsSize, 'height': IconsSize, '--bs-bg-opacity': '.4' }}>
                                            <div className="fs-2 text-primary " >{pending.count}</div>
                                        </div>
                                    </div>
                                    <div className="col d-flex align-items-start justify-content-center flex-column">
                                        <div className=" fs-2 lh-1 font2">{pending.total > 0 ? parseFloat((pending.total).toFixed(2)) : 0}€</div>
                                        <div className="" style={{ 'fontSize': '1rem' }}>Total Pending Stake</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="row text-danger">
                                    <div className="col-auto">
                                        <div className={`bg-danger rounded rounded-3 d-flex justify-content-center align-items-center`} style={{ 'width': IconsSize, 'height': IconsSize, '--bs-bg-opacity': '.4' }}>
                                            <FaFileInvoiceDollar size={'40%'} className="" />
                                        </div>
                                    </div>
                                    <div className="col d-flex align-items-start justify-content-center flex-column">
                                        <div className=" fs-2 lh-1 font2">{invoices.map(e => e.amount_total).reduce((t, c) => t + c, 0)}€</div>
                                        <div className="" style={{ 'fontSize': '1rem' }}>Total Invocied</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* total */}
                <div className="col-12 col-sm-6 col-xl-3" style={{ 'height': '350px' }}>
                    <div onClick={() => setShowTotal(!showTotal)} className="no-select rounded rounded-4 h-100 d-flex flex-column align-items-center justify-content-center" style={{ 'background': '#222222', 'position': 'relative' }}>
                        <div className={`lh-sm row ${profits.total === 0 && 'text-secondary'} ${profits.total > 0 && 'text-success'} ${profits.total < 0 && 'text-danger'} `} style={{ 'fontSize': '3rem' }}>
                            <span className={`px-0`} style={showTotal ? { 'borderBottom': ` ${profits.total > 0 ? '3px' : '0px'} solid #198754` } : { 'borderBottom': ' 3px solid #222222' }}>
                                {showTotal
                                    ? <>{parseFloat(profits?.total?.toFixed(2)) || 0}€</>
                                    : <span style={{ 'letterSpacing': '6px', 'fontSize': '4rem' }}>{"-".repeat(Math.abs(profits?.total?.toFixed(0)).toString().length)}</span>
                                }
                            </span>
                        </div>
                        <div style={{ "position": 'absolute', 'bottom': '5px', 'right': '10px', 'color': '#3a3a3a' }}>
                            {showTotal
                                ? <FaRegEye size={'20px'} />
                                : <FaRegEyeSlash size={'20px'} />
                            }
                        </div>
                    </div>

                </div>
                {/* per week */}
                <div className="col-xl-6 col-12" style={{ 'height': '350px' }}>
                    <div className="rounded rounded-4 h-100 " style={{ 'background': '#222222', 'position': 'relative' }}>
                        <div className="row">
                            <div className="col-12" style={{ 'height': '70px' }}>
                                <div className="font1 lh-1 d-flex justify-content-end fs-1 pe-5 pt-3 text-secondary">Total Profit</div>

                                <div className={`fs-2 d-flex lh-1 pe-5 justify-content-end ${proftisByDays.reduce((t, c) => t + c, 0) === 0 && 'text-secondary'} ${proftisByDays.reduce((t, c) => t + c, 0) > 0 && 'text-success'} ${proftisByDays.reduce((t, c) => t + c, 0) < 0 && 'text-danger'}`}>
                                    {parseFloat(proftisByDays.reduce((t, c) => t + c, 0).toFixed(2))}€
                                </div>

                            </div>
                            <div className="col-12 px-5" style={{ 'height': '230px' }}>
                                <div style={{ "position": 'relative', 'height': '100%', "width": '100%' }}>
                                    <Line data={weekData} options={weekOptions} />
                                </div>
                            </div>
                            <div className="col-12" style={{ 'height': '50px' }}>
                                <div className={`d-flex  align-items-center  h-100 no-select ${window.innerWidth > 480 ? 'pe-5 justify-content-end' : 'justify-content-center'}`}>
                                    <div
                                        className="col-auto font1 rounded rounded-3 d-flex justify-content-center align-items-center text-secondary"
                                        style={{ 'backgroundColor': '#343434', 'height': '32px', 'width': '32px', 'cursor': 'pointer' }}
                                        onClick={() => setWeekUntil(new Date(new Date(weekUntil).getTime() - (7 * 24 * 60 * 60 * 1000)).toISOString())}
                                    ><MdOutlineKeyboardDoubleArrowLeft size="70%" />
                                    </div>
                                    <div className="col-auto mx-2 font1 rounded rounded-3 px-4 py-1 text-center" style={{ 'backgroundColor': '#343434', 'width': window.innerWidth > 480 ? '290px' : '200px', 'fontSize': window.innerWidth > 480 ? '1rem' : '0.8rem' }}>
                                        {new Date(new Date(weekUntil).getTime() - (6 * 24 * 60 * 60 * 1000)).toLocaleDateString('en-US', { day: 'numeric', month: window.innerWidth > 480 ? 'long' : 'short', year: 'numeric' })}
                                        <span className="mx-2">-</span>
                                        {(new Date(weekUntil).toLocaleDateString('en-US', { day: 'numeric', month: window.innerWidth > 480 ? 'long' : 'short', year: 'numeric' }))}
                                    </div>
                                    <div
                                        className="col-auto font1 rounded rounded-3 d-flex justify-content-center align-items-center text-secondary"
                                        style={{ 'backgroundColor': '#343434', 'height': '32px', 'width': '32px', 'cursor': 'pointer' }}
                                        onClick={() => setWeekUntil(new Date(new Date(weekUntil).getTime() + (7 * 24 * 60 * 60 * 1000)).toISOString())}
                                    ><MdOutlineKeyboardDoubleArrowRight size="70%" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* per month */}
                <div className="col-xl-5 col-12" style={{ 'height': '450px' }}>
                    <div className="rounded rounded-4 h-100 " style={{ 'background': '#222222' }}>
                        <div className={`p-4 ${profits_by_months.length === 0 && 'd-none'}`} style={{ 'height': '100%', "width": '100%' }}>
                            <div style={{ "position": 'relative', 'height': '100%', "width": '100%' }}>
                                <Bar data={monthData} options={monthOptions} />
                            </div>
                        </div>
                        <div className={`p-4 d-flex justify-content-center align-items-center ${profits_by_months.length > 0 && 'd-none'}`} style={{ 'height': '100%', "width": '100%' }}>
                            <span className="text-center">
                                No Data
                            </span>
                        </div>
                    </div>
                </div>
                {/* calendar */}
                <div className="col-12 col-md-6 col-xl-4" style={{ 'height': '450px' }}>
                    <div className="rounded rounded-4 h-100 " style={{ 'background': '#222222', 'position': 'relative' }}>
                        <DashboardCalendar parameters={{ profits_by_days }} />
                    </div>
                </div>
                {/* bettype */}
                <div className="col-12 col-md-6 col-xl-3" style={{ 'height': '450px' }}>
                    <div className="rounded rounded-4 h-100 " style={{ 'background': '#222222' }}>
                        <div className={`p-4 ${profits_by_months.length === 0 && 'd-none'}`} style={{ 'height': '100%', "width": '100%' }}>
                            <div className="d-flex justify-content-center align-items-center" style={{ "position": 'relative', 'height': '100%', "width": '100%', 'opacity': '0.5' }}>
                                <Doughnut data={bettypeData} options={bettypeOptions} />
                            </div>
                        </div>
                        <div className={`p-4 d-flex justify-content-center align-items-center ${profits_by_months.length > 0 && 'd-none'}`} style={{ 'height': '100%', "width": '100%' }}>
                            <span className="text-center">
                                No Data
                            </span>
                        </div>
                    </div>
                </div>
                {/* latest bets */}
                <div className="col-12 col-md-12 col-xl-6" style={{ 'height': '450px' }}>
                    <div className="rounded rounded-4 h-100 " style={{ 'background': '#222222' }}>
                        <div className="d-flex flex-column">
                            <div className="d-flex justify-content-between">
                                <div>
                                    {/* {[...new Set(last_bets.map(e => e.bettype))]} */}
                                </div>
                                <div className="font1 lh-1 fs-1 pe-3 pt-3 text-secondary no-select">Latest Profits</div>
                            </div>
                            <div className="row mx-0 mt-2 px-2" style={{ 'height': '30px' }}>
                                <div className="d-flex jusfify-content-center align-items-center text-secondary" >
                                    <div className="col-'width':'120px'  pe-0 d-flex align-items-center" style={{ 'fontSize': '0.9rem', 'width': '80px' }}>
                                        Date
                                    </div>
                                    <div className="col-auto" style={{'width':'calc(100% - 100%/3 - 80px)'}}>
                                        Name
                                    </div>
                                    <div className="col-2 text-center">
                                        ROI
                                    </div>
                                    <div className="col-2 text-end">
                                        Profit
                                    </div>
                                </div>
                            </div>
                            <div className="" style={{ 'height': '350px','overflowX':'hidden','overflowY':'auto' }}>
                                {last_bets.map(e => {
                                    const formatedStart = formatIsoString(e.start)
                                    return (
                                        <div className="row mx-0 mt-2 px-2" style={{ 'height': '50px' }}>
                                            <div className="font1 d-flex jusfify-content-center align-items-center" >
                                                <div className="col-auto pe-0 d-flex align-items-center" style={{ 'fontSize': '0.9rem', 'width': '80px' }}>
                                                    <span className="text-secondary">{formatedStart.date} {formatedStart.month}</span>
                                                    &nbsp;
                                                    <span className="text-secondary">{formatedStart.hour}:{formatedStart.minute}</span>
                                                </div>
                                                <div className="col-auto" style={{'width':'calc(100% - 100%/3 - 80px)'}}>
                                                    <div className="d-flex flex-column text-secondary">
                                                        <div className="lh-1 text-truncate" style={{ 'fontSize': window.innerWidth >= 500 ?'1.1rem':(window.innerWidth >= 400?'0.8rem':'0.7rem') }}>{e.name}</div>
                                                        <div className="lh-1 text-truncate font1" style={{ 'fontSize': window.innerWidth >= 500 ?'1.1rem':(window.innerWidth >= 400?'0.8rem':'0.7rem') }}>{e.market}</div>
                                                    </div>
                                                </div>
                                                <div
                                                    className={`col-2 text-center text-secondary`}>
                                                    {e.bettype === 'arbitrage'
                                                        ? <>{parseFloat((e.profit / e.total * 100).toFixed(2))}%</>
                                                        : <></>
                                                    }
                                                </div>
                                                <div
                                                    className={`col-2 text-end fs-5 font2
                                                        ${e.profit > 0 && 'text-success'}
                                                        ${e.profit == 0 && 'text-secondary'}
                                                        ${e.profit < 0 && 'text-danger'}
                                                    `}>
                                                    {parseFloat((e.profit).toFixed(2))}€
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
                {/* accounts */}
                <div className="col-12 col-md-12 col-xl-6" style={{ 'height': '450px' }}>
                    <div className="rounded rounded-4 h-100 " style={{ 'background': '#222222' }}>
                        <div className="d-flex flex-column">
                            <div className="d-flex justify-content-between">
                                <div>
                                    {/* {[...new Set(last_bets.map(e => e.bettype))]} */}
                                </div>
                                <div className="font1 lh-1  fs-1 pe-3 pt-3 text-secondary no-select">Accounts</div>
                            </div>
                            <div className="row mx-0 mt-2 px-2" style={{ 'height': '30px', 'fontSize': window.innerWidth >= 550 ? '1rem' : '0.9rem' }}>
                                <div className="d-flex jusfify-content-center align-items-center text-secondary">
                                    <div className="col-auto text-center" style={{ 'width': window.innerWidth >= 550 ? '100px' : '80px' }}>
                                        Site
                                    </div>
                                    <div className="col-auto text-center" style={{ 'width': window.innerWidth >= 550 ? '100px' : '80px' }}>
                                        Account
                                    </div>
                                    <div className="col-auto col-md-3 col-lg-5 col-xl-auto"></div>
                                    <div className="col text-end">
                                        Balance
                                    </div>
                                    <div className="col text-end">
                                        ROI
                                    </div>
                                    <div className="col text-end ps-2">
                                        Profit
                                    </div>
                                </div>
                            </div>
                            <div className="overflow-auto" style={{ 'height': '350px' }}>
                                {accounts.map(e => {
                                    const formatedStart = formatIsoString(e.start)
                                    return (
                                        <div className="row mx-0 mt-2 px-2" style={{ 'height': '50px' }}>
                                            <div className="font1 d-flex jusfify-content-center align-items-center" >
                                                <div className="col-auto d-flex justify-content-center" style={{ 'width': window.innerWidth >= 550 ? '100px' : '80px' }}>
                                                    {['nike', 'tipsport', 'fortuna', 'doxxbet','synottip','tipos'].includes(e.site.toLowerCase())
                                                        ? <><img src={require(`../../static/images/sites/${e.site.toLowerCase()}.png`)} style={{ 'width': '80%' }} className="rounded rounded-5"></img></>
                                                        : <><div className="text-center text-secondary text-truncate bg-secondary rounded rounded-5 px-2 d-flex justify-content-center align-items-center" style={{ '--bs-bg-opacity': '.1', 'fontSize': '0.8rem', 'width': '80px' }}>{e.site}</div></>
                                                    }
                                                </div>
                                                <div className="col-auto d-flex justify-content-center" style={{ 'width': window.innerWidth >= 550 ? '100px' : '80px' }}>
                                                    <div
                                                        className="text-secondary text-truncate bg-secondary rounded rounded-5 px-2 d-flex justify-content-center align-items-center"
                                                        style={{ '--bs-bg-opacity': '.1', 'fontSize': window.innerWidth >= 550 ? '0.8rem' : '0.6rem', 'width': '80%' }}
                                                    >{e.account}</div>
                                                </div>
                                                <div className="col-auto col-md-3 col-lg-5 col-xl-auto"></div>
                                                <div className="col text-secondary text-end" style={{ 'fontSize': window.innerWidth >= 550 ? '1rem' : '0.7rem' }}>
                                                    {e.balance}€
                                                </div>
                                                <div className="col text-secondary text-end" style={{ 'fontSize': window.innerWidth >= 550 ? '1rem' : '0.7rem' }}>
                                                    {e.roi}%
                                                </div>
                                                <div
                                                    style={{ 'fontSize': window.innerWidth >= 550 ? '1.3rem' : '0.9rem' }}
                                                    className={`col text-end font2 ps-2
                                                        ${e.profit > 0 && 'text-success'}
                                                        ${e.profit == 0 && 'text-secondary'}
                                                        ${e.profit < 0 && 'text-danger'}
                                                    `}>
                                                    {e.profit}€
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="my-2"></div>
            </div>
        </div >
    )
}

const BetTracker = () => {
    const token = getCookie('token')
    const newDataIn = 30000
    const [error, setError] = useState('')
    const [dataFetched, setDataFetched] = useState(false)
    const [bets, setBets] = useState([])
    const [transactions, setTransactions] = useState([])
    const [dashboard, setDashboard] = useState([])
    const [show, setShow] = useState(getCookie('show') || 'dashboard')
    const [accounts, setAccounts] = useState([])

    useEffect(() => {
        setCookie('show', show)
    }, [show])

    const fetchNewBets = async () => {
        axios.post(`${process.env.REACT_APP_API}/bettracker/read`, { token, options: {} })
            .then(response => {
                setDataFetched(true)
                setBets(response.data.bets)
                setAccounts(response.data.accounts)
            })
            .catch(error => {
                // setError(error.response.data.error)
            })
    }

    const fetchNewTransactions = async () => {
        axios.post(`${process.env.REACT_APP_API}/transactiontracker/read`, { token })
            .then(response => {
                setDataFetched(true)
                setTransactions(response.data.transactions)
                setAccounts(response.data.accounts)
            })
            .catch(error => {
                // setError(error.response.data.error)
            })
    }

    const fetchNewDashboard = async () => {
        axios.post(`${process.env.REACT_APP_API}/dashboard/read`, { token })
            .then(response => {
                setDataFetched(true)
                setDashboard(response.data.dashboard)
                setAccounts(response.data.accounts)
            })
            .catch(error => {
                // setError(error.response.data.error)
            })
    }

    useEffect(() => {
        setError('')
        if (show === 'bets') {
            fetchNewBets()
            fetchNewTransactions()
        }
        if (show === 'transactions') {
            fetchNewTransactions()
            fetchNewBets()
        }
        const interval = setInterval(fetchNewBets, newDataIn)
        return () => clearInterval(interval)
    }, [token])

    useEffect(() => {
        setDataFetched(false)
        if (show === 'bets') {
            fetchNewBets()
            fetchNewTransactions()
        }
        if (show === 'transactions') {
            fetchNewTransactions()
            fetchNewBets()
        }
        if (show === 'dashboard') {
            fetchNewDashboard()
        }
    }, [show])


    return (
        <Layout>
            <div className="container-fluid">
                <div className='row gap-2 mx-0'>
                    {[
                        { name: 'Dashboard', type: 'dashboard' },
                        { name: 'Bet Tracker', type: 'bets' },
                        { name: 'Deposits / Withdrawals Tracker', type: 'transactions' }
                    ].map(e => {
                        return (
                            <div
                                key={e.type}
                                className={`no-select col-auto rounded rounded-2 py-2 px-3 ${show === e.type ? 'text-light' : 'text-secondary'}`}
                                style={{ "backgroundColor": '#222222', 'cursor': 'pointer' }}
                                onClick={() => setShow(e.type)}
                            >
                                {e.name}
                            </div>
                        )
                    })}
                </div>
                <div className={`px-0 ${show === 'bets' ? '' : 'd-none'}`}>
                    <Bets parameters={{ bets, fetchNewBets, accounts, transactions }} />
                </div>
                <div className={`px-0 ${show === 'transactions' ? '' : 'd-none'}`}>
                    <Transactions parameters={{ transactions, fetchNewTransactions, accounts, bets }} />
                </div>
                <div className={`px-0 ${show === 'dashboard' ? '' : 'd-none'}`}>
                    <Dashboard parameters={{ dashboard, fetchNewDashboard, accounts }} />
                </div>
            </div>
        </Layout >
    )
}


export default BetTracker
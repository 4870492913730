import React, { useEffect, useState } from "react"
import axios from 'axios'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Form, FormInput, FormError, FormButton, handleChange, handleBlur, setInputValues } from '../../components/Form'
import '../../style/auth/verify.css'
import { authenticate, setCookie } from  "../../helpers/helpers"


const Verify = () => {
    const [buttonText, setButtonText] = useState('Finish')
    const [error, setError] = useState('')
    const [values, setValues] = useState()
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate()
    const token = searchParams.get("token")

    useEffect(() => { setInputValues(['firstname', 'lastname', 'password', 'phone', 'birthyear'], setValues) }, [])

    const handleSubmit = (event) => {
        event.preventDefault()
        setButtonText('loader')
        const { firstname, lastname, password, phone, birthyear } = Object.keys(values).reduce((obj, key) => { obj[key] = values[key].value; return obj; }, {})
        axios({
            method: 'POST',
            url: `${process.env.REACT_APP_API}/auth/verify`,
            data: { token, firstname, lastname, phone, birthyear, password },
        })
            .then(response => {
                // setCookie('token', response.data.token)
                navigate('/home')
            })
            .catch(error => {
                let copyOfValues = values
                copyOfValues.password.value = ''
                Object.keys(copyOfValues).map(key => copyOfValues[key].isActivated = false);
                setValues(copyOfValues)
                setButtonText('Finish')
                if (error) {
                    if (error.response) {
                        if (error.response.data) {
                            setError(error.response.data.error)
                        }
                    }
                }
            })
    }


    return (
        <div id="verify" className="bg-white">
            <Form title={'Email Address Verified'}>
                <FormInput
                    onChange={event => handleChange(event, values, setValues)}
                    onBlur={event => handleBlur(event, values, setValues)}
                    values={values}
                    type={'text'}
                    name={'firstname'}
                    placeholder={'First name'}
                />
                <FormInput
                    onChange={event => handleChange(event, values, setValues)}
                    onBlur={event => handleBlur(event, values, setValues)}
                    values={values}
                    type={'text'}
                    name={'lastname'}
                    placeholder={'Last name'}
                />
                <FormInput
                    onChange={event => handleChange(event, values, setValues)}
                    onBlur={event => handleBlur(event, values, setValues)}
                    values={values}
                    type={'text'}
                    name={'phone'}
                    placeholder={'Phone number'}
                />
                <FormInput
                    onChange={event => handleChange(event, values, setValues)}
                    onBlur={event => handleBlur(event, values, setValues)}
                    values={values}
                    type={'text'}
                    name={'birthyear'}
                    placeholder={'Year of birth'}
                    maxLength={4}
                />
                <FormInput
                    onChange={event => handleChange(event, values, setValues)}
                    onBlur={event => handleBlur(event, values, setValues)}
                    values={values}
                    type={'password'}
                    name={'password'}
                    placeholder={'Password'}
                />
                <FormError error={error} />
                <FormButton text={buttonText} onClick={e => handleSubmit(e)} disabled={false} />
            </Form>
        </div>
    )
}

export default Verify
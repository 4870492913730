import React, { useEffect, useState } from "react"
import axios from 'axios'
import { useNavigate, Link, useSearchParams } from 'react-router-dom'
import { Form, FormInput, FormError, FormButton, FormText, handleChange, handleBlur, setInputValues } from '../../components/Form'
import '../../style/auth/signup.css'
import { setCookie } from "../../helpers/helpers"

const Signup = () => {
    const [buttonText, setButtonText] = useState('Continue')
    const [error, setError] = useState('')
    const [values, setValues] = useState()
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams();

    const i = searchParams.get("i")

    // useEffect(() => { setInputValues(['email'], setValues) }, [])

    useEffect(() => { setInputValues(['email', 'firstname', 'lastname', 'password', 'phone', 'birthyear'], setValues) }, [])

    const handleSubmit = (event) => {
        event.preventDefault()
        setButtonText('loader')
        const { email, firstname, lastname, password, phone, birthyear } = Object.keys(values).reduce((obj, key) => { obj[key] = values[key].value; return obj; }, {})
        axios({
            method: 'POST',
            url: `${process.env.REACT_APP_API}/auth/signup`,
            data: { email, firstname, lastname, phone, birthyear, password, invited_by: i ? i : 'INVITED_BY_ME' },
        })
            .then(response => {
                setCookie('token', response.data.token)
                navigate('/home')
            })
            .catch(error => {
                let copyOfValues = values
                copyOfValues.password.value = ''
                Object.keys(copyOfValues).map(key => copyOfValues[key].isActivated = false);
                setValues(copyOfValues)
                setButtonText('Finish')
                if (error) {
                    if (error.response) {
                        if (error.response.data) {
                            if (typeof error.response.data.error == 'string') {
                                setError(error.response.data.error)
                            }
                        }
                    }
                }
            })
    }

    // const handleSubmit = (event) => {
    //     event.preventDefault()
    //     setButtonText('loader')
    //     axios({
    //         method: 'POST',
    //         url: `${process.env.REACT_APP_API}/auth/signup`,
    //         data: Object.keys(values).reduce((obj, key) => { obj[key] = values[key].value; return obj; }, { invited_by: i ? i : 'INVITED_BY_ME' })
    //     })
    //         .then(response => {
    //             navigate(response.data.redirect)
    //         })
    //         .catch(error => {
    //             let copyOfValues = values
    //             Object.keys(copyOfValues).map(key => copyOfValues[key].isActivated = false);
    //             setValues(copyOfValues)
    //             setButtonText('Continue')
    //             if (error) {
    //                 if (error.response) {
    //                     if (error.response.data) {
    //                         setError(error.response.data.error)
    //                     }
    //                 }
    //             }
    //         })
    // }

    return (
        <div id="signup" className="bg-white">
            <Form title={'Create an account'}>
                <FormInput
                    onChange={event => handleChange(event, values, setValues)}
                    onBlur={event => handleBlur(event, values, setValues)}
                    values={values}
                    type={'email'}
                    name={'email'}
                    placeholder={'Email'}
                />
                <FormInput
                    onChange={event => handleChange(event, values, setValues)}
                    onBlur={event => handleBlur(event, values, setValues)}
                    values={values}
                    type={'text'}
                    name={'firstname'}
                    placeholder={'First name'}
                />
                <FormInput
                    onChange={event => handleChange(event, values, setValues)}
                    onBlur={event => handleBlur(event, values, setValues)}
                    values={values}
                    type={'text'}
                    name={'lastname'}
                    placeholder={'Last name'}
                />
                <FormInput
                    onChange={event => handleChange(event, values, setValues)}
                    onBlur={event => handleBlur(event, values, setValues)}
                    values={values}
                    type={'text'}
                    name={'phone'}
                    placeholder={'Phone number'}
                />
                <FormInput
                    onChange={event => handleChange(event, values, setValues)}
                    onBlur={event => handleBlur(event, values, setValues)}
                    values={values}
                    type={'text'}
                    name={'birthyear'}
                    placeholder={'Year of birth'}
                    maxLength={4}
                />
                <FormInput
                    onChange={event => handleChange(event, values, setValues)}
                    onBlur={event => handleBlur(event, values, setValues)}
                    values={values}
                    type={'password'}
                    name={'password'}
                    placeholder={'Password'}
                />
                <FormError error={error} />
                <FormButton text={buttonText} onClick={e => handleSubmit(e)} disabled={false} />
                <FormText html={<>Already have an account? <strong><Link to={"/signin"}>Log In</Link></strong></>} />
            </Form>
        </div>
    )
}

export default Signup
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
    --header-height: 60px;
    --white:#fff;
    --black:#000;
    --red:#DF2935;
}`, "",{"version":3,"sources":["webpack://./src/style/root.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,YAAY;IACZ,YAAY;IACZ,aAAa;AACjB","sourcesContent":[":root {\n    --header-height: 60px;\n    --white:#fff;\n    --black:#000;\n    --red:#DF2935;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, { useEffect, useState } from 'react'
import { IoFilterSharp } from "react-icons/io5";
import { getCookie } from '../../helpers/helpers'
import axios from 'axios'
const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
const sites = ['nike', 'tipsport', 'doxxbet', 'fortuna', 'tipos', 'synottip']
const sports = ['football', 'hockey', 'tennis', 'basketball', 'volleyball', 'handball']

export const Instagram = ({ free }) => {
    const InstagramColor = '#ff0fb6'
    return (
        <div className="m-0 mb-1">
            <div className="container-fluid px-1 pt-3 pb-1 font1">
                <div className="alert m-0 d-flex align-items-center"
                    style={{
                        'border': '0px solid #ff0fb6',
                        // '--bs-alert-bg':'transparent',
                        'backgroundImage': "url('https://preview.colorkit.co/gradient/f9ce34-ee2a7b-6228d7.png?size=wallpaper&scale=0.25&static=true')",
                        'backgroundSize': '100% 100%'
                    }}>
                    <div><img style={{ "height": '28px' }} src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAMAAABF0y+mAAABWVBMVEVyHVFHcEx0Fvp8Fv1yFP1/FfyLCOyfBOmtAeS7AuDHAd3UANjZAs7rD6xyFvzjANTqAMHoA7l2F/yPFfx8GPmfD/zFF/XVWujoZuLyZt3pRt/yGdHyA8j1AKTViPj/9Pr////8g9f9A7r3DZz5vO794Pf+ruj9B6uzFPv7JLv+AJr+BI/+YMP/1vD+AIThG+n9GZn/vd3/YZz+AXP+GoT/r8r+AmT/b5z+HHH+I3f+G2P/ur7+FFD+Klb+fYv/wcr+LUf+LWX+AFn+Nzr+Ohz+RT7+inP+Si7/ztD+TxL/zbv+Wy//9fH+WgL+c1X+ZyX+PUv+Bzv+VXD+cxr/6eL+aQT+eAX+nDj+gwD+gxH+fSL+rYn+jwD+iwL+XhX/3rn/1bH9ZR/+lwP+nwH+xHH8ZC3+qAH+sAD+wAP+x1P+zGb+pl79mQn+uAD9J3T+ygD9O1n8pRL9twuaEkMNAAAAc3RSTlMBAF3G///////////GW9j//9nY/1r///////////7//////8b/////////////////////////////////////////////////////////////////////////////////xf///1v////////Y/9j/2VzGSus+GgAAAeNJREFUeAFFjEWiU0EQRc/tqu74V9wZ4TBiB6wG3wpzVoQ7zHCXeELypHH+KVchJAESaEs0IUv0Zv+7fwLgSAOk9ZFghcnfDw4AAg18cyhWCKNVob9NCZjQLWVrEGhJbJGDoCBpMwCrJTETAKBWgdeRGb4dmJW56PdI/KU1K4X15N+AFsW0+3/WVm7Pa0Pmu4Fh7HcbRIjMIhr3FItUGAH4SNZKjK32YjKpK3Iv0yUY0b8g0Vs0oK+8wbeyMBAQwVeo44R1z7XWq6rqpn5jiTmAeQO3iceg9zkYzKvc71iEmMFbqCJJ4tDcKFrLjX5D/MEdq8GpIUUgd/tgGboTgiUDPDZ44SXNpr0gWRcAgqEmEPJRNVPOxYaOth0oAHdCxWoOzslH+RCvlafYMgFUHvAIHoCjz16io80BrH/j0AAcJ9KWKkhn5sRyGjsDtr/sqyrsWNO1cW9/cDNLZgHLSzVvn+wMq9qbKKwy7TiIaKTW56pzW6Oa+N1OKEQdvvl2RyOE4DEOZurd1cHNeab27tyjOPfhFlucXP04ovF97B6jTOzdJwFLaNAff7N6c8incH1VpiSR67q2VgjzUU7JgbMTiQtFKmgAsCAuczlflmVZn7iGBFc1awB/p4tqUS3LDb8OPwBrSrZIO/KHZwAAAABJRU5ErkJggg=='></img></div>
                    <div className='ms-3 fs-6' style={{ "color": '#fff' }}>Nezabudnite hodiť follow na Instagram <a style={{ "color": '#fff', 'textDecoration': 'none' }} href="https://instagram.com/arbitrage.sk">@arbitrage.sk</a></div>
                </div>
            </div>
        </div>
    )
}

export const NewBookies = ({ free }) => {

    return (
        <div className="m-0 mb-1">
            <div className="container-fluid px-1 pt-3 pb-1">
                <div className="alert alert-secondary m-0">Boli pridané nové spločnosti
                    <img src={require(`../../static/images/sites/tipos.png`)} style={{ 'borderRadius': '2px', 'height': '20px' }} className="my-2 mx-1" alt="Offer 1" ></img>
                    {/* & */}
                    <img src={require(`../../static/images/sites/synottip.png`)} style={{ 'borderRadius': '2px', 'height': '20px' }} className="my-2 mx-1" alt="Offer 1" ></img>
                    nezabudni si resetnut filtre
                </div>
            </div>
        </div>
    )
}


export const FreeVersion = ({ free }) => {
    if (!free) { return '' }

    return (
        <div className="m-0 mb-1">
            <div className="container-fluid px-1 pt-3 pb-1">
                <div className="alert alert-primary m-0">Toto je Free Verzia s arbitrážami zo ziskom do 1%.</div>
            </div>
        </div>
    )
}

export const UnpaidInvoices = ({ unpaidInvoices }) => {
    if (unpaidInvoices > 0) {
        return (
            <div className="m-0 mb-1">
                <div className="container-fluid px-1 pt-3 pb-1">
                    <div className="alert alert-danger m-0">{unpaidInvoices} unpaid invoice{unpaidInvoices > 1 ? 's' : ''}</div>
                </div>
            </div>
        )
    }
    return ''
}

export const UserMessage = ({ userMessage }) => {
    if (!userMessage) { return '' }

    return (
        <div className="m-0 mb-2">
            <div className="container-fluid px-1 pt-3 pb-1">
                <div className="alert alert-info m-0">{userMessage}</div>
            </div>
        </div>
    )
}

export const Options = ({ options, setOptions }) => {
    const [showOptions, setShowOptions] = useState(false)

    const handleSiteClick = (name) => {
        if (options.sites.includes(name)) {
            setOptions({ ...options, sites: options.sites.filter(e => e !== name) })
        } else {
            setOptions({ ...options, sites: [...options.sites, name] })

        }
    }
    const handleSportClick = (name) => {
        if (options.sports.includes(name)) {
            setOptions({ ...options, sports: options.sports.filter(e => e !== name) })
        } else {
            setOptions({ ...options, sports: [...options.sports, name] })
        }
    }



    return (
        <>
            <div className='row mx-sm-2 mt-2 mt-sm-3 mt-2 px-3 px-sm-2 mb-2'>
                <div
                    className='col-auto rounded rounded-5 text-uppercase font1 text-secondary px-3'
                    style={{ 'backgroundColor': '#222222', 'border': `3px solid #222222`, 'cursor': 'pointer' }}
                    onClick={() => setShowOptions(!showOptions)}
                >{showOptions ? 'Close' : 'Filters'}</div>
                <div
                    className={`ms-2 col-auto rounded rounded-5 text-uppercase font1 text-secondary px-3 ${!showOptions && 'd-none'}`}
                    style={{ 'backgroundColor': '#222222', 'border': `3px solid #222222`, 'cursor': 'pointer' }}
                    onClick={() => setOptions(defaultOptions)}
                >Reset</div>
            </div>
            <div className={`${!showOptions && 'd-none'}`}>
                <div className='row justify-content-start px-2 px-sm-4  pt-sm-3'>
                    {sites.map(e => {
                        return (
                            <div key={e} className={`col-auto  ${!options.sites.includes(e) && 'opacity-50'}`} style={{ 'cursor': 'pointer' }} onClick={() => handleSiteClick(e)}>
                                <img src={require(`../../static/images/sites/${e}.png`)} style={{ 'borderRadius': '2px', 'height': '20px' }} className="my-2" alt="Offer 1" ></img>
                            </div>
                        )
                    })}
                </div>
                <div className='row justify-content-start px-2 px-sm-4  pt-sm-3'>
                    {sports.map(e => {
                        return (
                            <div key={e} className={`col-auto ${!options.sports.includes(e) && 'opacity-50'}`} style={{ 'cursor': 'pointer' }} onClick={() => handleSportClick(e)}>
                                <img src={require(`../../static/images/sports/${e}.png`)} style={{ 'borderRadius': '2px', 'height': '30px' }} className="my-2" alt="Offer 1" ></img>
                            </div>
                        )
                    })}
                </div>
            </div>
        </>
    )
}

export const NothingFound = ({ arbitrages }) => {
    if (arbitrages.length > 0) { return '' }
    return (
        <div className="mx-1 mb-2">
            <div className="container-fluid px-1 pt-3 pb-1">
                <div className="alert alert-warning m-0">Nenašli sa žiadne arbitráže. Počkajte chvíľku.</div>
            </div>
        </div>
    )
}

export const Bonuses = ({ arbitrages }) => {
    return (
        <div className="mx-1 mb-2">
            <div className="container-fluid px-1 pt-3 pb-1">
                <div className="alert alert-secondary m-0">
                    <div className='d-flex flex-column'>
                        <div style={{ 'fontSize': '0.9rem' }}>
                            Prehľad všetkých vstúpných bonusov počas MS v hokeji v 7 slovenských stavkových spoločnostiach + bonusy pre už registrovaných hráčov <a href='/bonuses'>TU</a>. Dokopy to je až 140€ pri registracii.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export const Disclamer = ({ }) => {
    return (
        <div className='mb-5 mt-2'>
            <div className="container-fluid" style={{ 'padding': '0 5%' }}>
                <div className="text-secondary text-center" style={{ 'fontSize': '14px' }}>Kurzy slúžia len na informačné účely a môžu viesť k významným finančným stratám. Preto pred podaným stávky dôkladne skontrolujte, či sa vydávate do nejakého rizika.</div>
            </div>
            <br />
            <div className="container-fluid" style={{ 'padding': '0 5%' }}>
                <div className="text-secondary text-center" style={{ 'fontSize': '14px' }}>Zákaz hrania hazardných hier osobám mladším ako 18 rokov. Hazardné hry predstavujú riziko vysokých finančných strát. Nadmerné hranie prináša so sebou aj možné zdravotné riziká.</div>
                <div className="text-secondary text-center" style={{ 'fontSize': '14px' }}>Linka pomoci pre problémy s hraním <a href="tel:0800 800 900">0800 800 900</a>.</div>
            </div>
        </div>
    )
}

export const formatIsoString = (date) => {
    return {
        'day': days[(new Date(date)).getDay()],
        'date': new Date(date).getDate(),
        'month': months[(new Date(date)).getMonth()],
        'hour': (new Date(date)).getHours(),
        'minute': ((new Date(date)).getMinutes() < 10 ? '0' : '') + (new Date(date)).getMinutes(),
        'dateText': (
            (new Date(date).getDate() === new Date().getDate() &&
                new Date(date).getMonth() === new Date().getMonth())
                ? 'DNES'
                : (new Date(date).getDate() === new Date(new Date().setDate(new Date().getDate() + 1)).getDate() &&
                    new Date(date).getMonth() === new Date(new Date().setDate(new Date().getDate() + 1)).getMonth())
                    ? 'ZAJTRA'
                    : `${new Date(date).getDate()} ${months[(new Date(date)).getMonth()]}`
        )
    }
}

export const defaultOptions = {
    "collapsed": false,
    "sites": sites,
    "sports": sports,
    "min": null,
    "max": null,
    "max_start": null,
    "games_key": null
}

export const roundDigits = (number) => {
    const decimalPlaces = 2
    const factor = 10 ** decimalPlaces
    return (Math.round(number * factor) / factor).toFixed(decimalPlaces)
}

export const Button = ({ onClick, icon, text }) => {
    return (
        <div
            className="btn-dark col-12 rounded-3 btn mt-2 text-secondary"
            style={{ 'height': '40px', 'backgroundColor': '#161616' }}
            onClick={onClick}
        >
            <div className="row d-flex justify-content-center align-items-center">
                <div className="col-9 text-start ps-4 text-truncate font1" >
                    {text}
                </div>
                <div className="col-3 d-flex justify-content-end align-items-center pe-4" style={{ 'height': '20px' }}>
                    {icon}
                </div>
            </div>
        </div>
    )
}

export const hideGroup = (group_key) => {
    const token = getCookie('token')

    axios({
        method: 'POST',
        url: `${process.env.REACT_APP_API}/hide/group`,
        data: { group_key, token },
    })
        .then(() => {
        })
        .catch(err => {
        })
}

export const hideGame = (gameids) => {
    const token = getCookie('token')

    axios({
        method: 'POST',
        url: `${process.env.REACT_APP_API}/hide/game`,
        data: { gameids, token },
    })
        .then(() => {

        })
        .catch(err => {
        })
}

export const invalidGame = (ids) => {
    const token = getCookie('token')

    axios({
        method: 'POST',
        url: `${process.env.REACT_APP_API}/invalid/games`,
        data: { ids, token },
    })
}
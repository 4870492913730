import { useNavigate, BrowserRouter as Router, Route } from "react-router-dom";
import Layout from "./components/Layout";
import { useEffect, useState } from "react";
import { isAuth } from "./helpers/helpers";


const App = () => {
  const navigate = useNavigate()
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Set a timer to change visibility after 2 seconds
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 500);
  }) 
  useEffect(()=>{
    if(isAuth()){
      navigate('/prematch/arbitrage');
    }
  },[])
  const fontSize = 14
  const pr = 1.618
  return (
    <div style={{ "background": '#0F0725', 'height': "100vh" }} className="d-flex align-items-center justify-content-center flex-column">
      <div className="d-flex justify-content-end align-items-start" style={{ 'height': 'calc(100%/3)', 'width': '100%' }}>
        <a className=" text-white btn mt-4 me-4 rounded rounded-4 py-0" style={{ "background": '#6F00FD' }} href='/signin'>
          <span className="lh-base py-0 font3 text-uppercase" style={{ "fontSize": `${fontSize}px` }}>prihlásiť sa</span>
        </a>
      </div>
      <div className="font3" style={{ 'height': 'calc(100%/3)' }}>
        <div className=" text-white text-center d-flex align-items-center justify-content-center flex-column h-100" style={{ 'maxWidth': '400px' }}>
          <span className="text-uppercase lh-1" style={{ "fontSize": `${fontSize *pr*pr*pr}px`,'letterSpacing':'0' }}>arbitrážne stávkovanie</span>
          <span className="font4" style={{ "fontSize": `${fontSize}px` }}>Netipujeme výsledok - Zisk pri</span>
          <span className="font4" style={{ "fontSize": `${fontSize}px` }}>akomkoľvek výsledku zápasu</span>
        </div>
      </div>
      <div className={`d-flex align-items-center justify-content-center ${isVisible ? 'fade-in' : 'hidden'}`}style={{ 'height': 'calc(100%/3)' }}>
        <a className=" text-white btn rounded rounded-5 px-3" style={{ "background": '#6F00FD','paddingTop':'7px' }} href='/signup'>
          <span className="lh-base py-2 font3 text-uppercase " style={{ "fontSize": `${fontSize*pr}px` }}>skúsiť zadarmo</span>
        </a>
      </div>
    </div>
  );
}

export default App;
